import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import OrderHistoryProduct from '~/types/api/orderHistory/OrderHistoryProduct'
import OrderHistoryPayment from '~/types/api/orderHistory/OrderHistoryPayment'
import OrderHistoryDelivery from '~/types/api/orderHistory/OrderHistoryDelivery'
import config from '~/utils/config'
import s from '~/utils/s'
import NotificationType from '~/types/enum/NotificationType'

@Module({
  name: 'orderDetailPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _id: string = ''
  _number: number = 0
  _orderPrice: number = 0
  _deliveryPrice: number = 0
  _basketPrice: number = 0
  _discountPrice: number = 0
  _basketPriceDiscount: number = 0
  _status: string = ''
  _paymentStatus: string = ''
  _regionId: string = ''
  _sectionId: string = ''
  _canCancel: boolean = true
  _dateToPickup: number = 0
  _scheduledPickupDate: number = 0
  _dateOfIssue: number = 0
  _day: number = 0
  _period: string = ''
  _addressDelivery: string = ''
  _fio: string = ''
  _isExpress = false
  _products: OrderHistoryProduct[] = []
  _payment: OrderHistoryPayment = {
    id: 0,
    name: ''
  }

  _canPay = true

  _pickupAddress: string = ''

  _delivery: OrderHistoryDelivery = {
    id: '',
    code: 0,
    name: ''
  }

  @Mutation
  setId (id: string) {
    this._id = id
  }

  @Mutation
  setNumber (number: number) {
    this._number = number
  }

  @Mutation
  setOrderPrice (orderPrice: number) {
    this._orderPrice = orderPrice
  }

  @Mutation
  setBasketPrice (basketPrice: number) {
    this._basketPrice = basketPrice
  }

  @Mutation
  setDiscountPrice (discountPrice: number) {
    this._discountPrice = discountPrice
  }

  @Mutation
  setBasketPriceDiscount (basketPriceDiscount: number) {
    this._basketPriceDiscount = basketPriceDiscount
  }

  @Mutation
  setDeliveryPrice (price: number) {
    this._deliveryPrice = price
  }

  @Mutation
  setStatus (status: string) {
    this._status = status
  }

  @Mutation
  setPaymentStatus (status: string) {
    this._paymentStatus = status
  }

  @Mutation
  setProducts (items: OrderHistoryProduct[]) {
    this._products = items
  }

  @Mutation
  setPayment (payment: OrderHistoryPayment) {
    this._payment = payment
  }

  @Mutation
  setDelivery (delivery: OrderHistoryDelivery) {
    this._delivery = delivery
  }

  @Mutation
  setRegionId (regionId: string) {
    this._regionId = regionId
  }

  @Mutation
  setSectionId (id: string) {
    this._sectionId = id
  }

  @Mutation
  setCanCancel (value: boolean) {
    this._canCancel = value
  }

  @Mutation
  setPickupAddress (pickupAddress: string) {
    this._pickupAddress = pickupAddress
  }

  @Mutation
  setDateToPickup (dateToPickup: number) {
    this._dateToPickup = dateToPickup
  }

  @Mutation
  setScheduledPickupDate (scheduledPickupDate: number) {
    this._scheduledPickupDate = scheduledPickupDate
  }

  @Mutation
  setDateOfIssue (dateOfIssue: number) {
    this._dateOfIssue = dateOfIssue
  }

  @Mutation
  setDay (day: number) {
    this._day = day
  }

  @Mutation
  setPeriod (period: string) {
    this._period = period
  }

  @Mutation
  setAddressDelivery (addressDelivery: string) {
    this._addressDelivery = addressDelivery
  }

  @Mutation
  setFio (fio: string) {
    this._fio = fio
  }

  @Mutation
  setIsExpress (isExpress: boolean) {
    this._isExpress = isExpress
  }

  @Mutation
  setCanPay (value: boolean) {
    this._canPay = value
  }

  @Action({ rawError: config.rawError })
  async fillData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }
    const params = route.params
    const number = params.id
    // if (typeof params.id === 'string') {
    //   number = parseInt(params.id, 10).toString()
    // }

    const result = await this.store.$api.getOrderByNumber({
      number,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId
    })

    if (result.code === 200 && result.data) {
      this.setId(result.data.id)
      this.setNumber(result.data.number)
      this.setOrderPrice(result.data.orderPrice)
      this.setBasketPrice(result.data.basketPrice)
      this.setDiscountPrice(result.data.discountPrice)
      this.setBasketPriceDiscount(result.data.basketPriceDiscount)
      this.setDeliveryPrice(result.data.deliveryPrice)
      this.setStatus(result.data.status)
      this.setPaymentStatus(result.data.paymentStatus)
      this.setProducts(result.data.products)
      this.setPayment(result.data.payment)
      this.setDelivery(result.data.delivery)
      this.setRegionId(result.data.regionId)
      this.setCanCancel(result.data.canCancel)
      this.setPickupAddress(result.data.pickupAddress)
      this.setDateToPickup(result.data.dateToPickup)
      this.setDateOfIssue(result.data.dateOfIssue)
      this.setDay(result.data.day)
      this.setPeriod(result.data.period)
      this.setAddressDelivery(result.data.addressDelivery)
      this.setFio(result.data.contactName ?? result.data.fio)
      this.setIsExpress(result.data.isExpress)
      this.setCanPay(result.data.canPay ?? true)
      this.setScheduledPickupDate(result.data.scheduledPickupDate)
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  @Action({ rawError: config.rawError })
  async repeatOrder () {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const result = await s(this.store).basket.copyOrder({
      id: this.id,
      regionId,
      sectionId
    })

    if (result) {
      await s(this.store).regions.redirectBasketById(this.regionId)
    }
  }

  @Action({ rawError: config.rawError })
  async cancelOrder () {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.cancelOrderById(this.id, regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setCanCancel(false)
      this.setStatus('Отменен')
      if (!s(this.store).auth.user?.allowPush) {
        s(this.store).notification.addNotification({
          type: NotificationType.Success,
          text: `Заказ №${this.number} успешно отменен`
        })
      }
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    }
  }

  get id (): string {
    return this._id
  }

  get orderPrice (): number {
    return this._orderPrice
  }

  get basketPrice (): number {
    return this._basketPrice
  }

  get discountPrice (): number {
    return this._discountPrice
  }

  get basketPriceDiscount (): number {
    return this._basketPriceDiscount
  }

  get deliveryPrice (): number {
    return this._deliveryPrice
  }

  get status (): string {
    return this._status
  }

  get paymentStatus (): string {
    return this._paymentStatus
  }

  get products (): OrderHistoryProduct[] {
    return this._products
  }

  get payment (): OrderHistoryPayment {
    return this._payment
  }

  get delivery (): OrderHistoryDelivery {
    return this._delivery
  }

  get regionId (): string {
    return this._regionId
  }

  get sectionId (): string {
    return this._sectionId
  }

  get canCancel (): boolean {
    return this._canCancel
  }

  get pickupAddress (): string {
    return this._pickupAddress
  }

  get dateToPickup (): number {
    return this._dateToPickup
  }

  get dateOfIssue (): number {
    return this._dateOfIssue
  }

  get day (): number {
    return this._day
  }

  get period (): string {
    return this._period
  }

  get addressDelivery (): string {
    return this._addressDelivery
  }

  get fio (): string {
    return this._fio
  }

  get isExpress (): boolean {
    return this._isExpress
  }

  get number (): number {
    return this._number
  }

  get canPay (): boolean {
    return this._canPay
  }

  get scheduledPickupDate (): number {
    return this._scheduledPickupDate
  }
}
