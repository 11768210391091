import { Vue, Component, Prop } from 'vue-property-decorator'
import Section from '~/types/api/Section'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'
import loc from '~/utils/loc'

@Component
export default class FirstLevelList extends Vue {
  @Prop({ type: String, required: true })
  readonly selectedId!: string

  @Prop({ type: Array, required: true })
  readonly sections!: SfSection[]

  getImage (section: SfSection) {
    if (section.icon) {
      return (
        <div class="catalog-menu__first-level-list-item-logo">
          <img src={section.icon} alt={section.name} />
        </div>
      )
    } else {
      return <div class="catalog-menu__first-level-list-item-logo catalog-menu__first-level-list-item-logo_empty"/>
    }
  }

  get getSections (): SfSection[] {
    return this.sections
  }

  render () {
    return (
      <div ref="list" class="catalog-menu__first-level-list">
        {this.getSections.map((e) => {
          if (e.alternativeDisplay === false) {
            return (
              <div
                class="catalog-menu__first-level-list-item-wrapper"
                onMouseenter={() => this.$emit('select', e.id)}
                onClick={() => this.$emit('close')}>
                <nuxt-link
                  to={new SectionHelper(s(this.$store).general.sections, this.$store).getSectionPathById(e.id)}
                  class='catalog-menu__first-level-list-item'
                >
                  {this.getImage(e)}
                  <div class="catalog-menu__first-level-list-item-text">
                    {e.name}
                  </div>
                </nuxt-link>
              </div>
            )
          } else {
            return (
              <div
                class="catalog-menu__first-level-list-item-wrapper"
                onMouseenter={() => this.$emit('select', e.id)}
                onClick={() => this.$emit('close')}>
                <nuxt-link
                  to={loc(this.$store, {
                    name: 'categories-id',
                    params: {
                      id: e.id
                    }
                  })}
                  class='catalog-menu__first-level-list-item'
                >
                  {this.getImage(e)}
                  <div class="catalog-menu__first-level-list-item-text">
                    {e.name}
                  </div>
                </nuxt-link>
              </div>
            )
          }
        }
        )}
      </div>
    )
  }
}
