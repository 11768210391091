import s from '~/utils/s'

const url = process.env.NUXT_ENV_API_URL_SF || 'http://localhost:9997/api'

export default function ({ $axios, redirect, store }) {
  $axios.onRequest((config) => {
    if (config.baseURL.includes('9997')) {
      // console.log(`Making ${config.method} request to ${config.baseURL}${config.url} with body ${JSON.stringify(config.data, null, 2)} headers: ${JSON.stringify(config.headers, null, 2)}`)
    }
  })

  $axios.onResponse((response) => {
    if (response.config.baseURL.includes('9997')) {
      // console.log(`[${response.status}] ${JSON.stringify(response.data, null, 2)}`)
    }
  })

  /* $axios.onError(async (err) => {
    const code = parseInt(err.response && err.response.status)

    const originalRequest = err.config
    if (code === 401) {
      if (!originalRequest.__isRetryRequest && !originalRequest.url.includes('refresh-token')) {
        originalRequest.__isRetryRequest = true

        const token = s(store).auth.refreshToken

        const json = await fetch(`${url}/refresh-token`, {
          method: 'POST',
          headers: {
            'x-refresh-token': token
          }
        })

        const response = await json.json()

        console.log(response)
        if (response.status === 200) {
          s(store).auth.setSfTokenAction(response.result.jwt)
          s(store).auth.setRefreshTokenAction(response.result.rt)
          return await $axios(originalRequest)
        } else {
          s(store).auth.setSfToken('')
          s(store).auth.setRefreshToken('')
          await s(store).auth.fillAuthorizeToken()
          return await $axios(originalRequest)
        }
      }
    }
  }) */

  // $axios.onError(async (error) => {
  //   const code = parseInt(error.response && error.response.status)
  //   const originalRequest = error.config
  //
  //   if (code === 400) {
  //     return redirect('/400')
  //   }
  //
  //   // TODO: Сделать автоматическое обновление токена
  //   /*
  //   console.log(JSON.stringify(originalRequest))
  //   console.log(code)
  //   console.log(originalRequest.url.includes('refresh-token'))
  //   */
  //   if (code === 401) {
  //     if (!originalRequest.__isRetryRequest && !originalRequest.url.includes('refresh-token')) {
  //       originalRequest.__isRetryRequest = true
  //       const token = s(store).auth.refreshToken
  //
  //       const response = await $axios.get('/refresh-token', {
  //         baseURL: url,
  //         headers: {
  //           'x-refresh-token': token
  //         }
  //       })
  //
  //       console.log(response)
  //     }
  //   }
  // })
}
