import { Vue, Component } from 'vue-property-decorator'
import { Region } from '~/types/api/Region'
import LocationSvg from '~/assets/svg/popup-location.svg?inline'
import s from '~/utils/s'
import City from '~/types/api/pharmacy/City'

@Component
export default class RegionPopup extends Vue {
  targetRegionCode?: string = ''

  get selectedRegion (): Region | undefined {
    return s(this.$store).regions.region
  }

  created () {
    this.targetRegionCode = s(this.$store).regions.region?.code
  }

  async selectRegion (region: Region) {
    window.scrollTo(0, 0);
    s(this.$store).regions.setChoiceCity(true)
    this.targetRegionCode = region.code
    await s(this.$store).regions.redirectRegionByCode(this.targetRegionCode ?? '')
    s(this.$store).general.closePopup()
  }

  render () {
    return (
      <div class="popup-regions">
        <div class="popup-regions__header">
          Выберите город
        </div>
        <div class="popup-regions__list">
          {s(this.$store).regions.regions.map((e: Region) => (
            e.cities !== undefined && e.cities !== null && e.cities.length > 0
              ? e.cities.map((city: City) => (
                <div key={e.code} onClick={() => this.selectRegion(e)} class={{
                  'popup-regions__item': true
                }}>
                  <div class="popup-regions__item-logo">
                    <LocationSvg />
                  </div>
                  <div class="popup-regions__item-text">
                    {city.name}{e.name === city.name ? '' : ','}&nbsp;
                    <div class="popup-regions__item-text-region">
                      {e.name === city.name ? '' : e.name}
                    </div>
                  </div>
                </div>))
              : <div key={e.code} onClick={() => this.selectRegion(e)} class={{
                'popup-regions__item': true
              }}>
                <div class="popup-regions__item-logo">
                  <LocationSvg />
                </div>
                <div class="popup-regions__item-text">
                  {e.name}
                </div>
              </div>
          ))}
        </div>
      </div>
    )
  }
}
