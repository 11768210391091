const middleware = {}

middleware['authMiddleware'] = require('../middleware/authMiddleware.ts')
middleware['authMiddleware'] = middleware['authMiddleware'].default || middleware['authMiddleware']

middleware['baseURLRedirectMiddleware'] = require('../middleware/baseURLRedirectMiddleware.ts')
middleware['baseURLRedirectMiddleware'] = middleware['baseURLRedirectMiddleware'].default || middleware['baseURLRedirectMiddleware']

middleware['choiceCityMiddleware'] = require('../middleware/choiceCityMiddleware.ts')
middleware['choiceCityMiddleware'] = middleware['choiceCityMiddleware'].default || middleware['choiceCityMiddleware']

middleware['loginMiddleware'] = require('../middleware/loginMiddleware.ts')
middleware['loginMiddleware'] = middleware['loginMiddleware'].default || middleware['loginMiddleware']

middleware['redirectToLowerCase'] = require('../middleware/redirectToLowerCase.ts')
middleware['redirectToLowerCase'] = middleware['redirectToLowerCase'].default || middleware['redirectToLowerCase']

middleware['regionRedirectMiddleware'] = require('../middleware/regionRedirectMiddleware.ts')
middleware['regionRedirectMiddleware'] = middleware['regionRedirectMiddleware'].default || middleware['regionRedirectMiddleware']

middleware['regionSectionMiddleware'] = require('../middleware/regionSectionMiddleware.ts')
middleware['regionSectionMiddleware'] = middleware['regionSectionMiddleware'].default || middleware['regionSectionMiddleware']

middleware['sensorPanelMiddleware'] = require('../middleware/sensorPanelMiddleware.ts')
middleware['sensorPanelMiddleware'] = middleware['sensorPanelMiddleware'].default || middleware['sensorPanelMiddleware']

middleware['seoMiddleware'] = require('../middleware/seoMiddleware.ts')
middleware['seoMiddleware'] = middleware['seoMiddleware'].default || middleware['seoMiddleware']

middleware['trainingMiddleware'] = require('../middleware/trainingMiddleware.ts')
middleware['trainingMiddleware'] = middleware['trainingMiddleware'].default || middleware['trainingMiddleware']

export default middleware
