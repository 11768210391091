import { Vue, Component } from 'vue-property-decorator'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import { OrderItem } from '~/types/api/order/Order'

@Component
export default class OrderBasketPopup extends Vue {
  renderItem (item: OrderItem, link: string) {
    const isDiscount = item.price < item.basePrice
    return (
      <nuxt-link to={link} class="order-basket-popup__basket-list-item"
        nativeOn={{ click: () => s(this.$store).general.closePopup() }}>
        <div class="order-basket-popup__basket-list-item-name">
          {item.title}
        </div>
        <div class="order-basket-popup__basket-list-item-info">
          <div class="order-basket-popup__basket-list-item-count">
            {item.amount} шт.
          </div>
          {isDiscount &&
            <div class="order-basket-popup__basket-list-item-discount">
              {item.basePrice} ₽
            </div>
          }
          <div class="order-basket-popup__basket-list-item-price">
            {item.price} ₽
          </div>
        </div>
      </nuxt-link>
    )
  }

  render () {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    return (
      <div class="order-basket-popup">
        <div class="order-basket-popup__haeder">
          Состав заказа
        </div>
        <div class="order-basket-popup__list">
          {s(this.$store).orderPage.basketItems.map((e) => {
            const sections = e.sectionId
            const link = helper.getProductLink(sections?.length ? sections[0] : s(this.$store).categoryPage.category.id, e.seoUrl ?? e.id)
            return this.renderItem(e, link)
          })}
        </div>
      </div>
    )
  }
}
