import { Vue, Component } from 'vue-property-decorator'
import MobileCatagoryList from '~/components/general/generalList/MobileCatagoryList'
import MobilePersonalList from '~/components/general/generalList/MobilePersonalList'
import GeneralListType from '~/types/enum/GeneralListType'
import MobileSearchList from '~/components/general/generalList/MobileSearchList'
import s from '~/utils/s'

@Component
export default class GeneralList extends Vue {
  generalList () {
    switch (s(this.$store).general.generalListType) {
      case GeneralListType.Category:
        return (
          <div class="general-list__wrapper general-list__wrapper_offset">
            {<MobileCatagoryList />}
          </div>
        )
      case GeneralListType.Personal:
        return (
          <div class="general-list__wrapper">
            {<MobilePersonalList />}
          </div>
        )
      case GeneralListType.Search:
        return (
          <div class="general-list__wrapper general-list__wrapper_offset">
            {<MobileSearchList />}
          </div>
        )
    }
  }

  render () {
    return (
      <div class="general-list">
        {this.generalList()}
      </div>
    )
  }
}
