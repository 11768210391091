import { Plugin } from '@nuxt/types'
import s from '~/utils/s'
import 'smartbanner.js'

const showSmartBanner: Plugin = (context) => {
  if (process.client) {
    window.addEventListener('scroll', function onScroll() {
      if (!window.localStorage.getItem('bannerShown')) {
        setTimeout(() => {
          if (/android|iphone|kindle|ipad/i.test(navigator.userAgent) && s(context.store).regions.regionId) {
            s(context.store).general.showPopup('SmartBannerPopup')
            window.localStorage.setItem('bannerShown', 'true')
            window.removeEventListener('scroll', onScroll)
          }
        }, 1000)
      }
    })
  }
}

export default showSmartBanner
