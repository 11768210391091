import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const sensorPanelMiddleware: Middleware = async (context: Context) => {
  if (process.client) {
    return
  }

  const queryValue = context.route.query.sensor_panel

  if (!(queryValue === 'on' || queryValue === 'off')) {
    return
  }

  const now = new Date()

  const cookiesOptions = {
    domain: s(context.store).general.host,
    expires: new Date(now.setFullYear(now.getFullYear() + 2)),
    path: '/'
  }

  if (queryValue === 'on') {
    context.$cookies.set('sensor_panel', 'on', cookiesOptions)
  } else if (queryValue === 'off') {
    context.$cookies.remove('sensor_panel', cookiesOptions)
  }
}

export default sensorPanelMiddleware
