import $script from 'scriptjs'

export default function ({ app: { router }, isDev, env }) {
  if (process.client && !isDev) {
    const insertOnlineChat = env.NODE_ENV_INSERT_ONLINE_CHAT

    if (insertOnlineChat === 'true') {
      router.onReady(() => {
        setTimeout(() => {
          const token = env.NODE_ENV_ONLINE_CHAT_TOKEN
          if (token) {
            window.supportAPIMethod = 'OnlineChat'
            $script('https://zcdn.ru/support/support.js?h=' + token)

            window.OnlineChat = window.OnlineChat || function () {
              window.OnlineChat.q = window.OnlineChat.q || []
              window.OnlineChat.q.push(arguments)
            }
          }
        }, 2000)
      })
    }
  }
}
