import { Vue, Component, Prop } from 'vue-property-decorator'
import LinkItem from '~/types/LinkItem'

@Component
export default class FooterMenu extends Vue {
  @Prop({ type: Array, default: [] })
  readonly items!: LinkItem[]

  @Prop({ type: String, default: '' })
  readonly header!: ''

  render () {
    return (
      <div class="common-footer-menu">
        <div class="common-footer-menu__header">
          {this.header}
        </div>
        <div class="common-footer-menu__list">
          {this.items.map((e: LinkItem, i: number) => {
            if (e.isBlank === true) {
              return <a href={e.location} class="common-footer-menu__item" target="_blank">{e.title}</a>
            } else {
              return <nuxt-link key={i} to={e.location} class="common-footer-menu__item">
                {e.title}
              </nuxt-link>
            }
          })}
        </div>
      </div>
    )
  }
}
