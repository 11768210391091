const list = [
  12,
  24,
  48
]

export function checkValue (val: any): number {
  if (typeof val === 'number') {
    if (list.includes(val)) {
      return val
    }
  }
  if (typeof val === 'string') {
    if (list.includes(parseInt(val, 10))) {
      return parseInt(val, 10)
    }
  }
  return list[0]
}

export default list
