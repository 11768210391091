import { Vue, Component } from 'vue-property-decorator'
import HeaderTabs from '~/components/general/header/HeaderTabs'
import HeaderLinks from '~/components/general/header/HeaderLinks'
import HeaderCatalogLinks from '~/components/general/header/HeaderCatalogLinks'
import HeaderCloseGenrealList from '~/components/general/header/HeaderCloseGenrealList'
import HeaderBottom from '~/components/general/header/HeaderBottom'

@Component({
  components: {
    HeaderTabs,
    HeaderLinks,
    HeaderCatalogLinks
  }
})
export default class Header extends Vue {
  render () {
    return (
      <header class="common-header">
        <HeaderCloseGenrealList />
        <div class="common-header__top">
          <div class="common-header__container">
            <div class="common-header__row">
              <div class="common-header__col-tabs">
                <div class="common-header__tabs">
                  <HeaderTabs />
                </div>
              </div>
              <div class="common-header__col-link">
                <div class="common-header__links">
                  <HeaderLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="common-header__bottom">
          <HeaderBottom />
        </div>
      </header>
    )
  }
}
