import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'

@Component
export default class Loader extends Vue {
  loader () {
    if (s(this.$store).general.isLoaded) {
      return (
        <div class="loader">
          <div class="loader__wrapper">
            <div class="loader__roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <transition name="loader__animation">
        {this.loader()}
      </transition>
    )
  }
}
