import { Plugin } from '@nuxt/types'
import { throttle } from '~/utils/throttle'
import s from '~/utils/s'

interface SessionTimerStorageData {
  time: number,
  token: string
}

const writeLocalStorage = (token: string) => {
  localStorage.setItem('session_timer', JSON.stringify({
    token,
    time: new Date().getTime()
  }))
}

const readLocalStorage = (): SessionTimerStorageData | undefined => {
  const json = localStorage.getItem('session_timer')
  if (json) {
    return JSON.parse(json)
  }
}

const sessionTimer: Plugin = async (context) => {
  const sessionTimerEnabled = context.$cookies.get('sensor_panel') === 'on'
  const lifeTimeMilliseconds = Number.parseInt(context.env.NODE_ENV_SESSION_LIFETIME ?? 300000)

  if (!sessionTimerEnabled) {
    return
  }

  let timer: NodeJS.Timeout

  // Отчистка cookie токена и перезагрузка старницы
  const resetToken = () => {
    context.$cookies.set('token', '', {
      domain: s(context.store).general.host,
      maxAge: -1,
      path: '/'
    })
    window.location.reload()
  }

  // При взаимодействии пользователя с сайтом
  // записывает в storage данные о токене и времени
  // Также заново запускает таймер сброса токена
  const update = () => {
    writeLocalStorage(s(context.store).auth.token)
    clearTimeout(timer)
    timer = setTimeout(resetToken, lifeTimeMilliseconds)
  }

  // При загрузке сайта, если токен прошлого пользователя
  // совпадает с текущим и время жизни токена прошло,
  // сбрасывает токен и перезагружает страницу
  const lastData = readLocalStorage()
  if (lastData) {
    const isCurrentToken = lastData.token === s(context.store).auth.token
    const isTokenExpired = new Date().getTime() - lastData.time > lifeTimeMilliseconds
    if (isCurrentToken && isTokenExpired) {
      resetToken()
    }
  }

  update()
  const eventHandler = throttle(update, 500)

  window.addEventListener('click', eventHandler)
  window.addEventListener('scroll', eventHandler)
  window.addEventListener('resize', eventHandler)
  window.addEventListener('keypress', eventHandler)
  window.addEventListener('mousemove', eventHandler)
  window.addEventListener('touchstart', eventHandler)
  window.addEventListener('touchend', eventHandler)
  window.addEventListener('touchcancel', eventHandler)
  window.addEventListener('touchmove', eventHandler)
}

export default sessionTimer
