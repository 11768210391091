export default function ({ app: { router }, isDev, env }) {
  if (process.client && !isDev) {
    router.onReady(() => {
      setTimeout(() => {
        const _tmr = window._tmr || (window._tmr = [])
        _tmr.push({ id: '2938557', type: 'pageView', start: (new Date()).getTime() });
        (function (d, w, id) {
          if (d.getElementById(id)) { return }
          const ts = d.createElement('script')
          ts.type = 'text/javascript'
          ts.async = true
          ts.id = id
          ts.src = 'https://top-fwz1.mail.ru/js/code.js'
          const f = function () { const s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ts, s) }
          if (w.opera === '[object Opera]') { d.addEventListener('DOMContentLoaded', f, false) } else { f() }
        })(document, window, 'tmr-code')
      }, 2000)
    })
  }
}
