import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { MarkingAdInfo } from '../types/api/MarkingAdTypes'
import config from '~/utils/config'

@Module({
  name: 'markingAd',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // markingAd
  _creativeToken: string = ''
  _clientName: string = ''
  _advertiserUrl: string = ''
  _description: string = ''

  // start markingAd mutation
  @Mutation
  setCreativeToken (creativeToken: string) {
    this._creativeToken = creativeToken
  }

  @Mutation
  setClientName (clientName: string) {
    this._clientName = clientName
  }

  @Mutation
  setAdvertiserUrl (advertiserUrl: string) {
    this._advertiserUrl = advertiserUrl
  }

  @Mutation
  setDescription (description: string) {
    this._description = description
  }
  // end markingAd mutation

  @Action({ rawError: config.rawError })
  async loadMarkingInfo (creativeTokenId: string) {
    const result = await this.store.$api.getMarkingAdInfo(creativeTokenId)
    if (result.code === 200) {
      this.setCreativeToken(result.data?.creativeToken!)
      this.setClientName(result.data?.clientName!)
      this.setAdvertiserUrl(result.data?.advertiserUrl!)
      this.setDescription(result.data?.description!)
    }
    return {
      id: creativeTokenId,
      clientName: this.clientName,
      advertiserUrl: this.advertiserUrl,
      creativeToken: this.creativeToken,
      description: this.description
    } as MarkingAdInfo
  }

  // start markingAd props
  get clientName (): string {
    return this._clientName
  }

  get advertiserUrl (): string {
    return this._advertiserUrl
  }

  get description (): string {
    return this._description
  }

  get creativeToken (): string {
    return this._creativeToken
  }
  // end markingAd props
}
