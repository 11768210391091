import { Vue, Component, Prop } from 'vue-property-decorator'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class DiseaseSectionList extends Vue {
  @Prop({ required: true, type: Array })
  readonly items!: DiseaseSection[]

  get selectedId (): number | undefined {
    const code = this.$route.params?.section
    return s(this.$store).diseasesPage.sections.find(e => e.code === code)?.id
  }

  render () {
    return (
      <div class="disease-section-list">
        {this.items.map(e => (
          <nuxt-link {...{
            class: {
              'disease-section-list__item': true,
              'disease-section-list__item_selected': e.id === this.selectedId
            },
            nativeOn: {
              click: () => s(this.$store).general.closePopup()
            },
            key: e.id,
            props: {
              to: loc(this.$store, {
                name: 'diseases-section',
                params: {
                  section: e.code
                }
              })
            }
          }}>
            {e.name}
          </nuxt-link>
        ))}
      </div>
    )
  }
}
