import { Vue, Component, Prop } from 'vue-property-decorator'
import Section from '~/types/api/Section'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'

@Component
export default class ThirdLevelList extends Vue {
  @Prop({ type: Array, required: true })
  readonly sections!: SfSection[]

  render () {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    return (
      <div class="catalog-menu__third-level-list">
        {this.sections.map(e => (
          <nuxt-link key={e.id} class="catalog-menu__third-level-item" to={helper.getSectionPathById(e.id)} nativeOn={{ click: () => this.$emit('close') }}>
            {e.name}
          </nuxt-link>
        ))}
      </div>
    )
  }
}
