import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import config from '~/utils/config'
import s from '~/utils/s'
import NotificationType from '~/types/enum/NotificationType'
import IdProductWaitingList from '~/types/api/IdProductWaitingList'
import { CartItem } from '~/types/api/SfCart'

@Module({
  name: 'waitingList',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _items: CartItem[] = []
  _ids: IdProductWaitingList[] = []

  @Mutation
  setItems (items: CartItem[]) {
    this._items = items
  }

  @Mutation
  setIds (ids: IdProductWaitingList[]) {
    this._ids = ids
  }

  @Action({ rawError: config.rawError })
  async fillData () {
    s(this.store).general.enableLoader()
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getWaitingList(regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setItems(result.data)
    } else {
      this.setItems([])
    }
    await this.updateIds()
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async changeProduct (productId: string) {
    const sectionId = s(this.store).regions.sectionId

    s(this.store).general.enableLoader()
    const result = await this.store.$api.changeWaitingList({
      productId,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId
    })

    if (result.code === 200 && result.data) {
      let notificationText = 'Товар добавлен в лист ожидания'
      if (this._ids.map(id => id.id).includes(productId)) {
        notificationText = 'Товар удален из листа ожидания'
      }
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: notificationText
      })
      await this.fillData()
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Неизвестная ошибка'
      })
    }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async addProductToWaitingList (productId: string) {
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId ?? ''
    s(this.store).general.enableLoader()
    const result = await this.store.$api.addToWaitingList(productId, regionId, sectionId)

    if (result.code === 200 && result.data) {
      let notificationText = 'Товар добавлен в лист ожидания'
      if (this._ids.map(id => id.id).includes(productId)) {
        notificationText = 'Товар удален из листа ожидания'
      }
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: notificationText
      })
      await this.fillData()
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Неизвестная ошибка'
      })
    }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async removeProductFromWaitingList (productId: string) {
    s(this.store).general.enableLoader()
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId ?? ''
    const result = await this.store.$api.removeFromWaitingList(productId, regionId, sectionId)
    if (result.code === 200 && result.data) {
      let notificationText = 'Товар удален из листа ожидания'
      if (this._ids.map(id => id.id).includes(productId)) {
        notificationText = 'Товар удален из листа ожидания'
      }
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: notificationText
      })
      await this.fillData()
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    }
    //  else {
    //   s(this.store).notification.addNotification({
    //     type: NotificationType.Error,
    //     text: 'Неизвестная ошибка'
    //   })
    // }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  updateIds () {
    /* const result = await this.store.$api.getWaitingListIds()

    if (result.code === 200 && result.data) {
      this.setIds(result.data)
    } else {
      this.setIds([])
    } */

    this.setIds(this.items.map(item => ({ id: item.id })))
  }

  get items (): CartItem[] {
    return this._items
  }

  get ids (): IdProductWaitingList[] {
    return this._ids
  }
}
