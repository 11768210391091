import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import * as sort from '~/utils/config/productSort'
import * as count from '~/utils/config/productCount'
import Preset from '~/types/api/Preset'
import config from '~/utils/config'
import s from '~/utils/s'
import PresetCategory from '~/types/api/PresetCategory'
import Result from '~/types/api/Result'

type Dictionary<T> = { [key: string]: T }

@Module({
  name: 'presetPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _preset: PresetCategory = {
    id: 'ø',
    parentId: '0',
    title: '',
    code: '',
    count: 0,
    availableCount: 0,
    items: []
  }

  _presetList: Preset[] = []

  _limit: number = 15
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  @Mutation
  setPreset (preset: PresetCategory) {
    this._preset = preset
  }

  @Mutation
  setPresetList (presetList: Preset[]) {
    this._presetList = presetList
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._preset.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Action({ rawError: config.rawError })
  async loadPresetList () {
    const result = await this.store.$api.getPresetList(s(this.store).regions.regionId ?? 0)

    if (result.code === 200 && result.data) {
      this.setPresetList(result.data)
    } else {
      this.setPresetList([])
    }
  }

  @Action({ rawError: config.rawError })
  async loadProducts (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    s(this.store).general.enableLoader()

    const query = route.query

    const limit = count.checkValue(query.count)
    const sortType = sort.checkValue(query.sort)
    const page = await this.getPageNumber(query)
    const offset = page * limit

    const options = {
      regionId: s(this.store).regions.regionId ?? 0,
      code: route.params.code,
      limit,
      offset,
      sortType: sortType === 'bySortAsc' ? '' : sortType
    }

    const result = await this.store.$api.getPresetProducts(options)

    await this.resultParse(result)

    this.setLimit(limit)
    this.setPage(page)

    s(this.store).general.disableLoader()

    return result.data
  }

  @Action({ rawError: config.rawError })
  getPageNumber (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  @Action({ rawError: config.rawError })
  resultParse (result: Result<PresetCategory>) {
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setPreset({
          ...this.preset,
          items: [...this.preset.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({
          top: offsetScroll,
          left: 0
        }), 0)
        this.disableLoadMore()
      } else {
        this.setPreset(result.data)
      }
    } else {
      this.setPreset({
        id: '0',
        parentId: '0',
        code: '',
        title: '',
        items: [],
        count: 0,
        availableCount: 0
      })
    }
  }

  get preset (): PresetCategory {
    return this._preset
  }

  get presetList (): Preset[] {
    return this._presetList
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.preset.count / this.limit)
  }
}
