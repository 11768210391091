import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import general from '~/store/general'
import regions from '~/store/regions'
import mainPage from '~/store/mainPage'
import auth from '~/store/auth'
import categoryPage from '~/store/categoryPage'
import basket from '~/store/basket'
import notification from '~/store/notification'
import unknownPage from '~/store/unknownPage'
import productPage from '~/store/productPage'
import orderPage from '~/store/orderPage'
import wishlist from '~/store/wishlist'
import searchBar from '~/store/searchBar'
import searchPage from '~/store/searchPage'
import newsPage from '~/store/newsPage'
import newsDetailPage from '~/store/newsDetailPage'
import stockPage from '~/store/stockPage'
import stockDetailPage from '~/store/stockDetailPage'
import personalPage from '~/store/personalPage'
import diseasesPage from '~/store/diseasesPage'
import popularPage from '~/store/popularPage'
import salePage from '~/store/salePage'
import pharmaciesPage from '~/store/pharmaciesPage'
import pharmacyDetailPage from '~/store/pharmacyDetailPage'
import presetPage from '~/store/presetPage'
import orderDetailPage from '~/store/orderDetailPage'
import filter from '~/store/filter'
import seo from '~/store/seo'
import diseaseDetailPage from '~/store/diseaseDetailPage'
import partnerCardBlock from '~/store/partnerCardBlock'
import productCardsCommercial from '~/store/productCardsCommercial'
import seoTextBlock from '~/store/seoTextBlock'
import helpPages from '~/store/helpPages'
import reviewsPage from '~/store/reviewsPage'
import waitingList from '~/store/waitingList'
import orderRating from '~/store/orderRating'
import recipePage from '~/store/recipePage'
import staticPages from '~/store/staticPages'
import collectionProduct from '~/store/collectionProduct'
import bannerPage from '~/store/bannerPage'
import categoriesPage from '~/store/categoriesPage'
import markingAd from '~/store/markingAd'
import userProductList from '~/store/userProductList'
import eCommerce from '~/store/eCommerce'
import noveltiesPage from '~/store/noveltiesPage'
import advertising from '~/store/advertising'

interface StoreType {
  general: general
  regions: regions
  mainPage: mainPage
  auth: auth
  categoryPage: categoryPage
  basket: basket
  notification: notification
  unknownPage: unknownPage
  productPage: productPage
  orderPage: orderPage
  wishlist: wishlist
  userProductList: userProductList
  searchBar: searchBar
  searchPage: searchPage
  newsPage: newsPage
  newsDetailPage: newsDetailPage
  stockPage: stockPage
  stockDetailPage: stockDetailPage
  personalPage: personalPage
  recipePage: recipePage
  diseasesPage: diseasesPage
  diseaseDetailPage: diseaseDetailPage
  popularPage: popularPage
  noveltiesPage: noveltiesPage,
  salePage: salePage
  presetPage: presetPage
  pharmaciesPage: pharmaciesPage
  pharmacyDetailPage: pharmacyDetailPage
  orderDetailPage: orderDetailPage
  seo: seo,
  partnerCardBlock: partnerCardBlock,
  productCardsCommercial: productCardsCommercial,
  seoTextBlock: seoTextBlock
  helpPages: helpPages
  reviewsPage: reviewsPage
  waitingList: waitingList
  orderRating: orderRating
  collectionProduct: collectionProduct
  bannerPage: bannerPage
  categoriesPage: categoriesPage
  markingAd: markingAd
  eCommerce: eCommerce
  advertising: advertising

  // filters
  categoryFilter: filter
  wishlistFilter: filter
  searchFilter: filter
  popularFilter: filter
  saleFilter: filter
  presetFilter: filter
  noveltiesFilter: filter,
  bannerFilter: filter,
  staticPages: staticPages
}

export default (store: Store<any>): StoreType => {
  return {
    general: getModule(general, store),
    regions: getModule(regions, store),
    mainPage: getModule(mainPage, store),
    auth: getModule(auth, store),
    categoryPage: getModule(categoryPage, store),
    basket: getModule(basket, store),
    notification: getModule(notification, store),
    unknownPage: getModule(unknownPage, store),
    productPage: getModule(productPage, store),
    presetPage: getModule(presetPage, store),
    orderPage: getModule(orderPage, store),
    wishlist: getModule(wishlist, store),
    userProductList: getModule(userProductList, store),
    searchBar: getModule(searchBar, store),
    searchPage: getModule(searchPage, store),
    newsPage: getModule(newsPage, store),
    newsDetailPage: getModule(newsDetailPage, store),
    stockPage: getModule(stockPage, store),
    stockDetailPage: getModule(stockDetailPage, store),
    personalPage: getModule(personalPage, store),
    diseasesPage: getModule(diseasesPage, store),
    diseaseDetailPage: getModule(diseaseDetailPage, store),
    popularPage: getModule(popularPage, store),
    noveltiesPage: getModule(noveltiesPage, store),
    salePage: getModule(salePage, store),
    pharmaciesPage: getModule(pharmaciesPage, store),
    pharmacyDetailPage: getModule(pharmacyDetailPage, store),
    orderDetailPage: getModule(orderDetailPage, store),
    seo: getModule(seo, store),
    partnerCardBlock: getModule(partnerCardBlock, store),
    productCardsCommercial: getModule(productCardsCommercial, store),
    seoTextBlock: getModule(seoTextBlock, store),
    helpPages: getModule(helpPages, store),
    reviewsPage: getModule(reviewsPage, store),
    waitingList: getModule(waitingList, store),
    orderRating: getModule(orderRating, store),
    collectionProduct: getModule(collectionProduct, store),
    bannerPage: getModule(bannerPage, store),
    categoriesPage: getModule(categoriesPage, store),
    markingAd: getModule(markingAd, store),
    eCommerce: getModule(eCommerce, store),
    advertising: getModule(advertising, store),

    // filters
    categoryFilter: getModule(filter, store),
    wishlistFilter: getModule(filter, store),
    searchFilter: getModule(filter, store),
    popularFilter: getModule(filter, store),
    saleFilter: getModule(filter, store),
    presetFilter: getModule(filter, store),
    recipePage: getModule(recipePage, store),
    noveltiesFilter: getModule(filter, store),
    staticPages: getModule(staticPages, store),
    bannerFilter: getModule(filter, store)
  }
}
