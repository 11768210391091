import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import StockDetailOptions from '~/types/api/options/StockDetailOptions'
import Product from '~/types/api/Product'
import ListResponse from '~/types/api/ListResponse'
import * as sort from '~/utils/config/productSort'
import config from '~/utils/config'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import s from '~/utils/s'
import Image from '~/types/api/Image'

@Module({
  name: 'stockDetailPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule implements ISeoDynamic {
  _list: ListResponse<Product> = {
    items: [],
    count: 0
  }

  // list
  _limit: number = 9
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  // stock
  _id: number = 0
  _code: string = ''
  _title: string = ''
  _dateFrom?: Date
  _dateTo?: Date
  _img?: Image = undefined
  _description: string = ''
  _HTMLContent: string = ''

  // start list mutation
  @Mutation
  setList (list: ListResponse<Product>) {
    this._list = list
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }
  // end list mutation

  // start stock mutation
  @Mutation
  setId (id: number) {
    this._id = id
  }

  @Mutation
  setCode (code: string) {
    this._code = code
  }

  @Mutation
  setTitle (title: string) {
    this._title = title
  }

  @Mutation
  setDateFrom (date?: Date) {
    if (date) {
      this._dateFrom = date
    } else {
      this._dateFrom = undefined
    }
  }

  @Mutation
  setDateTo (date?: Date) {
    if (date) {
      this._dateTo = date
    } else {
      this._dateTo = undefined
    }
  }

  @Mutation
  setImg (img?: Image) {
    this._img = img
  }

  @Mutation
  setDescription (description?: string) {
    if (description) {
      this._description = description
    } else {
      this._description = ''
    }
  }

  @Mutation
  setHTMLContent (content?: string) {
    if (content) {
      this._HTMLContent = content
    } else {
      this._HTMLContent = ''
    }
  }
  // end stock mutation

  @Action({ rawError: config.rawError })
  async loadData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    await this.loadStockInfo(route)
    // await this.loadProducts(route)
  }

  @Action({ rawError: config.rawError })
  async loadStockInfo (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const params = route.params
    let code = ''
    if (typeof params.code === 'string') {
      code = params.code
    }

    if (!code) {
      s(this.store).general.redirectNotFound()
    }

    const options: StockDetailOptions = {
      code,
      regionId: s(this.store).regions.regionId ?? 0
    }

    const result = await this.store.$api.getStock(options)
    if (result.code === 200 && result.data) {
      this.setId(result.data.id)
      this.setCode(result.data.code)
      this.setTitle(result.data.title)
      this.setDateFrom(result.data.dateFrom)
      this.setDateTo(result.data.dateTo)
      this.setImg(result.data.image)
      this.setHTMLContent(result.data.HTMLContent)
      this.setDescription(result.data.description)
      // this.setList({ items: result.data.products, count: result.data.products.length })
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  @Action({ rawError: config.rawError })
  async loadProducts (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }

    const query = route.query
    const sortType = sort.checkValue(query.sort)
    let page = 0
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
    }
    const offset = page * this._limit

    const result = await this.store.$api.getStockProducts({
      code: this._code,
      limit: this._limit,
      offset,
      regionId: s(this.store).regions.regionId ?? 0,
      sortType,
      sectionId: s(this.store).regions.sectionId
    })

    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({ top: offsetScroll, left: 0 }), 0)
        this.disableLoadMore()
      } else {
        this.setList(result.data)
      }
    } else {
      this.setList({
        items: [],
        count: 0
      })
    }
    this.setPage(page)
    s(this.store).general.disableLoader()
  }

  // start list props
  get list (): ListResponse<Product> {
    return this._list
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }
  // end list props

  // start stock props
  get id (): number {
    return this._id
  }

  get code (): string {
    return this._code
  }

  get title (): string {
    return this._title
  }

  get dateFrom () {
    return this._dateFrom
  }

  get dateTo () {
    return this._dateTo
  }

  get img (): Image | undefined {
    return this._img
  }

  get description (): string {
    return this._description
  }

  get HTMLContent (): string {
    return this._HTMLContent
  }
  // end stock props

  get seoCode (): string {
    return this.code
  }
}
