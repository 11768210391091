import { Vue, Component } from 'vue-property-decorator'
import BgSvg from '~/assets/svg/404-bg.svg?inline'
import NotFountSvg from '~/assets/svg/404.svg?inline'
import s from '~/utils/s'
import { MetrikaLocalCodes } from '~/types/api/MetrikaCode'

@Component
export default class Error404 extends Vue {
  async mounted () {
    this.$reachGoal(await s(this.$store).seo.getMetrikaCode(MetrikaLocalCodes.PAGE_404))
  }

  render () {
    return (
      <div class="not-found-error-page">
        <div class="not-found-error-page__bg">
          <BgSvg />
        </div>
        <div class="not-found-error-page__content">
          <div class="not-found-error-page__header">
            Страница не существует
          </div>
          <div class="not-found-error-page__main-link-wrapper">
            <nuxt-link to="/" class="not-found-error-page__main-link button-text">
              На главную
            </nuxt-link>
          </div>
          <div class="not-found-error-page__image">
            <NotFountSvg />
          </div>
          <div class="not-found-error-page__descriptio-wrapper">
            <div class="not-found-error-page__description">
              Все в&nbsp;порядке! Возможно, то, что вы&nbsp;ищете, есть в&nbsp;популярных категориях            </div>
          </div>
        </div>
      </div>
    )
  }
}
