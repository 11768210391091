import { Vue, Component } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import Btn from '~/components/element/Btn'
import TextInput from '~/components/element/TextInput'
import RatingInput from '~/components/element/RatingInput'
import s from '~/utils/s'
import Review from '~/types/api/Review'
import UnknownPageType from '~/types/enum/UnknownPageType'

@Component({
  components: {
    ValidationObserver
  }
})
export default class ReviewPopup extends Vue {
  review: Review = {
    text: '',
    rating: null
  }

  async send () {
    const { id: productId } = s(this.$store).productPage

    try {
      const userReview = s(this.$store).productPage.reviews.find(review => review.userId === s(this.$store).auth.user?.id)

      if (userReview) {
        await s(this.$store).reviewsPage.updateReview({
          ...this.review,
          productId
        })
      } else {
        await s(this.$store).reviewsPage.addReview({
          ...this.review,
          productId
        })
      }
      await this.afterSend()
    } catch (e) {}
  }

  async afterSend () {
    const { closePopup } = s(this.$store).general
    const { loadProduct, fetchReviews } = s(this.$store).reviewsPage
    const { id, loadReviewsForProduct } = s(this.$store).productPage

    if (id) {
      s(this.$store).general.enableLoader()

      await loadProduct(id)
      await loadReviewsForProduct(id, s(this.$store).regions.regionId)

      if (UnknownPageType.Reviews) {
        await fetchReviews()
      }

      s(this.$store).general.disableLoader()
    }

    closePopup()
  }

  renderTextarea () {
    return (
      <TextInput key="text" {...{
        props: {
          name: 'text',
          label: 'Текст отзыва',
          type: 'textarea',
          rules: 'required',
          value: this.review.text
        },
        on: {
          input: (v: string) => this.review.text = v
        }
      }} />
    )
  }

  render () {
    const userReview = s(this.$store).productPage.reviews.find(review => review.userId === s(this.$store).auth.user?.id)

    if (userReview) {
      this.review = { ...userReview }
    }

    return (
      <validation-observer ref="form">
        {({ handleSubmit }: any) => (
          <div class="popup-review">
            <div class="popup-review__header">
              { userReview?.id
                ? 'Изменить свой отзыв'
                : 'Оставить отзыв'
              }
            </div>

            <div class="popup-review__content">
              <RatingInput {...{
                props: {
                  label: 'Оценка',
                  name: 'rating',
                  value: this.review.rating,
                  rules: 'required'
                },
                on: {
                  input: (v: number) => this.review.rating = v
                }
              }} />

              { this.renderTextarea() }
            </div>

            <div class="popup-review__footer">
              <Btn {...{
                props: {
                  label: 'Отправить',
                  wide: true
                },
                nativeOn: {
                  click: () => handleSubmit(this.send)
                }
              }} />
            </div>
          </div>
        )}
      </validation-observer>
    )
  }
}
