import { Vue, Component } from 'vue-property-decorator'
import MenuList from '~/components/general/generalList/MenuList'
import ArrowSvg from '~/assets/svg/personal-menu-arrow.svg?inline'
import LocationSvg from '~/assets/svg/personal-menu-location.svg?inline'
import PhoneSvg from '~/assets/svg/personal-menu-phone.svg?inline'
import HeartSvg from '~/assets/svg/personal-menu-heart-bordered.svg?inline'
import PersonalSvg from '~/assets/svg/personal-menu-man.svg?inline'
import { Region } from '~/types/api/Region'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class MobilePersonalList extends Vue {
  get selectedParentRegion (): Region | undefined {
    return s(this.$store).regions.region
  }

  openLocationPopup (event: MouseEvent) {
    event.stopPropagation()
    s(this.$store).general.showPopup('RegionPopup')
  }

  renderPhone () {
    return (
      <div class="mobile-personal-list__item-wrapper">
        <a href={s(this.$store).regions.phoneLink} class="mobile-personal-list__item">
          <div class="mobile-personal-list__item-logo">
            <PhoneSvg />
          </div>
          <div class="mobile-personal-list__item-text">
            {s(this.$store).regions.phone}
          </div>
        </a>
      </div>
    )
  }

  renderLocation () {
    return (
      <div class="mobile-personal-list__item-wrapper">
        <div class="mobile-personal-list__item" onClick={this.openLocationPopup}>
          <div class="mobile-personal-list__item-logo">
            <LocationSvg />
          </div>
          <div class="mobile-personal-list__item-text">
            {this.selectedParentRegion?.name}
          </div>
          <div class="mobile-personal-list__item-arrow">
            <ArrowSvg />
          </div>
        </div>
      </div>
    )
  }

  renderPersonal () {
    return (
      <div class="mobile-personal-list__item-wrapper mobile-personal-list__item-wrapper_green">
        <nuxt-link {...{
          class: ['mobile-personal-list__item', 'mobile-personal-list__item_green'],
          props: {
            to: '/personal/'
          },
          nativeOn: {
            click: () => s(this.$store).general.closeGeneralList()
          }
        }}>
          <div class="mobile-personal-list__item-logo">
            <PersonalSvg />
          </div>
          <div class="mobile-personal-list__item-text">
            {s(this.$store).auth.isAuth ? s(this.$store).auth.user?.fullName : 'Войти'}
          </div>
        </nuxt-link>
      </div>
    )
  }

  renderWishlist () {
    return (
      <div class="mobile-personal-list__item-wrapper">
        <div {...{
          class: 'mobile-personal-list__item',
          on: {
            click: (event: MouseEvent) => {
              const { isAuth } = s(this.$store).auth
              if (!isAuth) {
                event.stopPropagation()
                s(this.$store).general.showPopup('AuthPopup')
              } else {
                s(this.$store).general.closeGeneralList()
                this.$store.$router.push(loc(this.$store, { name: 'wishlist' }))
              }
            }
          }
        }}>
          <div class="mobile-personal-list__item-logo">
            <HeartSvg />
          </div>
          <div class="mobile-personal-list__item-text">
            Избранное
          </div>
          {s(this.$store).wishlist.ids.length > 0 &&
            <div class="mobile-personal-list__item-count">
              {s(this.$store).wishlist.ids.length}
            </div>
          }
          <div class="mobile-personal-list__item-arrow">
            <ArrowSvg />
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div class="mobile-personal-list">
        <div class="mobile-personal-list__wrapper-personal">
          <div class="mobile-personal-list__personal-list">
            {this.renderPhone()}
            {this.renderLocation()}
            {this.renderPersonal()}
            {this.renderWishlist()}
          </div>
        </div>
        <div class="mobile-personal-list__wrapper-menu">
          <MenuList />
        </div>
      </div>
    )
  }
}
