import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { NuxtApp } from '@nuxt/types/app'
import ListResponse from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import ResponseGroupBuilder from '~/utils/api/ResponseGroupBuilder'
import { MainSliderItem } from '~/types/api/MainSliderItem'
import { MainSliderAdvertisementItem } from '~/types/api/MainSliderAdvertisementItem'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import config from '~/utils/config'
import s from '~/utils/s'
import { parseJwt } from '~/utils/jwt'
import Stock from '~/types/api/stock/Stock'

@Module({
  name: 'mainPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _promotions: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _popular: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _novelties: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _products: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _listForSlides: ListResponse<Stock> = {
    items: [],
    count: 0
  }

  _mainSliderItems: MainSliderItem[] | null = null

  _mainSliderAdvertisementItems: MainSliderAdvertisementItem[] | null = null

  _diseaseSections: DiseaseSection[] = []

  @Mutation
  setPromotions(promotions: ListResponse<Product>) {
    this._promotions = promotions
  }

  @Mutation
  setPopular(popular: ListResponse<Product>) {
    this._popular = popular
  }

  @Mutation
  setNovelties(novelties: ListResponse<Product>) {
    this._novelties = novelties
  }

  @Mutation
  setMainSliderItems(items: MainSliderItem[] | null) {
    this._mainSliderItems = items
  }

  @Mutation
  setMainSliderAdvertisementItems(items: MainSliderAdvertisementItem[] | null) {
    this._mainSliderAdvertisementItems = items
  }

  @Mutation
  setDiseaseSections(sections: DiseaseSection[]) {
    this._diseaseSections = sections
  }

  @Mutation
  setProducts(products: ListResponse<Product>) {
    this._products = products
  }

  @Mutation
  setListForSlides (list: ListResponse<Stock>) {
    this._listForSlides = list
  }

  @Action({ rawError: config.rawError })
  async fillBannerData() {
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId
    const result = await ResponseGroupBuilder.get([
      {
        task: this.store.$api.getMainSliderItems(regionId, sectionId),
        default: [],
        key: 'mainSliderItems'
      },
      {
        task: this.store.$api.getMainSliderAdvertisementItems(regionId, sectionId),
        default: [],
        key: 'mainSliderAdvertisementItems'
      }
    ])
    for (const [key, value] of Object.entries(result)) {
      if (value != null) {
        switch (key) {
          case 'mainSliderItems':
            this.setMainSliderItems(value as MainSliderItem[])
            break
          case 'mainSliderAdvertisementItems':
            this.setMainSliderAdvertisementItems(value as MainSliderAdvertisementItem[])
            break
        }
      }
    }
  }

  @Action({ rawError: config.rawError })
  async fillData() {
    const app = this.store.$router.app as NuxtApp
    const isDev = app.context.isDev
    const regionId = s(this.store).regions.regionId ?? '0'
    const sectionId = s(this.store).regions.sectionId
    if (s(this.store).auth.refreshToken) {
      const payload = parseJwt(s(this.store).auth.refreshToken)
      if (payload.exp < Date.now() / 1000) {
        await s(this.store).auth.refreshTokenAction()
      }
    }
    const result = await ResponseGroupBuilder.get([
      {
        task: this.store.$api.getProductPromotions({
          limit: 20,
          offset: 0,
          regionId,
          sectionId,
          mainPage: true
        }),
        default: {
          count: 0,
          items: []
        },
        key: 'promotions'
      },
      {
        task: this.store.$api.getProductPopular({
          limit: 20,
          offset: 0,
          regionId,
          sectionId,
          mainPage: true,
          devRequest: isDev
        }),
        default: {
          count: 0,
          items: []
        },
        key: 'popular'
      },
      {
        task: this.store.$api.getProductNovelties({
          limit: 20,
          offset: 0,
          regionId,
          sectionId,
          mainPage: true,
          devRequest: isDev
        }),
        default: {
          count: 0,
          items: []
        },
        key: 'novelties'
      },
      {
        task: this.store.$api.getDiseaseSections(),
        default: [],
        key: 'diseaseSections'
      },
      {
        task: this.store.$api.getStocks({
          limit: 20,
          offset: 0,
          regionId,
          sectionId,
          mainPage: true
        }),
        default: {
          count: 0,
          items: []
        },
        key: 'stocks'
      }
    ])

    for (const [key, value] of Object.entries(result)) {
      if (value != null) {
        switch (key) {
          case 'promotions':
            this.setPromotions(value as ListResponse<Product>)
            break
          case 'popular':
            this.setPopular(value as ListResponse<Product>)
            break
          case 'novelties':
            this.setNovelties(value as ListResponse<Product>)
            break
          case 'diseaseSections':
            this.setDiseaseSections(value as DiseaseSection[])
            break
          case 'products':
            this.setProducts(value as ListResponse<Product>)
            break
          case 'stocks':
            this.setListForSlides(value as ListResponse<Stock>)
            break
        }
      }
    }
  }

  @Action({ rawError: config.rawError })
  clearData() {
    const emptyList: ListResponse<Product> = {
      count: 0,
      items: []
    }
    this.setProducts(emptyList)
    this.setPromotions(emptyList)
    this.setPopular(emptyList)
    this.setNovelties(emptyList)
    this.setDiseaseSections([])
  }

  @Action({ rawError: config.rawError })
  clearBannerData() {
    this.setMainSliderItems(null)
    this.setMainSliderAdvertisementItems(null)
  }

  get products(): ListResponse<Product> {
    return this._products
  }

  get listForSlides (): ListResponse<Stock> {
    return this._listForSlides
  }

  get promotions (): ListResponse<Product> {
    return this._promotions
  }

  get novelties(): ListResponse<Product> {
    return this._novelties
  }

  get popular(): ListResponse<Product> {
    return this._popular
  }

  get mainSliderItems(): MainSliderItem[] | null {
    return this._mainSliderItems
  }

  get mainSliderAdvertisementItems(): MainSliderAdvertisementItem[] | null {
    return this._mainSliderAdvertisementItems
  }

  get diseaseSections(): DiseaseSection[] {
    return this._diseaseSections
  }
}
