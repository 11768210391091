import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _596389c6 = () => interopDefault(import('../pages/personal/index.tsx' /* webpackChunkName: "pages/personal/index" */))
const _0b8abe44 = () => interopDefault(import('../pages/order/success.tsx' /* webpackChunkName: "pages/order/success" */))
const _c6144398 = () => interopDefault(import('../pages/personal/login.tsx' /* webpackChunkName: "pages/personal/login" */))
const _556afbe9 = () => interopDefault(import('../pages/personal/reset-password-data.tsx' /* webpackChunkName: "pages/personal/reset-password-data" */))
const _9ab96938 = () => interopDefault(import('../pages/personal/reset-password.tsx' /* webpackChunkName: "pages/personal/reset-password" */))
const _14796c6e = () => interopDefault(import('../pages/index.tsx' /* webpackChunkName: "pages/index" */))
const _f38d4f1c = () => interopDefault(import('../pages/_region/index.tsx' /* webpackChunkName: "pages/_region/index" */))
const _425b8a9c = () => interopDefault(import('../pages/_region/basket.tsx' /* webpackChunkName: "pages/_region/basket" */))
const _2a476cfc = () => interopDefault(import('../pages/_region/catalog/index.tsx' /* webpackChunkName: "pages/_region/catalog/index" */))
const _1e93d9e0 = () => interopDefault(import('../pages/_region/diseases/index.tsx' /* webpackChunkName: "pages/_region/diseases/index" */))
const _002649ec = () => interopDefault(import('../pages/_region/news/index.tsx' /* webpackChunkName: "pages/_region/news/index" */))
const _c1f7ce24 = () => interopDefault(import('../pages/_region/order.tsx' /* webpackChunkName: "pages/_region/order" */))
const _6ddd9399 = () => interopDefault(import('../pages/_region/personal/index.tsx' /* webpackChunkName: "pages/_region/personal/index" */))
const _5185075e = () => interopDefault(import('../pages/_region/personal/index/orders.tsx' /* webpackChunkName: "pages/_region/personal/index/orders" */))
const _16bc37a2 = () => interopDefault(import('../pages/_region/personal/index/recipes.tsx' /* webpackChunkName: "pages/_region/personal/index/recipes" */))
const _7683e4c8 = () => interopDefault(import('../pages/_region/personal/index/index.tsx' /* webpackChunkName: "pages/_region/personal/index/index" */))
const _36e361ea = () => interopDefault(import('../pages/_region/pharmacies/index.tsx' /* webpackChunkName: "pages/_region/pharmacies/index" */))
const _269d1504 = () => interopDefault(import('../pages/_region/search.tsx' /* webpackChunkName: "pages/_region/search" */))
const _77c4d4f9 = () => interopDefault(import('../pages/_region/stock/index.tsx' /* webpackChunkName: "pages/_region/stock/index" */))
const _138d6ac2 = () => interopDefault(import('../pages/_region/userProductList.tsx' /* webpackChunkName: "pages/_region/userProductList" */))
const _a71e3f4a = () => interopDefault(import('../pages/_region/wishlist.tsx' /* webpackChunkName: "pages/_region/wishlist" */))
const _14d709fc = () => interopDefault(import('../pages/_region/catalog/novinki.tsx' /* webpackChunkName: "pages/_region/catalog/novinki" */))
const _09f347c3 = () => interopDefault(import('../pages/_region/catalog/popular.tsx' /* webpackChunkName: "pages/_region/catalog/popular" */))
const _5537d993 = () => interopDefault(import('../pages/_region/catalog/sale.tsx' /* webpackChunkName: "pages/_region/catalog/sale" */))
const _fc28fbd6 = () => interopDefault(import('../pages/_region/help/about.tsx' /* webpackChunkName: "pages/_region/help/about" */))
const _52a8f94a = () => interopDefault(import('../pages/_region/help/delivery-payment.tsx' /* webpackChunkName: "pages/_region/help/delivery-payment" */))
const _6960a7e2 = () => interopDefault(import('../pages/_region/help/how-get-order.tsx' /* webpackChunkName: "pages/_region/help/how-get-order" */))
const _4fc06fba = () => interopDefault(import('../pages/_region/help/how-make-order.tsx' /* webpackChunkName: "pages/_region/help/how-make-order" */))
const _5b320ae1 = () => interopDefault(import('../pages/_region/help/pharmacy.tsx' /* webpackChunkName: "pages/_region/help/pharmacy" */))
const _70472ec0 = () => interopDefault(import('../pages/_region/help/policy.tsx' /* webpackChunkName: "pages/_region/help/policy" */))
const _3234b112 = () => interopDefault(import('../pages/_region/help/rule-service.tsx' /* webpackChunkName: "pages/_region/help/rule-service" */))
const _c3f569c2 = () => interopDefault(import('../pages/_region/personal/orders/_id.tsx' /* webpackChunkName: "pages/_region/personal/orders/_id" */))
const _0d11c9aa = () => interopDefault(import('../pages/_region/about/_code.tsx' /* webpackChunkName: "pages/_region/about/_code" */))
const _f8e23582 = () => interopDefault(import('../pages/_region/banner/_code.tsx' /* webpackChunkName: "pages/_region/banner/_code" */))
const _6b9eb49d = () => interopDefault(import('../pages/_region/categories/_id.tsx' /* webpackChunkName: "pages/_region/categories/_id" */))
const _6c428f99 = () => interopDefault(import('../pages/_region/disease/_code.tsx' /* webpackChunkName: "pages/_region/disease/_code" */))
const _77cf5f44 = () => interopDefault(import('../pages/_region/diseases/_section.tsx' /* webpackChunkName: "pages/_region/diseases/_section" */))
const _b923e458 = () => interopDefault(import('../pages/_region/help/_code.tsx' /* webpackChunkName: "pages/_region/help/_code" */))
const _01f0fae6 = () => interopDefault(import('../pages/_region/news/_code.tsx' /* webpackChunkName: "pages/_region/news/_code" */))
const _3a9cde12 = () => interopDefault(import('../pages/_region/pharmacies/_id.tsx' /* webpackChunkName: "pages/_region/pharmacies/_id" */))
const _78ba8191 = () => interopDefault(import('../pages/_region/presets/_code.tsx' /* webpackChunkName: "pages/_region/presets/_code" */))
const _798f85f3 = () => interopDefault(import('../pages/_region/stock/_code.tsx' /* webpackChunkName: "pages/_region/stock/_code" */))
const _7082c3ff = () => interopDefault(import('../pages/_region/_.tsx' /* webpackChunkName: "pages/_region/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/personal/",
    component: _596389c6,
    pathToRegexpOptions: {"strict":true},
    name: "personal"
  }, {
    path: "/order/success/",
    component: _0b8abe44,
    pathToRegexpOptions: {"strict":true},
    name: "order-success"
  }, {
    path: "/personal/login/",
    component: _c6144398,
    pathToRegexpOptions: {"strict":true},
    name: "personal-login"
  }, {
    path: "/personal/reset-password-data/",
    component: _556afbe9,
    pathToRegexpOptions: {"strict":true},
    name: "personal-reset-password-data"
  }, {
    path: "/personal/reset-password/",
    component: _9ab96938,
    pathToRegexpOptions: {"strict":true},
    name: "personal-reset-password"
  }, {
    path: "/",
    component: _14796c6e,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:region/",
    component: _f38d4f1c,
    pathToRegexpOptions: {"strict":true},
    name: "region"
  }, {
    path: "/:region/basket/",
    component: _425b8a9c,
    pathToRegexpOptions: {"strict":true},
    name: "region-basket"
  }, {
    path: "/:region/catalog/",
    component: _2a476cfc,
    pathToRegexpOptions: {"strict":true},
    name: "region-catalog"
  }, {
    path: "/:region/diseases/",
    component: _1e93d9e0,
    pathToRegexpOptions: {"strict":true},
    name: "region-diseases"
  }, {
    path: "/:region/news/",
    component: _002649ec,
    pathToRegexpOptions: {"strict":true},
    name: "region-news"
  }, {
    path: "/:region/order/",
    component: _c1f7ce24,
    pathToRegexpOptions: {"strict":true},
    name: "region-order"
  }, {
    path: "/:region/personal/",
    component: _6ddd9399,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "orders/",
      component: _5185075e,
      pathToRegexpOptions: {"strict":true},
      name: "region-personal-index-orders"
    }, {
      path: "recipes/",
      component: _16bc37a2,
      pathToRegexpOptions: {"strict":true},
      name: "region-personal-index-recipes"
    }, {
      path: "",
      component: _7683e4c8,
      pathToRegexpOptions: {"strict":true},
      name: "region-personal-index"
    }]
  }, {
    path: "/:region/pharmacies/",
    component: _36e361ea,
    pathToRegexpOptions: {"strict":true},
    name: "region-pharmacies"
  }, {
    path: "/:region/search/",
    component: _269d1504,
    pathToRegexpOptions: {"strict":true},
    name: "region-search"
  }, {
    path: "/:region/stock/",
    component: _77c4d4f9,
    pathToRegexpOptions: {"strict":true},
    name: "region-stock"
  }, {
    path: "/:region/userProductList/",
    component: _138d6ac2,
    pathToRegexpOptions: {"strict":true},
    name: "region-userProductList"
  }, {
    path: "/:region/wishlist/",
    component: _a71e3f4a,
    pathToRegexpOptions: {"strict":true},
    name: "region-wishlist"
  }, {
    path: "/:region/catalog/novinki/",
    component: _14d709fc,
    pathToRegexpOptions: {"strict":true},
    name: "region-catalog-novinki"
  }, {
    path: "/:region/catalog/popular/",
    component: _09f347c3,
    pathToRegexpOptions: {"strict":true},
    name: "region-catalog-popular"
  }, {
    path: "/:region/catalog/sale/",
    component: _5537d993,
    pathToRegexpOptions: {"strict":true},
    name: "region-catalog-sale"
  }, {
    path: "/:region/help/about/",
    component: _fc28fbd6,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-about"
  }, {
    path: "/:region/help/delivery-payment/",
    component: _52a8f94a,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-delivery-payment"
  }, {
    path: "/:region/help/how-get-order/",
    component: _6960a7e2,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-how-get-order"
  }, {
    path: "/:region/help/how-make-order/",
    component: _4fc06fba,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-how-make-order"
  }, {
    path: "/:region/help/pharmacy/",
    component: _5b320ae1,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-pharmacy"
  }, {
    path: "/:region/help/policy/",
    component: _70472ec0,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-policy"
  }, {
    path: "/:region/help/rule-service/",
    component: _3234b112,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-rule-service"
  }, {
    path: "/:region/personal/orders/:id?/",
    component: _c3f569c2,
    pathToRegexpOptions: {"strict":true},
    name: "region-personal-orders-id"
  }, {
    path: "/:region/about/:code?/",
    component: _0d11c9aa,
    pathToRegexpOptions: {"strict":true},
    name: "region-about-code"
  }, {
    path: "/:region/banner/:code?/",
    component: _f8e23582,
    pathToRegexpOptions: {"strict":true},
    name: "region-banner-code"
  }, {
    path: "/:region/categories/:id?/",
    component: _6b9eb49d,
    pathToRegexpOptions: {"strict":true},
    name: "region-categories-id"
  }, {
    path: "/:region/disease/:code?/",
    component: _6c428f99,
    pathToRegexpOptions: {"strict":true},
    name: "region-disease-code"
  }, {
    path: "/:region/diseases/:section/",
    component: _77cf5f44,
    pathToRegexpOptions: {"strict":true},
    name: "region-diseases-section"
  }, {
    path: "/:region/help/:code?/",
    component: _b923e458,
    pathToRegexpOptions: {"strict":true},
    name: "region-help-code"
  }, {
    path: "/:region/news/:code/",
    component: _01f0fae6,
    pathToRegexpOptions: {"strict":true},
    name: "region-news-code"
  }, {
    path: "/:region/pharmacies/:id/",
    component: _3a9cde12,
    pathToRegexpOptions: {"strict":true},
    name: "region-pharmacies-id"
  }, {
    path: "/:region/presets/:code?/",
    component: _78ba8191,
    pathToRegexpOptions: {"strict":true},
    name: "region-presets-code"
  }, {
    path: "/:region/stock/:code/",
    component: _798f85f3,
    pathToRegexpOptions: {"strict":true},
    name: "region-stock-code"
  }, {
    path: "/:region/*",
    component: _7082c3ff,
    pathToRegexpOptions: {"strict":true},
    name: "region-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
