import { Vue, Component } from 'vue-property-decorator'
import MapIconSvg from '~/assets/svg/marker.svg'
import MapSelectedIconSvg from '~/assets/svg/marker-selected.svg'
import LocationSvg from '~/assets/svg/location-order.svg?inline'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import LocationDistrict from '~/types/api/LocationDistrict'
import SelectorArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import s from '~/utils/s'

enum TypeTab {
  Map,
  List
}

interface Marker {
  id: string,
  selected: boolean,
  coords: number[],
  size: number[],
  url: any
}

@Component
export default class PickupSelectorPopupMobile extends Vue {
  typeTap: TypeTab = TypeTab.Map
  searchInput: string = ''
  coords: number[] = [55.996857, 92.912626]
  zoom: number = 14
  showSearchList: boolean = false
  selectedMapPickpoint: string = ''
  selectedListPickpoint: string = ''
  districtId: string = '0'
  showSelector: boolean = false

  get bounds () {
    const points = s(this.$store).orderPage.pickpoints.filter(e => e.point.lat && e.point.lng).map(e => e.point)
    const lat = [...points].sort((a, b) => a.lat - b.lat)
    const lng = [...points].sort((a, b) => a.lng - b.lng)
    return [
      [lat[lat.length - 1]?.lat, lng[0]?.lng],
      [lat[0]?.lat, lng[lng.length - 1]?.lng]
    ]
  }

  get pickpointMarkers (): Marker[] {
    return this.pickpointBySearch.filter(e => e.point).map(e => ({
      id: e.id,
      selected: e.id === this.selectedMapPickpoint,
      coords: [e.point.lat, e.point.lng],
      size: [20, 31],
      url: this.selectedMapPickpoint === e.id ? MapSelectedIconSvg : MapIconSvg
    }))
  }

  get pickpointBySearch () {
    let list = s(this.$store).orderPage.pickpoints
    if (this.searchInput.length > 2) {
      const query = this.searchInput.toLowerCase()
      list = s(this.$store).orderPage.pickpoints.filter((e) => {
        const name = e.name.toLowerCase()
        const nameForSite = e.nameForSite?.toLowerCase()
        const address = e.address.toLowerCase()
        const description = e.description.toLowerCase()
        return name.includes(query) || address.includes(query) || description.includes(query) || nameForSite?.includes(query)
      })
    }

    return list
  }

  get pickpointsByFilterDiscrict () {
    let list = s(this.$store).orderPage.pickpoints
    if (this.districtId !== '0') {
      list = list.filter(e => e.district.id === this.districtId)
    }

    return list
  }

  get districtList () {
    const districtList: LocationDistrict[] = s(this.$store).orderPage.pickpoints
      .filter(e => e.district)
      .map(e => e.district)
    return districtList.reduce<LocationDistrict[]>((acc, current) => {
      const x = acc.find(item => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [{
      id: '0',
      xmlId: '0',
      name: 'Все районы'
    }])
  }

  clickByMap (id: string) {
    this.selectedMapPickpoint = id
  }

  clickBySearchList (id: string) {
    this.selectedMapPickpoint = id
    this.clearSearch()
  }

  clearSearch () {
    this.searchInput = ''
    this.showSearchList = false
  }

  selectPickpount () {
    if (this.typeTap === TypeTab.Map && this.selectedMapPickpoint !== '') {
      s(this.$store).orderPage.setPickpointId(this.selectedMapPickpoint)
    } else if (this.typeTap === TypeTab.List && this.selectedListPickpoint !== '') {
      s(this.$store).orderPage.setPickpointId(this.selectedListPickpoint)
    }

    s(this.$store).general.closePopup()
  }

  selectDistrict (e: string) {
    this.districtId = e
    this.showSelector = false
  }

  renderMap () {
    return (
      <div class="pickup-selector-popup-mobile__wrapper">
        <div class="pickup-selector-popup-mobile__map">
          <yandex-map {...{
            props: {
              coords: this.coords,
              zoom: this.zoom,
              bounds: this.bounds,
              'scroll-zoom': false,
              controls: [
                'zoomControl'
              ],
              clusterOptions: {
                cluster: {
                  clusterDisableClickZoom: false,
                  clusterIconColor: '#296654',
                  gridSize: 50,
                  zoomMargin: [100, 20, 60, 20],
                  openBalloonOnClick: false
                }
              }
            }
          }}>
            {this.pickpointMarkers.map((e) => {
              return (
                <ymap-marker {...{
                  props: {
                    'marker-id': e.id,
                    coords: e.coords,
                    clusterName: 'cluster',
                    icon: {
                      layout: 'default#imageWithContent',
                      imageSize: e.size,
                      imageHref: e.url,
                      imageOffset: [-e.size[0] / 2, -e.size[1]],
                      contentOffset: [0, 0]
                    }
                  },
                  on: {
                    click: () => this.clickByMap(e.id)
                  },
                  key: `${e.id}_${e.selected}`
                }} />
              )
            })}
          </yandex-map>
        </div>
        <div class="pickup-selector-popup-mobile__search-wrapper" {...{
          directives: [{
            name: 'VueClickOutside',
            value: () => this.clearSearch()
          }]
        }}>
          <div class="pickup-selector-popup-mobile__search">
            <div class="pickup-selector-popup-mobile__search-icon">
              <LocationSvg />
            </div>
            <input type="text"
              class="pickup-selector-popup-mobile__search-input"
              v-model={this.searchInput}
              onFocus={() => { this.showSearchList = true }}
              placeholder="Поиск по адресу" />
            <div class="pickup-selector-popup-mobile__search-clear" onClick={() => this.clearSearch()}>
              <CloseSvg />
            </div>
          </div>
          {this.showSearchList &&
            <div class="pickup-selector-popup-mobile__search-list">
              {this.pickpointBySearch.map(e => (
                <div class="pickup-selector-popup-mobile__search-list-item"
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation()
                    this.clickBySearchList(e.id)
                  }}>
                  {e.nameForSite ? e.nameForSite : e.name}
                </div>
              ))}
            </div>
          }
        </div>
        {this.selectedMapPickpoint !== '' &&
          <div class="pickup-selector-popup-mobile__pickpoint-selected">
            {s(this.$store).orderPage.pickpoints.find(e => e.id === this.selectedMapPickpoint)?.nameForSite}
          </div>
        }
      </div>
    )
  }

  renderList () {
    return (
      <div class="pickup-selector-popup-mobile__list-wrapper">
        <div class="pickup-selector-popup-mobile__list-filter">
          <div {...{
            class: 'link-selector',
            directives: [{
              name: 'VueClickOutside',
              value: () => { this.showSelector = false }
            }]
          }}>
            <div {...{
              class: 'link-selector__selected',
              on: {
                click: () => { this.showSelector = !this.showSelector }
              }
            }}>
              <div class="link-selector__selected-text">
                {this.districtList.find(e => e.id === this.districtId)?.name}
              </div>
              <div class="link-selector__selected-arrow">
                <SelectorArrowSvg />
              </div>
            </div>
            {this.showSelector &&
              <div class="link-selector__list">
                {this.districtList.map(e => (
                  <div {...{
                    class: 'link-selector__item',
                    on: {
                      click: (event: MouseEvent) => {
                        event.stopPropagation()
                        this.selectDistrict(e.id)
                      }
                    }
                  }}>{e.name}</div>
                ))}
              </div>
            }
          </div>
        </div>
        <div class="pickup-selector-popup-mobile__list">
          {this.pickpointsByFilterDiscrict.map(e => (
            <div {...{
              class: {
                'pickup-selector-popup-mobile__list-item': true,
                'pickup-selector-popup-mobile__list-item_selected': e.id === this.selectedListPickpoint
              },
              on: {
                click: () => { this.selectedListPickpoint = e.id }
              }
            }}>
              <div class="pickup-selector-popup-mobile__list-item-name">
                {e.nameForSite ? e.nameForSite : e.name}
              </div>
              <div class="pickup-selector-popup-mobile__list-item-address">
                {e.address}
              </div>
              <div class="pickup-selector-popup-mobile__list-item-work-time">
                {e.shortWorkTime}
              </div>
              <div class="pickup-selector-popup-mobile__list-item-phone">
                {e.phone}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderTabSelector () {
    return (
      <div class="pickup-selector-popup-mobile__selector">
        <div {...{
          class: {
            'pickup-selector-popup-mobile__selector-item': true,
            'pickup-selector-popup-mobile__selector-item_selected': this.typeTap === TypeTab.Map
          },
          on: {
            click: () => { this.typeTap = TypeTab.Map }
          }
        }}>
          Карта
        </div>
        <div {...{
          class: {
            'pickup-selector-popup-mobile__selector-item': true,
            'pickup-selector-popup-mobile__selector-item_selected': this.typeTap === TypeTab.List
          },
          on: {
            click: () => { this.typeTap = TypeTab.List }
          }
        }}>
          Список
        </div>
      </div>
    )
  }

  renderTab () {
    if (this.typeTap === TypeTab.Map) {
      return this.renderMap()
    } else {
      return this.renderList()
    }
  }

  render () {
    return (
      <div class="pickup-selector-popup-mobile">
        <div class="pickup-selector-popup-mobile__header-wrapper">
          Выберите аптеку
        </div>
        <div class="pickup-selector-popup-mobile__selector-wrapper">
          {this.renderTabSelector()}
        </div>
        <div class="pickup-selector-popup-mobile__tab-wrapper">
          {this.renderTab()}
        </div>
        <div class="pickup-selector-popup-mobile__button-wrapper" onClick={() => this.selectPickpount()}>
          Далее
        </div>
      </div>
    )
  }
}
