import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class Btn extends Vue {
  @Prop({ type: String, required: true })
  readonly label?: string;

  @Prop({
    type: String,
    default: 'submit',
    validator: v => ['submit', 'button'].includes(v)
  })
  readonly type?: string;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly loading?: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly wide!: boolean;

  render () {
    return (
      <button
        type={this.type}
        class={{ 'button-text': true, 'button-text__wide': this.wide }}
      >
        {this.label}
      </button>
    )
  }
}
