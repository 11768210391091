import { Vue, Component } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import TextInput from '~/components/element/TextInput'
import s from '~/utils/s'

@Component({
  components: {
    ValidationObserver
  }
})
export default class ResetPasswordPopup extends Vue {
  password: string = ''
  passwordConfirm: string = ''

  async send () {
    await s(this.$store).personalPage.changePassword({
      password: this.password,
      passwordConfirm: this.passwordConfirm
    })
  }

  renderPassword () {
    return (
      <div class="popup-reset-password__password-step-input">
        <TextInput {...{
          props: {
            name: 'password',
            label: 'Пароль',
            value: this.password,
            rules: 'required|min:6',
            type: 'password'
          },
          on: {
            input: (v: string) => this.password = v
          }
        }} />
      </div>
    )
  }

  renderPasswordConfim () {
    return (
      <div class="popup-reset-password__password-step-input">
        <TextInput {...{
          props: {
            name: 'passwordConfirm',
            label: 'Повторите пароль',
            value: this.passwordConfirm,
            rules: 'required|confirmed:password',
            type: 'password'
          },
          on: {
            input: (v: string) => this.passwordConfirm = v
          }
        }} />
      </div>
    )
  }

  render () {
    return (
      <div class="popup-reset-password">
        <div class="popup-reset-password__header">
          Изменение пароля
        </div>
        <validation-observer>
          {({ handleSubmit }: any) => (
            <div class="popup-reset-password__form">
              <div class="popup-reset-password__form-input">
                {this.renderPassword()}
              </div>
              <div class="popup-reset-password__form-input">
                {this.renderPasswordConfim()}
              </div>
              <div class="popup-reset-password__form-input">
                <div class="button-text" onClick={() => handleSubmit(this.send)}>
                  Сохранить
                </div>
              </div>
            </div>
          )}
        </validation-observer>
      </div>
    )
  }
}
