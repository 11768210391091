import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'

@Component
export default class SmartBannerPopup extends Vue {
  isTablet = false

  mounted() {
    this.$cookies.set('smartbanner_exited', '1', {
      domain: `.${s(this.$store).general.host}`,
      path: '/'
    })
    this.checkDeviceType()
    window.addEventListener('resize', this.checkDeviceType)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceType)
  }

  checkDeviceType() {
    this.isTablet = (window.innerWidth >= 710 && window.innerWidth <= 1280) || /iPad/.test(navigator.userAgent)
  }

  render() {
    return (
      <div class="popup-smart-banner">
        <div class="popup-smart-banner__wrapper">
          {!this.isTablet && this.rendrInfo()}
          <div class="popup-smart-banner__background"></div>
          <div class="popup-smart-banner__view">
            {this.isTablet && this.rendrInfo()}
            <div>
              <button class="button-text button-text__wide popup-smart-banner__link" onClick={() => window.open('https://24farmacia.ru/api/mobile-app/link', '_blank')}>
                Скачать
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  rendrInfo() {
    return (
      <div class="popup-smart-banner__info">
        <div class="popup-smart-banner__view">
          <div class="popup-smart-banner__title">
            Обновленное мобильное приложение 24farmacia!
          </div>
          <div class="popup-smart-banner__author">
            Аптека в твоем телефоне
          </div>
        </div>
      </div>
    )
  }
}
