import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import DiseaseList from '~/types/api/disease/DiseaseList'
import Result from '~/types/api/Result'
import DiseaseSection from '~/types/api/disease/DiseaseSection'
import config from '~/utils/config'
import DiseaseListOptions from '~/types/api/options/DiseaseListOptions'
import s from '~/utils/s'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import diseaseCanonicalUrlHelper from '~/utils/diseaseCanonicalUrlHelper'

type Dictionary<T> = { [key: string]: T }

@Module({
  name: 'diseasesPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule implements ISeoDynamic {
  _list: DiseaseList = {
    count: 0,
    filterFirstLetters: [],
    items: []
  }

  _sections: DiseaseSection[] = []
  _isLoadSections: boolean = false

  _limit: number = 13
  _page: number = 0
  _seoCode: string = ''
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  @Mutation
  setList (list: DiseaseList) {
    this._list = list
  }

  @Mutation
  setSections (sections: DiseaseSection[]) {
    this._sections = sections
  }

  @Mutation
  setSectionsLoaded () {
    this._isLoadSections = true
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Mutation
  setSeoCode (seoCode:string) {
    this._seoCode = seoCode
  }

  @Action({ rawError: config.rawError })
  async loadData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }
    await Promise.allSettled([
      this.loadSections(),
      this.loadItems(route),
      s(this.store).seoTextBlock.loadSeoText('diseases')
    ])
  }

  @Action({ rawError: config.rawError })
  async loadCanonical (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const defaultCategory = s(this.store).regions.region?.items.find(e => e.default)
    let categoryCode = 'apteki'
    if (defaultCategory) {
      categoryCode = defaultCategory.code
    }
    await s(this.store).seo.setCanonical(diseaseCanonicalUrlHelper(route.path, categoryCode))
  }

  @Action({ rawError: config.rawError })
  async loadSections () {
    if (!this._isLoadSections) {
      const result = await this.store.$api.getDiseaseSections()
      if (result.code === 200 && result.data) {
        this.setSections(result.data)
        this.setSectionsLoaded()
      }
    }
  }

  @Action({ rawError: config.rawError })
  async loadItems (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    // если переход из другой страницы, непоказывает лоадер
    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    const query = route.query
    const params = route.params
    let sectionCode: string = ''
    let letter: string = ''
    const page = await this.getPageNumber(query)
    if (typeof params.section === 'string') {
      sectionCode = params.section
      this.setSeoCode(sectionCode)
    } else {
      this.setSeoCode('')
    }
    if (typeof query.letter === 'string') {
      letter = query.letter
    }
    const limit = this._limit
    const offset = page * limit

    const options: DiseaseListOptions = {
      limit,
      offset,
      letter,
      regionId: s(this.store).regions.section?.id.toString() ?? '0',
      sectionId: s(this.store).regions.sectionId
    }

    if (sectionCode) {
      options.code = sectionCode
    }

    let request = this.store.$api.getDiseasesAll(options)
    if (options.code) {
      request = this.store.$api.getDiseases(options)
    }

    const result = await request
    this.resultParse(result)

    this.setLimit(limit)
    this.setPage(page)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  getPageNumber (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  @Action({ rawError: config.rawError })
  resultParse (result: Result<DiseaseList>) {
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({
          top: offsetScroll,
          left: 0
        }), 0)
        this.disableLoadMore()
      } else {
        this.setList(result.data)
      }
    } else {
      this.setList({
        count: 0,
        filterFirstLetters: [],
        items: []
      })
    }
  }

  get list (): DiseaseList {
    return this._list
  }

  get sections (): DiseaseSection[] {
    return this._sections
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }

  get seoCode (): string {
    return this._seoCode
  }
}
