import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import config from '~/utils/config'
import s from '~/utils/s'

@Module({
  name: 'helpPages',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _textHelpPage: string = ''
  _footerInfo: string = ''
  _email: string = ''

  @Mutation
  setTextPage (text: string) {
    this._textHelpPage = text
  }

  @Mutation
  setFooterInfo (text: string) {
    this._footerInfo = text
  }

  @Mutation
  setEmail (text: string) {
    this._email = text
  }

  @Action({ rawError: config.rawError })
  async loadTextPage (codePageName: string) {
    const result = await this.store.$api.getHelpPageText({
      cityCode: s(this.store).regions.region?.code ?? 'krasnoyarsk',
      codePage: codePageName
    })

    if (result.code === 200 && result.data) {
      this.setTextPage(result.data.text)
    } else {
      this.setTextPage('')
    }
  }

  @Action({ rawError: config.rawError })
  async loadFooterInfo () {
    const result = await this.store.$api.getSiteSetting()

    if (result?.code === 200 && result?.data) {
      this.setFooterInfo(result.data.footerText)
      this.setEmail(result.data.email)
    } else {
      this.setFooterInfo('')
      this.setEmail('')
    }
  }

  get textPage (): string {
    return this._textHelpPage
  }

  get footerInfo (): string {
    return this._footerInfo
  }

  get email (): string {
    return this._email
  }
}
