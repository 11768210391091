import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UpdateProfile from '~/types/api/options/UpdateProfile'
import NotificationType from '~/types/enum/NotificationType'
import ChangePasswordOptions from '~/types/api/options/ChangePasswordOptions'
import OrderHistory from '~/types/api/orderHistory/OrderHistory'
import config from '~/utils/config'
import s from '~/utils/s'
import UserOrdersOptions from '~/types/api/options/UserOrdersOptions'
import ListResponse from '~/types/api/ListResponse'
import Topic from '~/types/api/Topic'
import { UserDefaultAddress } from '~/types/api/UserDefaultAddress'

@Module({
  name: 'personalPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _email: string = ''
  _fullName: string = ''
  _phone: string = ''
  _address: UserDefaultAddress = {
    value: '',
    deliverySquareId: ''
  }

  _pushAllowed = false

  _addressChanged = false
  _offsetOrders: number = 0
  _allowShowMoreOrders: boolean = true
  _orderList: ListResponse<OrderHistory> = {
    items: [],
    count: 0
  }

  _topics: Topic[] = []

  @Mutation
  setTopics (topics: Topic[]) {
    this._topics = topics
  }

  @Mutation
  setEmail (val: string) {
    this._email = val
  }

  @Mutation
  setFullName (val: string) {
    this._fullName = val
  }

  @Mutation
  setPhone (val: string) {
    this._phone = val.replace(/^(\+7)|[^\d+]/g, '')
  }

  @Mutation
  setAddress (val: UserDefaultAddress) {
    this._address = val
  }

  @Mutation
  setOrderList (list: OrderHistory[]) {
    this._orderList.items = list
  }

  @Mutation
  setOffsetOrders (val: number) {
    this._offsetOrders = val
  }

  @Mutation
  setCountOrders (val: number) {
    this._orderList.count = val
  }

  @Mutation
  setAllowShowMoreOrders (val: boolean) {
    this._allowShowMoreOrders = val
  }

  @Mutation
  setAddressChanged (value: boolean) {
    this._addressChanged = value
  }

  @Mutation
  setPushAllowed (value: boolean) {
    this._pushAllowed = value
  }

  @Action({ rawError: config.rawError })
  async loadData () {
    this.initProfile()
    // await this.initTopics()
    await this.initOrderList()
  }

  @Action({ rawError: config.rawError })
  async initProfile () {
    const user = s(this.store).auth.user
    if (user) {
      this.setEmail(user.email)
      this.setFullName(user.fullName)
      this.setPhone(user.phoneNumber ?? '')
      this.setPushAllowed(user.allowPush)

      await this.getUserAddress()
    }
  }

  @Action({ rawError: config.rawError })
  async getUserAddress () {
    const regionId = s(this.store).regions.regionId
    const result = await this.store.$api.getUserDefaultAddress(regionId)

    if (result.data) {
      this.setAddress(result.data)
    }
  }

  @Action({ rawError: config.rawError })
  async initTopics () {
    const result = await this.store.$api.getTopics()

    if (result.code === 200 && result.data) {
      this.setTopics(result.data)
    }
  }

  @Action({ rawError: config.rawError })
  async initOrderList () {
    const options: UserOrdersOptions = {
      regionId: s(this.store).regions.regionId ?? 0,
      offset: 0,
      limit: 5
    }
    const result = await this.store.$api.getOrders(options)

    if (result.code === 200 && result.data) {
      this.setCountOrders(result.data.count)
      this.setOrderList(result.data.items)
      this.setAllowShowMoreOrders(this.countOrders > this.orderList.length)
      this.setOffsetOrders(this.orderList.length)
    } else {
      this.setOrderList([])
    }
  }

  @Action({ rawError: config.rawError })
  async loadMoreOrderList () {
    const options: UserOrdersOptions = {
      regionId: s(this.store).regions.regionId ?? '0',
      offset: this.offsetOrders,
      limit: 5
    }
    const result = await this.store.$api.getOrders(options)

    if (result.code === 200 && result.data) {
      const orders = [...this.orderList, ...result.data.items]
      this.setOrderList(orders)
      this.setAllowShowMoreOrders(this.countOrders > this.orderList.length)
      this.setOffsetOrders(this.orderList.length)
    } else {
      this.setOrderList([])
    }
  }

  @Action({ rawError: config.rawError })
  async updateUser () {
    s(this.store).general.enableLoader()
    const options: UpdateProfile = {
      email: this.email,
      fullName: this.fullName
    }

    if (this.phone !== s(this.store).auth.user?.phoneNumber) {
      s(this.store).general.openPopup('PhoneConfirmationPopup')

    // TO DO удалить если смена номера работает корректно 
    /*   const res = await this.store.$api.changePhoneNumber({ phoneNumber: this.phone })

      if (res.code === 200) {
        s(this.store).notification.addNotification({
          type: NotificationType.Success,
          text: 'Вам поступит звонок, введите последние 4 цифры позвонившего номера!'
        })
        s(this.store).general.openPopup('PhoneConfirmationPopup')
      } else if (res.error) {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: res.error.description
        })
      } else {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: 'Неизвестная ошибка'
        })
      } */
    }

    const user = s(this.store).auth.user
    if (JSON.stringify(options) !== JSON.stringify({
      email: user?.email,
      fullName: user?.fullName
    })) {
      const result = await this.store.$api.updateProfile(options)
      if (result.code === 200 && result.data) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ...otherFields } = result.data
        s(this.store).auth.setUser(otherFields)
        const user = s(this.store).auth.user
        if (user) {
          this.setEmail(user.email)
          this.setFullName(user.fullName)
          this.setPhone(user.phoneNumber ?? '')
        }
        s(this.store).notification.addNotification({
          type: NotificationType.Success,
          text: 'Профиль успешно обновлен'
        })
      } else if (result.error) {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: result.error.description
        })
      } else {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: 'Неизвестная ошибка'
        })
      }
    }

    const regionId = s(this.store).regions.regionId
    const response = await this.store.$api.getUserDefaultAddress(regionId)
    if (response.data?.value !== this.address.value) {
      const result = await this.store.$api.setUserDefaultAddress(regionId, this.address)

      if (result.data) {
        s(this.store).notification.addNotification({
          type: NotificationType.Success,
          text: 'Адрес успешно обновлен'
        })
      }
    }

    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async changePassword (options: ChangePasswordOptions) {
    s(this.store).general.enableLoader()
    const result = await this.store.$api.changePassword(options)

    if (result.code === 200 && result.data?.status === true) {
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: 'Пароль изменен'
      })
      s(this.store).general.closePopup()
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: result.error.description
      })
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Не удалось изменить пароль'
      })
    }

    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async subscribeToTopic (topicId: number) {
    const topics = JSON.parse(JSON.stringify(this.topics))
    const index = topics.findIndex((topic: Topic) => topic.id === topicId)

    await this.store.$api.subscribeToTopic(topicId)

    if (index !== -1) {
      topics[index].subscribed = !topics[index].subscribed

      this.setTopics(topics)
    }
  }

  @Action
  async updateUserPushPermission (value: boolean) {
    const fcmToken = await this.store.$fire.messaging.getToken()
    await this.store.$api.setUserPushPermission(value, fcmToken)
    await s(this.store).auth.fillUser()
    await this.initProfile()
  }

  get email (): string {
    return this._email
  }

  get fullName (): string {
    return this._fullName
  }

  get phone (): string {
    return this._phone
  }

  get address (): UserDefaultAddress {
    return this._address
  }

  get orderList (): OrderHistory[] {
    return this._orderList.items
  }

  get offsetOrders (): number {
    return this._offsetOrders
  }

  get countOrders (): number {
    return this._orderList.count
  }

  get allowShowMoreOrders (): boolean {
    return this._allowShowMoreOrders
  }

  get topics (): Topic[] {
    return this._topics
  }

  get pushAllowed (): boolean {
    return this._pushAllowed
  }
}
