import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import ListResponse from '~/types/api/ListResponse'
import Stock from '~/types/api/stock/Stock'
import ListOptions from '~/types/api/options/ListOptions'
import config from '~/utils/config'
import s from '~/utils/s'
import { BANNER_GROUP_CODE } from '~/types/enum/BannerGroups'

@Module({
  name: 'stockPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _list: ListResponse<Stock> = {
    items: [],
    count: 0
  }

  _listForSlides: ListResponse<Stock> = {
    items: [],
    count: 0
  }

  _limit: number = 6
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  @Mutation
  setList (list: ListResponse<Stock>) {
    this._list = list
  }

  @Mutation
  setListForSlides (list: ListResponse<Stock>) {
    this._listForSlides = list
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Action({ rawError: config.rawError })
  async loadStocks (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    // если переход из другой страницы, непоказывает лоадер
    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    const query = route.query
    let page = 0
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
    }
    const limit = this._limit
    const offset = page * limit

    const options: ListOptions = {
      limit,
      offset,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0
    }

    const result = await this.store.$api.getStocks(options)
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({ top: offsetScroll, left: 0 }), 0)
        this.disableLoadMore()
      } else {
        this.setList(result.data)
      }
    } else {
      this.setList({
        items: [],
        count: 0
      })
    }
    this.setLimit(limit)
    this.setPage(page)
    await s(this.store).partnerCardBlock.loadPartnerCardBlock(BANNER_GROUP_CODE.promotionBanner)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async loadStocksForSlides () {
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId ?? 0

    const result = await this.store.$api.getStocksForSlides(regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setListForSlides(result.data)
    } else {
      this.setListForSlides({
        items: [],
        count: 0
      })
    }
  }

  get list (): ListResponse<Stock> {
    return this._list
  }

  get listForSlides (): ListResponse<Stock> {
    return this._listForSlides
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }
}
