import $script from 'scriptjs'

export default function ({ isDev, env }) {
  if (process.client && !isDev) {
    const insertChat = env.NODE_ENV_INSERT_LIVETEX_CHAT

    if (insertChat === 'true') {
      window.liveTex = true
      window.liveTexID = 168916
      window.liveTex_object = true

      window.addEventListener('load', function () {
        setTimeout(() => ($script('https://cs15.livetex.ru/js/client.js')), 2000)
      })
    }
  }
}
