import { Vue, Component, Prop } from 'vue-property-decorator'
import NotificationType from '~/types/enum/NotificationType'
import s from '~/utils/s'
import { CartItem } from '~/types/api/Cart'

@Component
export default class BasketNumber extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string

  @Prop({ type: Boolean, default: false })
  readonly isLarge!: boolean

  @Prop({ type: Boolean, default: false })
  readonly inHover!: boolean

  get cartRow (): CartItem | undefined {
    return s(this.$store).basket.items.find(e => e.product.id === this.id)
  }

  get available (): number {
    return this.cartRow?.amount ?? 1
  }

  set available (val: number) {
    if (this.cartRow) {
      if (val > 0 && val <= this.cartRow.product.available) {
        if (!this.inHover) {
          s(this.$store)
            .basket.change({
              productId: this.cartRow.product.id,
              regionId: s(this.$store).regions.regionId ?? 0,
              quantity: val,
              sectionId: s(this.$store).regions.sectionId
            })
            .then()
        } else {
          s(this.$store)
            .basket.changeFromHover({
              productId: this.cartRow.product.id,
              regionId: s(this.$store).regions.regionId ?? 0,
              quantity: val,
              sectionId: s(this.$store).regions.sectionId
            })
            .then()
        }
      } else if (val > this.cartRow?.product.available) {
        s(this.$store).notification.addNotification({
          text: 'Выбранное кол-во товаров превышает наличие',
          type: NotificationType.Warning
        })
      }
    }
  }

  async minus () {
    if (this.cartRow && this.cartRow.amount > 0) {
      if (this.inHover) {
        await s(this.$store).basket.removeFromHover(this.cartRow.product.id)
      } else {
        await s(this.$store).basket.remove(this.cartRow.product.id)
      }
    }
  }

  async plus () {
    if (
      this.cartRow &&
      this.cartRow.amount + 1 <= this.cartRow.product.available
    ) {
      if (this.inHover) {
        await s(this.$store).basket.addFromHover(this.cartRow.product.id)
      } else {
        await s(this.$store).basket.add(this.cartRow.product.id)
      }
    } else {
      s(this.$store).notification.addNotification({
        text: 'Выбранное кол-во товаров превышает наличие',
        type: NotificationType.Warning
      })
    }
  }

  render () {
    const showPlus =
      this.cartRow?.product &&
      this.cartRow.amount < this.cartRow.product.available
    return (
      <div
        {...{
          class: {
            'basket-number': true,
            'basket-number_large': this.isLarge
          }
        }}
      >
        <div class="basket-number__minus" onClick={this.minus}>
          -
        </div>
        <div class="basket-number__number">
          <input
            type="text"
            vModel_number_lazy={this.available}
            {...{
              attrs: {
                type: 'text',
                name: `${this.cartRow?.product.code}_available`
              }
            }}
          />
        </div>
        {showPlus && (
          <div class="basket-number__plus" onClick={this.plus}>
            +
          </div>
        )}
      </div>
    )
  }
}
