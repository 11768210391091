import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'
import { MetrikaLocalCodes } from '~/types/api/MetrikaCode'

@Component
export default class FooterCallback extends Vue {
  async openPopup (event: MouseEvent) {
    event.stopPropagation()
    s(this.$store).general.showPopup('CallbackPopup')
    this.$reachGoal(await s(this.$store).seo.getMetrikaCode(MetrikaLocalCodes.CONTACT_US_STEP_1))
  }

  render () {
    return (
      <div class="common-footer-callback" onClick={this.openPopup}>
        Связаться с нами
      </div>
    )
  }
}
