import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import CartOptions from '~/types/api/options/CartOptions'
import NotificationType from '~/types/enum/NotificationType'
import PresetBasketOptions from '~/types/api/options/PresetBasketOptions'
import config from '~/utils/config'
import s from '~/utils/s'
import Product from '~/types/api/Product'
import Cart, { CartItem } from '~/types/api/Cart'
import Result from '~/types/api/Result'
import OrderCopyOptions from '~/types/api/options/OrderCopyOptions'
import { E_COMMERCE_OPERATIONS } from '~/types/enum/ECommerce'
import { NuxtApp } from '@nuxt/types/app'

@Module({
  name: 'basket',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // #region store
  _items: CartItem[] = []
  _price: number = 0
  _basePrice: number = 0
  _discountPrice: number = 0
  _selectedIds: string[] = []
  _recommendations: Product[] = []
  _count: number = 0
  _containsPrescription: boolean = false
  _favoriteProducts: Product[] = []
  // #endregion
  _popularProducts: Product[] = []
  // #region mutation
  @Mutation
  setItems (items: CartItem[]) {
    this._items = items
  }

  @Mutation
  setPrice (price: number) {
    this._price = price
  }

  @Mutation
  setBasePrice (price: number) {
    this._basePrice = price
  }

  @Mutation
  setDiscountPrice (price: number) {
    this._discountPrice = price
  }

  @Mutation
  addSelectedId (id: string) {
    if (!this._selectedIds.includes(id)) {
      this._selectedIds.push(id)
    }
  }

  @Mutation
  removeSelectedId (id: string) {
    this._selectedIds = this._selectedIds.filter(e => e !== id)
  }

  @Mutation
  selectAll () {
    this._selectedIds = this._items.map(e => e.id)
  }

  @Mutation
  unselectAll () {
    this._selectedIds = []
  }

  @Mutation
  setRecommendations (items: Product[]) {
    this._recommendations = items
  }

  @Mutation
  setCount (count: number) {
    this._count = count
  }

  @Mutation
  setContainsPrescription (flag: boolean) {
    this._containsPrescription = flag
  }

  @Mutation
  setFavoriteProducts (products: Product[]) {
    this._favoriteProducts = products
  }

  @Mutation
  setPopularProducts (products: Product[]) {
    this._popularProducts = products
  }
  // #endregion

  // #region actions
  @Action({ rawError: config.rawError })
  async fillData (inHover?: boolean) {
    if (!inHover) { s(this.store).general.enableLoader() }
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getCart(regionId, sectionId)
    await this.fillDataOfResult(result)
    await this.loadRecommendations()

    if (!inHover) { s(this.store).general.disableLoader() }
  }

  @Action({ rawError: config.rawError })
  async fillSlidersData () {
    await s(this.store).basket.getSliderFavoriteProducts()
    await s(this.store).basket.getSliderPopularProducts()
  }

  @Action({ rawError: config.rawError })
  checkPrescription () {
    const result = this.items.map((item) => {
      if (item.product.byPrescription) {
        return true
      }
    })
    if (result.includes(true)) { this.setContainsPrescription(true) } else {
      this.setContainsPrescription(false)
    }
  }

  @Action({ rawError: config.rawError })
  fillDataOfResult (result?: Result<Cart>) {
    if (result?.code === 200 && result?.data) {
      this.setItems(result.data.items)
      this.setPrice(result.data.price)
      this.setBasePrice(result.data.basePrice)
      this.setDiscountPrice(result.data.discountPrice)
      this.showNotification(result.data.message)
      this.setCount(result.data.count)
      this.checkPrescription()
      return true
    } else if (result?.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description ?? result.error
      })
    }

    this.setItems([])
    this.setPrice(0)
    this.setBasePrice(0)
    this.setDiscountPrice(0)
    this.setCount(0)
    return false
  }

  @Action({ rawError: config.rawError })
  async add (productId: string) {
    const regionIdWaitingList = s(this.store).waitingList.items.find((element) => {
      return element.product.id === productId
    })?.regionId

    const sectionIdWaitingList = s(this.store).waitingList.items.find((element) => {
      return element.product.id === productId
    })?.sectionId

    s(this.store).general.enableLoader()
    const result = await this.store.$api.addCart({
      productId,
      regionId: regionIdWaitingList ?? s(this.store).regions.regionId ?? 0,
      quantity: 1,
      sectionId: sectionIdWaitingList ?? s(this.store).regions.sectionId ?? 0
    })
    if (result.code === 404) {
      this.showNotification(result.error?.description ?? '')
      s(this.store).general.disableLoader()
      return false
    }
    s(this.store).eCommerce.addToBasketECommerce({
      productId,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      type: E_COMMERCE_OPERATIONS.addToBasket
    })
    await this.fillData(false)
    if (sectionIdWaitingList && sectionIdWaitingList !== s(this.store).regions.sectionId) {
      this.showNotification(`Товар добавлен в раздел "${s(this.store).regions.region?.items.find(s => s.sectionId === sectionIdWaitingList)?.name}"`)
    }
    if (regionIdWaitingList && regionIdWaitingList !== s(this.store).regions.regionId) {
      await s(this.store).regions.redirectBasketById(regionIdWaitingList)
    }
    this.loadRecommendations().then()
    s(this.store).general.disableLoader()
    return true
  }

  @Action({ rawError: config.rawError })
  async addFromHover (productId: string) {
    const regionIdWaitingList = s(this.store).waitingList.items.find(
      (element) => {
        return element.product.id === productId
      }
    )?.regionId

    const sectionIdWaitingList = s(this.store).waitingList.items.find(
      (element) => {
        return element.product.id === productId
      }
    )?.sectionId
    await this.store.$api.addCart({
      productId,
      regionId: regionIdWaitingList ?? s(this.store).regions.regionId ?? 0,
      quantity: 1,
      sectionId: sectionIdWaitingList ?? s(this.store).regions.sectionId ?? 0
    })
    s(this.store).eCommerce.addToBasketECommerce({
      productId,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      type: E_COMMERCE_OPERATIONS.addToBasket
    })
    await this.fillData(true)
    if (
      sectionIdWaitingList &&
      sectionIdWaitingList !== s(this.store).regions.sectionId
    ) {
      this.showNotification(
        `Товар добавлен в раздел "${s(this.store).regions.region?.items.find(
          s => s.sectionId === sectionIdWaitingList
        )?.name
        }"`
      )
    }
    if (
      regionIdWaitingList &&
      regionIdWaitingList !== s(this.store).regions.regionId
    ) {
      await s(this.store).regions.redirectBasketById(regionIdWaitingList)
    }
    this.loadRecommendations().then()
    return true
  }

  @Action({ rawError: config.rawError })
  async addPreset (options: PresetBasketOptions) {
    s(this.store).general.enableLoader()

    if (!options.quantity || options.quantity < 1) {
      options.quantity = 1
    }

    const params = {
      regionId: s(this.store).regions.regionId ?? 0,
      code: options.code,
      quantity: options.quantity
    }

    const result = await this.store.$api.addCartPreset(params)
    const success = await this.fillDataOfResult(result)

    this.loadRecommendations()

    s(this.store).general.disableLoader()

    return success
  }

  @Action({ rawError: config.rawError })
  async change (options: CartOptions) {
    s(this.store).general.enableLoader()
    const result = await this.store.$api.changeCart(options)
    const addValue = s(this.store).basket.items.filter(item => item.product.id === options.productId)[0].amount - (options.quantity! ?? 0)
    const type = addValue < 0 ? E_COMMERCE_OPERATIONS.addToBasket : E_COMMERCE_OPERATIONS.removeFromBasket

    s(this.store).eCommerce.addToBasketECommerce({
      productId: options.productId,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      quantity: Math.abs(addValue),
      type
    })
    await this.fillDataOfResult(result)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async remove (id: string) {
    s(this.store).general.enableLoader()

    const result = await this.store.$api.removeCart({
      regionId: s(this.store).regions.regionId ?? 0,
      productId: id,
      sectionId: s(this.store).regions.sectionId
    })
    s(this.store).eCommerce.addToBasketECommerce({
      productId: id,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      type: E_COMMERCE_OPERATIONS.removeFromBasket
    })
    await this.fillDataOfResult(result)
    this.loadRecommendations().then()
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async changeFromHover (options: CartOptions) {
    const result = await this.store.$api.changeCart(options)
    const addValue = s(this.store).basket.items.filter(item => item.product.id === options.productId)[0].amount - (options.quantity! ?? 0)
    const type = addValue < 0 ? E_COMMERCE_OPERATIONS.addToBasket : E_COMMERCE_OPERATIONS.removeFromBasket

    s(this.store).eCommerce.addToBasketECommerce({
      productId: options.productId,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      quantity: Math.abs(addValue),
      type
    })
    await this.fillDataOfResult(result)
  }

  @Action({ rawError: config.rawError })
  async removeFromHover (id: string) {
    const result = await this.store.$api.removeCart({
      regionId: s(this.store).regions.regionId ?? 0,
      productId: id,
      sectionId: s(this.store).regions.sectionId
    })
    s(this.store).eCommerce.addToBasketECommerce({
      productId: id,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId ?? 0,
      type: E_COMMERCE_OPERATIONS.removeFromBasket
    })
    await this.fillDataOfResult(result)
    this.loadRecommendations().then()
  }

  @Action({ rawError: config.rawError })
  async removeRows (ids: string[]) {
    s(this.store).general.enableLoader()
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.removeCartRows(ids, regionId, sectionId)

    await this.fillDataOfResult(result)
    this.loadRecommendations().then()
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async loadRecommendations (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    if (route.name !== 'region-basket') {

    }

    /*
        const regionId = s(this.store).regions.regionId ?? 0
    TODO: добавить рекомендации в корзине
    const result = await this.store.$api.getCartRecommendation(regionId)
    if (result.code === 200 && result.data) {
      this.setRecommendations(result.data)
    } else {
      this.setRecommendations([])
    } */
  }

  @Action({ rawError: config.rawError })
  async copyOrder (options: OrderCopyOptions) {
    s(this.store).general.enableLoader()
    const result = await this.store.$api.copyOrder(options)
    const success = await this.fillDataOfResult(result)
    this.loadRecommendations().then()
    s(this.store).general.disableLoader()
    return success
  }

  @Action({ rawError: config.rawError })
  showNotification (message: string) {
    if (message) {
      s(this.store).notification.addNotification(
        {
          text: message,
          type: NotificationType.Warning
        }
      )
    }
  }

  @Action({ rawError: config.rawError })
  clearCart () {
    this.setItems([])
    this.setPrice(0)
    this.setBasePrice(0)
    this.setDiscountPrice(0)
    this.setCount(0)
  }
  // #endregion

  // #region getters

  @Action({ rawError: config.rawError })
  async getSliderFavoriteProducts () {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const result = await this.store.$api.getSliderFavorites(regionId, sectionId)

    if (result.code === 200 && result.data) {
      this.setFavoriteProducts(result.data.items)
    } else {
      this.setFavoriteProducts([])
    }

    return result.data?.items
  }

  @Action({ rawError: config.rawError })
  async getSliderPopularProducts () {
    const app = this.store.$router.app as NuxtApp
    const isDev = app.context.isDev
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const result = await this.store.$api.getProductPopular({
      limit: 20,
      offset: 0,
      regionId,
      sectionId,
      mainPage: true,
      devRequest: isDev
    })

    if (result.code === 200 && result.data) {
      this.setPopularProducts(result.data.items)
    } else {
      this.setPopularProducts([])
    }

    return result.data?.items
  }

  get items (): CartItem[] {
    return this._items
  }

  get price (): number {
    return this._price
  }

  get basePrice (): number {
    return this._basePrice
  }

  get discountPrice (): number {
    return this._discountPrice
  }

  get selectedIds (): string[] {
    return this._selectedIds
  }

  get recommendations (): Product[] {
    return this._recommendations
  }

  get count (): number {
    return this._count
  }

  get containsPrescription (): boolean {
    return this._containsPrescription
  }

  get favoriteProducts (): Product[] {
    return this._favoriteProducts
  }

  get popularProducts (): Product[] {
    return this._popularProducts
  }
  // #endregion
}
