import { Vue, Component } from 'vue-property-decorator'
import { CreateElement } from 'vue'

@Component
export default class FooterApps extends Vue {
  render (h: CreateElement) {
    return (
      <div class="common-footer-apps">
        <a href="https://play.google.com/store/apps/details?id=com.ionicframework.myionic2project925427&hl=ru&gl=US" class="common-footer-apps__app-link" target="_blank">
          {h('img', { attrs: { src: require('~/assets/image/google-play.jpg') } })}
        </a>
        <a href="https://apps.apple.com/ru/app/id1282232555" class="common-footer-apps__app-link" target="_blank">
          {h('img', { attrs: { src: require('~/assets/image/app-store.jpg') } })}
        </a>
      </div>
    )
  }
}
