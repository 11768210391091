import { Plugin } from '@nuxt/types'
import { getCookieClient, parseCookiesServer } from '~/utils/cookie'
import s from '~/utils/s'

const auth: Plugin = async ({ req, res, store }) => {
  /* if (req) {
    const cookie = parseCookiesServer(req)
    const token = cookie.token ?? ''
    if (token) {
      s(store).auth.setTokenAction(token)
    } else {
      await s(store).auth.fillToken()
    }
    let host = req.headers.host ?? ''
    host = host.replace(/:\d*!/g, '')
    const subdomains = host.split('.')
    const cookieHost = '.' + subdomains[subdomains.length - 2] + '.' + subdomains[subdomains.length - 1]
    res.setHeader('Set-Cookie', [`token=${s(store).auth.token}; domain=${cookieHost}; path=/; max-age=2592000;`])
  } else {
    const token = getCookieClient('token')
    if (token) {
      s(store).auth.setTokenAction(token)
    }
  } */

  const sfToken = s(store).auth._sfToken

  if (sfToken) {
    store.$api.setSfToken(sfToken)
  } else {
    await s(store).auth.fillAuthorizeToken()
  }

  const refreshToken = s(store).auth.refreshToken

  if (refreshToken) {
    store.$api.setRefreshToken(refreshToken)
  }
}

export default auth
