import { Vue, Component } from 'vue-property-decorator'
import { format } from 'fecha'
import OrderRatingStarSvg from '~/assets/svg/order-rating-star.svg?inline'
import OrderRatingSvg from '~/assets/svg/order-rating.svg?inline'
import Btn from '~/components/element/Btn'
import s from '~/utils/s'
import OrderRatingStep from '~/types/enum/OrderRatingStep'

@Component
export default class OrderRatingStepStars extends Vue {
  stars = [5, 4, 3, 2, 1]

  formatDate (date: Date) {
    const intl = new Intl.DateTimeFormat('ru-RU', {
      month: 'long'
    })

    let month = intl.format(new Date(date))
    const lastChar = month.charAt(month.length - 1)

    if (lastChar === 'ь' || lastChar === 'й') {
      month = month.substring(0, month.length - 1)
      month += 'я'
    } else {
      month += 'а'
    }

    const day = format(new Date(date), 'D')

    return `${day} ${month}`
  }

  renderStars () {
    return (
      <div class="popup-rating__stars">
        {this.stars.map((value: number) => (
          <div {...{
            class: {
              'popup-rating__star': true,
              'popup-rating__star-selected': s(this.$store).orderRating.starValue === value
            },
            on: {
              click: () => {
                s(this.$store).orderRating.setStarValue(value)
              }
            }
          }}>
            <input name="star" type="radio" id={'star' + value}/>
            <label for={'star' + value}>
              <div class="popup-rating__star">
                <OrderRatingStarSvg/>
              </div>
            </label>
          </div>
        ))}
      </div>
    )
  }

  render () {
    return (
      <div class="popup-rating">
        <div class="popup-rating__header">
          Благодарим за выбор аптеки 24farmacia!
        </div>
        <div class="popup-rating__description">
          Поделитесь впечатлениями от заказа №{s(this.$store).orderRating.orderForRating.number} от {this.formatDate(s(this.$store).orderRating.orderForRating.createdAt)}
        </div>
        <div class="popup-rating__voting">
          <div class="popup-rating__thanks-icon">
            <div class="popup-rating__thanks-icon-rating">
              <OrderRatingSvg/>
            </div>
          </div>
          {this.renderStars()}
          <Btn
            {...{
              props: { label: 'Оценить', wide: true, type: 'button' },
              nativeOn: {
                click: async () => {
                  if (await s(this.$store).orderRating.sendOrderForRating()) {
                    s(this.$store).orderRating.setOrderRatingStep(OrderRatingStep.Comment)
                  }
                }
              }
            }}
          ></Btn>
        </div>
      </div>
    )
  }
}
