import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Cookies from 'js-cookie'
import User from '~/types/api/User'
import AuthorizationOptions from '~/types/api/options/AuthorizationOptions'
import NotificationType from '~/types/enum/NotificationType'
import config from '~/utils/config'
import s from '~/utils/s'

interface TokenArguments {
  token: string;
  refreshToken: string
}

const initUser = {
  id: '',
  email: '',
  fullName: '',
  allowPush: false
}

@Module({
  name: 'auth',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _token: string | null = null
  _sfToken: string | null = null
  _user?: User = initUser

  _refreshToken: string | null = null;

  @Mutation
  setToken (token: string) {
    this._token = token
  }

  @Mutation
  setUser (user?: User) {
    if (user) {
      this._user = Object.assign({}, user)
    } else {
      this._user = initUser
    }
  }

  @Mutation
  setEsiaAuth (state: boolean) {
    if (this._user) {
      this._user.esiaAuth = state
    }
  }

  @Action({ rawError: config.rawError })
  setTokenAction (token: string) {
    this.store.$api.setToken(token)
    this.setToken(token)
  }

  @Mutation
  setSfToken (token: string) {
    this._sfToken = token
  }

  @Mutation
  setRefreshToken (token: string) {
    this._refreshToken = token
  }

  @Mutation
  setUserPhoneNumber (phoneNumber: string) {
    if (this._user) {
      this._user.phoneNumber = phoneNumber
    }
  }

  @Action({ rawError: config.rawError })
  setSfTokenAction ({ token, refreshToken }: TokenArguments) {
    this.store.$api.setSfToken(token)
    this.store.$api.setRefreshToken(refreshToken)
    this.setSfToken(token)
    this.setRefreshToken(refreshToken)
  }

  @Action({ rawError: config.rawError })
  setAuthorizeTokenAction (token: string) {
    this.store.$api.setSfToken(token)
    this.setSfToken(token)
  }

  @Action({ rawError: config.rawError })
  setRefreshTokenAction (token: string) {
    this.store.$api.setRefreshToken(token)
    this.setRefreshToken(token)
  }

  @Action({ rawError: config.rawError })
  async fillToken () {
    const result = await this.store.$api.getToken()
    if (result.code === 200 && result.data) {
      this.setTokenAction(result.data.token)
      if (this.store.$router.app) {
        const cokkie = `token=${result.data.token}; domain=${s(this.store).general.host}; path=/; max-age=2592000;`
        if (this.store.$router.app.$nuxt.context.req) {
          const res = this.store.$router.app.$nuxt.context.res
          res.setHeader('Set-Cookie', [cokkie])
        } else {
          document.cookie = cokkie
        }
      }
    }
  }

  @Action({ rawError: config.rawError })
  async fillAuthorizeToken () {
    const result = await this.store.$api.getAuthorizeToken()
    if (result.code === 200 && result.data) {
      this.setAuthorizeTokenAction(result.data.token)
    }
  }

  @Action({ rawError: config.rawError })
  async refreshTokenAction () {
    if (this.refreshToken.length) {
      const result = await this.store.$api.refreshToken()
      if (result.code === 200 && result.data) {
        this.setSfTokenAction({ token: result.data.jwt, refreshToken: result.data.rt })
      } else {
        this.clearUserData()
        await this.fillAuthorizeToken()
      }
    } else {
      this.clearUserData()
      await this.fillAuthorizeToken()
    }
  }

  @Action({ rawError: config.rawError })
  async auth (otpions: AuthorizationOptions) {
    s(this.store).general.enableLoader()
    const result = await this.store.$api.login(otpions)
    if (result.code === 200 && result.data) {
      this.setUser(result.data)
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: 'Вы успешно авторизовались'
      })
      await s(this.store).wishlist.loadProducts()
      await s(this.store).userProductList.loadUserProductList()
      const fcmToken = await this.store.$fire.messaging.getToken()
      Cookies.set('fcmToken', fcmToken)
      await this.store.$api.addFirebaseToken(fcmToken)
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Внутренняя ошибка'
      })
    }

    s(this.store).general.disableLoader()

    return result
  }

  @Action({ rawError: config.rawError })
  clearUserData () {
    this.setSfToken('')
    this.setRefreshToken('')
    Cookies.remove('fcmToken')
    this.setUser()
    s(this.store).basket.clearCart()
    s(this.store).wishlist.setList({ items: [], count: 0 })
    if (process.client) {
      localStorage.removeItem('orderForRatingLastPopup')
    }
  }

  @Action({ rawError: config.rawError })
  async logout () {
    s(this.store).general.enableLoader()
    await this.clearUserData()
    await this.fillAuthorizeToken()
    await this.store.$router.push({
      name: 'index'
    })
    s(this.store).notification.addNotification({
      type: NotificationType.Success,
      text: 'Вы успешно вышли из личного кабинета'
    })
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async fillUser () {
    if (this.sfToken?.length && this.refreshToken.length) {
      const result = await this.store.$api.profile()
      if (result.data) {
        this.setUser(result.data)
      } else {
        await this.refreshTokenAction()
        const newResult = await this.store.$api.profile()
        if (newResult.data) {
          this.setUser(newResult.data)
        }
      }
    }
  }

  get token (): string {
    return this._token ?? ''
  }

  get sfToken (): string {
    return this._sfToken ?? ''
  }

  get refreshToken (): string {
    return this._refreshToken ?? ''
  }

  get user (): User | undefined {
    return this._user
  }

  get isAuth (): boolean {
    return this.user?.id !== ''
  }

  get esiaAuth (): boolean | undefined {
    return this.user?.esiaAuth
  }
}
