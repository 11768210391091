import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EsiaAuthPopup extends Vue {
  async authorizeInEsia () {
    const response = await this.$api.fetchEsiaLink()

    if (response.data && response.code === 200) {
      window.open(response.data.url, '_self')
    }
  }

  render () {
    return (
      <div class="popup-esia-auth">
        <div class="popup-esia-auth__container">
          <h2 class="popup-esia-auth__header">Авторизуйтесь на портале госуслуг</h2>
          <p class="popup-esia-auth__description">Это безопасно и поможет выводить все ваши рецепты в личном кабинете</p>
        </div>
        <div onClick={async () => await this.authorizeInEsia()} class="popup-esia-auth__button button-text">
          Авторизоваться
        </div>
      </div>
    )
  }
}
