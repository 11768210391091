import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import OrderUser from '~/types/api/order/OrderUser'
import OrderInterval from '~/types/api/order/OrderInterval'
import OrderDelivery from '~/types/api/order/OrderDelivery'
import OrderPayment from '~/types/api/order/OrderPayment'
import OrderPickpoint from '~/types/api/order/OrderPickpoint'
import NotificationType from '~/types/enum/NotificationType'
import OrderStep from '~/types/enum/OrderStep'
import OrderDeliveryType from '~/types/enum/OrderDeliveryType'
import config from '~/utils/config'
import OrderOptions from '~/types/api/order/OrderOptions'
import Order, { OrderItem } from '~/types/api/order/Order'
import OrderSaveResult from '~/types/api/order/OrderSaveResult'
import StreetDadata from '~/types/api/order/StreetDadata'
import HouseDadata from '~/types/api/order/HouseDadata'
import s from '~/utils/s'
import AdditionalFunctionOrder from '~/types/api/order/AdditionalFunctionOrder'
import AddressDadata from '~/types/api/order/AddressDadata'
import { ORDER_SOURCE_TYPE } from '~/types/enum/OrderSourceType'

interface DeliveryDataId {
  deliveryId: number,
  pickupId: number
}

@Module({
  name: 'orderPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // #region state
  _orderDeliveryId: number = 0
  _orderPickupId: number = 0
  _orderStep: OrderStep = OrderStep.UserData
  _orderDeliveryType: OrderDeliveryType = OrderDeliveryType.Pickup
  _orderPrice: number = 0
  _deliveryPrice: number = 0
  _basketPrice: number = 0
  _discountPrice: number = 0
  _basketPriceDiscount: number = 0
  _address: string = ''
  _addFunctionsOrder: AdditionalFunctionOrder[] = []
  _intervals: OrderInterval[] = []
  _deliverys: OrderDelivery[] = []
  _payments: OrderPayment[] = []
  _pickpoints: OrderPickpoint[] = []
  _selectedPickpointId: string = ''
  _selectedPaymentId: string = ''
  _saveResult: OrderSaveResult | null = null
  _addressDataItems: AddressDadata | null = null
  _streetDataItem: StreetDadata | null = null
  _houseDataItem: HouseDadata | null = null
  _flat: string = ''
  _selectedDay: string = ''
  _selectedIntervalId: string = ''
  _comment: string = ''
  _isNextStepValidate: boolean = true
  _restrictionsErros: string[] = []
  _callBeforeDelivery: boolean = false
  _callBeforePlacingOrder: boolean = false
  _isPolicyAllowed: boolean = true
  _checkedAddFuncOrder: number[] = []
  _basketItems: OrderItem[] = []
  _expressIntervals: OrderInterval[] = []
  _isExpress: boolean = false
  _isAdditionalUser: boolean = false
  _additionalName: string = ''
  _additionalPhone: string = ''
  _isChangeAddressDataItems: boolean = false
  _user: OrderUser = {
    name: '',
    phone: '',
    email: ''
  }

  _hasExpress: boolean = false
  _isMobile: boolean = false
  _isDelivryAvailable: boolean = false
  _isPrescriptionError: boolean = false
  // #endregion

  // #region mutations
  @Mutation
  setDeliveryId (id: number) {
    this._orderDeliveryId = id
  }

  @Mutation
  setPickupId (id: number) {
    this._orderPickupId = id
  }

  @Mutation
  setOrderStep (step: OrderStep) {
    this._orderStep = step
  }

  @Mutation
  setDeliveryType (type: OrderDeliveryType) {
    this._orderDeliveryType = type
  }

  @Mutation
  setOrderPrice (price: number) {
    this._orderPrice = price
  }

  @Mutation
  setDeliveryPrice (price: number) {
    this._deliveryPrice = price
  }

  @Mutation
  setBasketPrice (price: number) {
    this._basketPrice = price
  }

  @Mutation
  setBasketPriceDiscount (price: number) {
    this._basketPriceDiscount = price
  }

  @Mutation
  setDiscountPrice (price: number) {
    this._discountPrice = price
  }

  @Mutation
  setAddress (address: string) {
    this._address = address
  }

  @Mutation
  setUser (user: OrderUser) {
    this._user = user
  }

  @Mutation
  setPickpointId (id: string) {
    this._selectedPickpointId = id
  }

  @Mutation
  setPaymentId (id: string) {
    this._selectedPaymentId = id
  }

  @Mutation
  setPickpoints (items: OrderPickpoint[]) {
    this._pickpoints = items
  }

  @Mutation
  setAdditionalFunctionsOrder (items: AdditionalFunctionOrder[]) {
    this._addFunctionsOrder = items
  }

  @Mutation
  setCheckedAddFuncOrder (item: number[]) {
    this._checkedAddFuncOrder = item
  }

  @Mutation
  setPayments (items: OrderPayment[]) {
    this._payments = items
  }

  @Mutation
  setDeliverys (items: OrderDelivery[]) {
    this._deliverys = items
  }

  @Mutation
  setSaveResult (result: OrderSaveResult) {
    this._saveResult = result
  }

  @Mutation
  setAddressDadata (item: AddressDadata | null) {
    this._addressDataItems = item
  }

  @Mutation
  setStreetDadata (item: StreetDadata | null) {
    this._streetDataItem = item
  }

  @Mutation
  setHouseDadata (item: HouseDadata | null) {
    this._houseDataItem = item
  }

  @Mutation
  setFlat (item: string) {
    this._flat = item
  }

  @Mutation
  setIntervals (items: OrderInterval[]) {
    this._intervals = items
  }

  @Mutation
  setDay (day: string) {
    this._selectedDay = day
  }

  @Mutation
  setIntervalId (id: string) {
    this._selectedIntervalId = id
  }

  @Mutation
  setComment (text: string) {
    this._comment = text
  }

  @Mutation
  setIsNextStepValidate (val: boolean) {
    this._isNextStepValidate = val
  }

  @Mutation
  setRestrictionsErros (erros: string[]) {
    this._restrictionsErros = erros
  }

  @Mutation
  setCallBeforeDelivery (val: boolean) {
    this._callBeforeDelivery = val
  }

  @Mutation
  setCallBeforePlacingOrder (val: boolean) {
    this._callBeforePlacingOrder = val
  }

  @Mutation
  setIsPolicyAllowed (val: boolean) {
    this._isPolicyAllowed = val
  }

  @Mutation
  setBasketItems (basketItems: OrderItem[]) {
    this._basketItems = basketItems
  }

  @Mutation
  setExpressIntervals (expressIntervals: OrderInterval[]) {
    this._expressIntervals = expressIntervals
  }

  @Mutation
  setIsExpress (isExpress: boolean) {
    this._isExpress = isExpress
  }

  @Mutation
  setIsAdditionalUser (isAdditionalUser: boolean) {
    this._isAdditionalUser = isAdditionalUser
  }

  @Mutation
  setAdditionalName (additionalName: string) {
    this._additionalName = additionalName
  }

  @Mutation
  setAdditionalPhone (additionalPhone: string) {
    this._additionalPhone = additionalPhone.replace(/^(\+7)|[^\d+]/g, '')
  }

  @Mutation
  setIsMobile (isMobile: boolean) {
    this._isMobile = isMobile
  }

  @Mutation
  setHasExpress (value: boolean) {
    this._hasExpress = value
  }

  @Mutation
  setIsChangeAddressDataItems (isChanged: boolean) {
    this._isChangeAddressDataItems = isChanged
  }

  @Mutation
  setIsDeliveryAvailable (isDelivryAvailable: boolean) {
    this._isDelivryAvailable = isDelivryAvailable
  }

  @Mutation
  setIsPrescriptionError(isPrescriptionError: boolean) {
    this._isPrescriptionError = isPrescriptionError
  }
  // #endregion

  // #region actions
  @Action({ rawError: config.rawError })
  async fillAddressDadata (item: AddressDadata) {
    this.setAddressDadata(item)
    // if (item.deliverySquareId) {
    s(this.store).general.enableLoader()
    await this.updateOrder()
    /* } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: 'Не найден регион доставки, возможно указан неполный адрес'
      })
    } */
  }

  @Action({ rawError: config.rawError })
  async fillExpressData (value: boolean) {
    this.setIsExpress(value)
    if (value) {
      const item = this.expressIntervals[0]
      this.setDay(item.value)
      this.setIntervalId(item.periods[0].id)
    } else {
      const result = await this.store.$api.getAddressDadata({
        regionId: s(this.store).regions.regionId ?? 0,
        query: this.addressDataItem?.value ?? ''
      })
      if (result.code === 200 && result.data) {
        this.setAddressDadata(result.data[0])
      }
    }
    await this.updateOrder()
  }

  @Action({ rawError: config.rawError })
  fillStreetDadata (item: StreetDadata) {
    this.setStreetDadata(item)
    this.setHouseDadata(null)
    this.setFlat('')
  }

  @Action({ rawError: config.rawError })
  async fillHouseDadata (item: HouseDadata) {
    s(this.store).general.enableLoader()
    this.setHouseDadata(item)
    this.setFlat('')
    await this.updateOrder()
  }

  @Action({ rawError: config.rawError })
  async fillData () {
    s(this.store).general.enableLoader()
    this.clearOrder()
    const result = await this.store.$api.getOrder(s(this.store).regions.regionId ?? 0, s(this.store).regions.sectionId)
    if (result.code === 200 && result.data) {
      await s(this.store).personalPage.getUserAddress()
      this.setData(result.data)
      await this.loadOrderPickPoints()
    } else if (result.error) {
      /* s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      }) */
      this.clearOrder()
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Неизвестная ошибка'
      })
      this.clearOrder()
    }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  initPayment () {
    if (this.payments.find(e => e.id === this.paymentId) === undefined) {
      this.setPaymentId(this.payments[0].id)
    }
  }

  @Action({ rawError: config.rawError })
  async updateOrder () {
    let isSuccess: boolean = false
    this.initPayment()
    s(this.store).general.enableLoader()

    const options = await this.prepareOptions()

    if (options) {
      const result = await this.store.$api.updateOrder(options)
      if (result.code === 200 && result.data) {
        this.setData(result.data)
        isSuccess = true
      } else if (result.error) {
        s(this.store).notification.addNotification({
          type: NotificationType.Warning,
          text: result.error.description
        })
      } else {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: 'Неизвесная ошибка'
        })
        this.clearOrder()
      }
    }

    s(this.store).general.disableLoader()
    return isSuccess
  }

  @Action({ rawError: config.rawError })
  async loadOrderPickPoints () {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const basketNomenclatureIds = s(this.store).basket.items.map(n => n.product.id)
    const result = await this.store.$api.getPickPointsForOrder(regionId, sectionId, basketNomenclatureIds)

    if (result.data?.items) {
      this.setPickpoints(result.data?.items)
    } else {
      this.setPickpoints([])
    }
  }

  @Action({ rawError: config.rawError })
  async saveOrder () {
    let isSuccess: boolean = false
    this.initPayment()
    s(this.store).general.enableLoader()

    const options = await this.prepareOptions()

    if (options) {
      const result = await this.store.$api.saveOrder(options)
      if (result.code === 200 && result.data) {
        s(this.store).eCommerce.confirmOrderECommerce(
          s(this.store).basket.items.map((item) => {
            return {
              productId: item.product.code,
              name: item.product.title,
              price: item.product.price,
              quantity: item.amount,
              orderId: result.data?.number!
            }
          }))
        this.setSaveResult(result.data)
        this.setCallBeforeDelivery(false)
        this.setCallBeforePlacingOrder(false)
        isSuccess = true
      } else if (result.error) {
        s(this.store).notification.addNotification({
          type: NotificationType.Warning,
          text: result.error.description
        })
      } else {
        s(this.store).notification.addNotification({
          type: NotificationType.Error,
          text: 'Неизвесная ошибка'
        })
        this.clearOrder()
      }
    }

    s(this.store).general.disableLoader()
    return isSuccess
  }

  @Action({ rawError: config.rawError })
  prepareOptions (): OrderOptions | undefined {
    let options: OrderOptions | undefined
    if (this._orderDeliveryType === OrderDeliveryType.Pickup) {
      options = {
        deliveryId: this.orderDeliveryType,
        paymentId: this._selectedPaymentId,
        regionId: s(this.store).regions.regionId ?? 0,
        sectionId: s(this.store).regions.sectionId ?? '0',
        userEmail: this.user.email,
        userName: this.user.name,
        userPhone: this.user.phone,
        pickupId: this.pickpointId,
        callBeforeDelivery: this.callBeforeDelivery,
        callBeforePlacingOrder: this.callBeforePlacingOrder,
        comment: this.comment,
        checkedAddFuncOrder: this.checkedAddFuncOrder,
        source: this.isMobile ? ORDER_SOURCE_TYPE.mobileSite : ORDER_SOURCE_TYPE.site,
        pickupDate: this.pickpoints.find(e => e.id === this.pickpointId)?.dateToPickup
      }

      if (this.isAdditionalUser) {
        options.additionalName = this.additionalName
        options.additionalPhone = this.additionalPhone
      }
    } else if (this._orderDeliveryType === OrderDeliveryType.Delivery) {
      options = {
        deliveryId: this.orderDeliveryType,
        paymentId: this._selectedPaymentId,
        regionId: s(this.store).regions.regionId ?? 0,
        sectionId: s(this.store).regions.sectionId ?? '0',
        userEmail: this.user.email,
        userName: this.user.name,
        userPhone: this.user.phone,
        address: this.addressDataItem?.value,
        lat: this.addressDataItem?.lat,
        lon: this.addressDataItem?.lon,
        unrestrictedValue: this.fullAddress,
        deliverySquareId: this.addressDataItem?.deliverySquareId,
        callBeforeDelivery: this.callBeforeDelivery,
        callBeforePlacingOrder: this.callBeforePlacingOrder,
        comment: this.comment,
        checkedAddFuncOrder: this.checkedAddFuncOrder,
        isExpress: this.isExpress,
        source: this.isMobile ? ORDER_SOURCE_TYPE.mobileSite : ORDER_SOURCE_TYPE.site
      }

      if (this.day) {
        options.day = this.day
      }

      if (this.intervalId) {
        options.periodId = this.intervalId
      }

      if (this.isAdditionalUser) {
        options.additionalName = this.additionalName
        options.additionalPhone = this.additionalPhone
      }
    }

    return options
  }

  @Action({ rawError: config.rawError })
  setData (order: Order) {
    const userAddress = s(this.store).personalPage.address
    const isOrderAddressChanged = !!order.address.deliverySquareId
    this.setBasketPrice(order.basketPrice)
    this.setOrderPrice(order.orderPrice)
    this.setDeliveryPrice(order.deliveryPrice)
    this.setDiscountPrice(order.discountPrice)
    this.setBasketPriceDiscount(order.basketPriceDiscount)
    this.setAddressDadata(isOrderAddressChanged ? order.address : {
      value: userAddress.value ?? '',
      deliverySquareId: userAddress.deliverySquareId ?? '',
      unrestrictedValue: userAddress.value ?? ''
    })
    this.setUser(order.user)
    this.setPayments(order.payments)
    this.setDeliverys(order.delivery)
    this.setIntervals(order.intervals ?? [])
    this.setBasketItems(order.basketItems)
    this.setExpressIntervals(order.expressIntervals ?? [])
    this.setHasExpress(order.hasExpress ?? false)

    if (order.pickpoints) {
      this.setPickpoints(order.pickpoints)
    }

    if (!this.pickpointId && order.defaultUserPickupId) {
      this.setPickpointId(order.defaultUserPickupId)
    }

    if (this.delivery === undefined) {
      if (this.orderDeliveryType === OrderDeliveryType.Delivery) {
        this.setDeliveryType(OrderDeliveryType.Pickup)
      } else if (this.orderDeliveryType === OrderDeliveryType.Pickup) {
        this.setDeliveryType(OrderDeliveryType.Delivery)
      }
    }

    if (order.additionalFunctionsOrder) {
      this.setAdditionalFunctionsOrder(order.additionalFunctionsOrder)
    }

    this.checkRestrictions()
  }

  @Action({ rawError: config.rawError })
  clearOrder () {
    this.setOrderStep(OrderStep.UserData)
    this.setDeliveryType(OrderDeliveryType.Pickup)
    this.setOrderPrice(0)
    this.setBasketPrice(0)
    this.setDeliveryPrice(0)
    this.setDiscountPrice(0)
    this.setBasketPriceDiscount(0)
    this.setAddressDadata(null)
    this.setPickpoints([])
    this.setAdditionalFunctionsOrder([])
    this.setPickpointId('')
    this.setPayments([])
    this.setDeliverys([])
    this.setIntervals([])
    this.setStreetDadata(null)
    this.setHouseDadata(null)
    this.setFlat('')
    this.setComment('')
    this.setIsPolicyAllowed(true)
    this.setBasketItems([])
    this.setIsExpress(false)
    this.setExpressIntervals([])
    this.setIsAdditionalUser(false)
    this.setAdditionalName('')
    this.setAdditionalPhone('')

    this.checkRestrictions()
  }

  @Action({ rawError: config.rawError })
  setDeliveryIds (data: DeliveryDataId) {
    this.setDeliveryId(data.deliveryId)
    this.setPickupId(data.pickupId)
  }

  @Action({ rawError: config.rawError })
  checkRestrictions () {
    const errors: string[] = []
    const delivery = this.delivery
    if (delivery) {
      const isPrescriptionError = delivery.restrictions.isPrescription === true &&
        s(this.store).orderPage.basketItems.find(e => e.byPrescription)

      const cond = s(this.store).regions.currentRegionSectionDeliveryConditions

      const isRegionError = !cond.deliveryAvailable && delivery.code === 1

      if (isRegionError) {
        errors.push('в данном регионе нет доставки')
      }

      if (isPrescriptionError) {
        this.setIsPrescriptionError(true)
      }
    }
    this.setRestrictionsErros(errors)
  }

  @Action({ rawError: config.rawError })
  async checkDeliveryAvailable (): Promise<boolean> {
    if (this.addressDataItem) {
      const result = await this.store.$api.getDeliveryAvailable(s(this.store).regions.regionId, this.addressDataItem)
      this.setIsDeliveryAvailable(result.code === 200 && result.data?.status === true)
      return this.isDeliveryAvailable
    } else {
      return false
    }
  }
  // #endregion

  // #region getters
  get orderStep (): OrderStep {
    return this._orderStep
  }

  get orderDeliveryType (): OrderDeliveryType {
    return this._orderDeliveryType
  }

  get pickpoints (): OrderPickpoint[] {
    return this._pickpoints
  }

  get additionalFunctionsOrder (): AdditionalFunctionOrder[] {
    return this._addFunctionsOrder
  }

  get checkedAddFuncOrder (): number[] {
    return this._checkedAddFuncOrder
  }

  get user (): OrderUser {
    return this._user
  }

  get orderPrice (): number {
    return this._orderPrice
  }

  get deliveryPrice (): number {
    return this._deliveryPrice
  }

  get basketPrice (): number {
    return this._basketPrice
  }

  get discountPrice (): number {
    return this._discountPrice
  }

  get basketPriceDiscount (): number {
    return this._basketPriceDiscount
  }

  get pickpointId (): string {
    return this._selectedPickpointId
  }

  get paymentId (): string {
    return this._selectedPaymentId
  }

  get deliverys (): OrderDelivery[] {
    return this._deliverys
  }

  get delivery () {
    return this.deliverys.find(e => e.code === this.orderDeliveryType)
  }

  get payments (): OrderPayment[] {
    return this._payments.filter(e => this.delivery?.restrictions.byPaySystem.includes(e.id))
  }

  get saveResult (): OrderSaveResult | null {
    return this._saveResult
  }

  get addressDataItem (): AddressDadata | null {
    return this._addressDataItems
  }

  get streetDataItem (): StreetDadata | null {
    return this._streetDataItem
  }

  get houseDataItem (): HouseDadata | null {
    return this._houseDataItem
  }

  get flat (): string {
    return this._flat
  }

  get fullAddress (): string {
    return this.addressDataItem?.unrestrictedValue ?? ''
  }

  get intervals (): OrderInterval[] {
    return this._intervals
  }

  get day (): string {
    return this._selectedDay
  }

  get intervalId (): string {
    return this._selectedIntervalId
  }

  get comment (): string {
    return this._comment
  }

  get isNextStepValidate (): boolean {
    return this._isNextStepValidate
  }

  get restrictionsErros (): string[] {
    return this._restrictionsErros
  }

  get callBeforeDelivery (): boolean {
    return this._callBeforeDelivery
  }

  get callBeforePlacingOrder (): boolean {
    return this._callBeforePlacingOrder
  }

  get isPolicyAllowed (): boolean {
    return this._isPolicyAllowed
  }

  get basketItems (): OrderItem[] {
    return this._basketItems
  }

  get expressIntervals (): OrderInterval[] {
    return this._expressIntervals
  }

  get isExpress (): boolean {
    return this._isExpress
  }

  get isAdditionalUser (): boolean {
    return this._isAdditionalUser
  }

  get additionalPhone (): string {
    return this._additionalPhone
  }

  get additionalName (): string {
    return this._additionalName
  }

  get hasExpress () {
    return this._hasExpress
  }

  get isAddressDataItemsChanged () {
    return this._isChangeAddressDataItems
  }

  get isDeliveryAvailable () {
    return this._isDelivryAvailable
  }

  get isPrescriptionError() {
    return this._isPrescriptionError
  }
  // #endregion

  get isMobile () {
    return this._isMobile
  }
}
