import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const choiceCityMiddleware: Middleware = (context: Context) => {
  const chosenCity = s(context.store).regions.choiceCity
  let host = ''
  if (process.server) {
    host = context.req.headers.host ?? ''
  }
  const regexDomain = s(context.store).general.host?.replace('.', '\\.')
  const regexMain = new RegExp('^' + regexDomain + '(:\\d{1,5})?$', 'i')
  const regexSub = new RegExp('^([^.]*)\\.' + regexDomain + '(:\\d{1,5})?$', 'i')

  if (regexMain.test(host)) {
    if (chosenCity) {
      s(context.store).general.setIsShowChoiceCity(false)
    } else {
      s(context.store).general.setIsShowChoiceCity(true)
    }
  } else if (regexSub.test(host)) {
    s(context.store).general.setIsShowChoiceCity(false)
  }
}

export default choiceCityMiddleware
