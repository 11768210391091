/* eslint-disable no-dupe-class-members */
import { Store } from 'vuex'
import Result from '~/types/api/Result'

export default abstract class ErrorHandler {
  protected store: Store<any>

  constructor (store: Store<any>) {
    this.store = store
  }

  abstract check<T> (): Promise<void> | void
  abstract check<T> (): Promise<Result<T>> | void
  abstract check<T> (fn: () => Promise<Result<T>>): Promise<Result<T>> | void
  abstract check<T, K> (fn: (param: K) => Promise<Result<T>>, param: K): Promise<Result<T>> | void
}
