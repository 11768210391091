import ErrorHandler from '~/utils/api/errorHandler/ErrorHandler'

export default class ClosedSiteErrorHandler extends ErrorHandler {
  check (..._params: any[]) {
    this.store.dispatch('general/redirectClosed')
    return new Promise<any>(resolve => resolve({
      code: 503,
      error: {
        description: 'Site closed'
      }
    }))
  }
}
