// кек, eslint в полиморфизм не может
/* eslint-disable no-dupe-class-members */
import ResponseGroupItem from '~/types/api/ResponseGroupItem'

export default class {
  public static get<T1> (values: readonly[
    ResponseGroupItem<T1>
  ]): Promise<{[key: string]: T1 | null}>

  public static get<T1, T2> (values: readonly[
    ResponseGroupItem<T1>,
    ResponseGroupItem<T2>
  ]): Promise<{[key: string]: T1 | T2 | null}>

  public static get<T1, T2, T3> (values: readonly[
    ResponseGroupItem<T1>,
    ResponseGroupItem<T2>,
    ResponseGroupItem<T3>,
  ]): Promise<{[key: string]: T1 | T2 | T3 | null}>

  public static get<T1, T2, T3, T4> (values: readonly[
    ResponseGroupItem<T1>,
    ResponseGroupItem<T2>,
    ResponseGroupItem<T3>,
    ResponseGroupItem<T4>,
  ]): Promise<{[key: string]: T1 | T2 | T3 | T4 | null}>

  public static get<T1, T2, T3, T4, T5> (values: readonly[
    ResponseGroupItem<T1>,
    ResponseGroupItem<T2>,
    ResponseGroupItem<T3>,
    ResponseGroupItem<T4>,
    ResponseGroupItem<T5>,
  ]): Promise<{[key: string]: T1 | T2 | T3 | T4 | T5 | null}>

  public static async get (..._items: any[]): Promise<any> {
    const items = _items[0]
    const values = items.map((e: any) => e.key)
    const defaults = items.map((e: any) => e.default)
    const tasks = items.map((e: any) => e.task)
    const responses = await Promise.allSettled(tasks)
    const result: any = {}
    Object.values(responses).forEach((e: any, i: number) => {
      if (e.status === 'fulfilled' && e.value.code === 200) {
        result[values[i]] = e.value.data
      } else {
        result[values[i]] = defaults[i]
      }
    })
    return result
  }
}
