import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'

@Component({
  components: {
    ValidationProvider
  }
})
export default class TextInput extends Vue {
  innerValue: string = '';

  @Prop({ type: String, required: true })
  readonly label?: string;

  @Prop({ type: String, required: true })
  readonly name?: string;

  @Prop({ type: String, default: '' })
  readonly rules?: string;

  @Prop({ type: String, default: '' })
  readonly mask?: string;

  @Prop({ type: String, default: '' })
  readonly placeholder?: string;

  @Prop({ type: String, default: '' })
  readonly value?: string;

  @Prop({
    type: String,
    default: 'text',
    validator: v =>
      [
        'url',
        'text',
        'password',
        'tel',
        'search',
        'number',
        'email',
        'textarea'
      ].includes(v)
  })
  readonly type?: string;

  @Prop({ type: String })
  readonly customName?: string;

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  get hasValue (): boolean {
    return !!this.innerValue
  }

  get showMask (): boolean {
    return !!this.mask
  }

  renderMask () {
    if (this.showMask) {
      return {
        directives: [
          {
            name: 'mask',
            value: this.mask
          }
        ]
      }
    }
    return {}
  }

  @Watch('innerValue')
  innerValueChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value')
  valueChange (value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  renderScopLabelPayload () {
    if (this.$scopedSlots.labelPayload !== undefined) {
      return this.$scopedSlots.labelPayload(this)
    }
  }

  renderInput () {
    if (this.type === 'textarea') {
      return (
        <textarea
          placeholder={this.placeholder}
          name={this.name}
          v-model={this.innerValue}
          rows="20"
        ></textarea>
      )
    } else {
      return (
        <input
          placeholder={this.placeholder}
          type={this.type}
          name={this.name}
          v-model={this.innerValue}
          {...this.renderMask()}
        />
      )
    }
  }

  render () {
    return (
      <validation-provider
        {...{
          props: {
            rules: this.rules,
            name: this.customName ?? this.label,
            customMessages: ru.messages,
            vid: this.name
          }
        }}
      >
        {({ errors, required }: any) => (
          <div
            {...{
              class: {
                'common-input': true,
                'common-input_required': required,
                'common-input_error': errors.length > 0
              }
            }}
          >
            {this.renderInput()}
            <div class="common-input__name">
              <div class="common-input__name-text">{this.label}</div>
              <div class="common-input__name-payload">
                {this.renderScopLabelPayload()}
              </div>
            </div>
            <div class="common-input__error">{errors[0]}</div>
          </div>
        )}
      </validation-provider>
    )
  }
}
