import { Vue, Component } from 'vue-property-decorator'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'

@Component
export default class MobileSearchList extends Vue {
  renderProducts () {
    const items = s(this.$store).searchBar.searchResult.products?.items
    if (items && items.length > 0) {
      const sectionHelper = new SectionHelper(s(this.$store).general.sections, this.$store)

      return (
        <div class="mobile-search-list__item-container">
          {items.map((e) => {
            const sections = e.sectionId
            const link = sectionHelper.getProductLink(sections?.length ? sections[0] : s(this.$store).categoryPage.category.id, e.seoUrl ?? e.id)
            return (
              <nuxt-link {...{
                nativeOn: {
                  click: s(this.$store).searchBar.closeClear
                }
              }} key={e.id} to={link} class="mobile-search-list__item-link">
                {e.title}
              </nuxt-link>
            )
          })}
          <div class="mobile-search-list__item-container-line"></div>
        </div>
      )
    }
  }

  renderStocks () {
    const items = s(this.$store).searchBar.searchResult.stocks
    if (items && items.length > 0) {
      return (
        <div class="mobile-search-list__item-container">
          {items.map((e) => {
            const link = '/'
            return (
              <nuxt-link {...{
                nativeOn: {
                  click: s(this.$store).searchBar.closeClear
                }
              }} key={e.id} to={link} class="mobile-search-list__item-link mobile-search-list__item-link_stock">
                {e.title}
              </nuxt-link>
            )
          })}
          <div class="mobile-search-list__item-container-line"></div>
        </div>
      )
    }
  }

  renderArticles () {
    const items = s(this.$store).searchBar.searchResult.articles
    if (items && items.length > 0) {
      return (
        <div class="mobile-search-list__item-container">
          {items.map((e) => {
            const link = '/'
            return (
              <nuxt-link {...{
                nativeOn: {
                  click: s(this.$store).searchBar.closeClear
                }
              }} key={e.id} to={link} class="mobile-search-list__item-link">
                Статья: «{e.title}»
              </nuxt-link>
            )
          })}
          <div class="mobile-search-list__item-container-line"></div>
        </div>
      )
    }
  }

  render () {
    return (
      <div class="mobile-search-list">
        {this.renderProducts()}
        {this.renderStocks()}
        {this.renderArticles()}
      </div>
    )
  }
}
