import Vue from 'vue'

Vue.directive('zoom-image', {
  bind(el) {
    const styles = `
    .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.9);
    }

    .modal-content {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      -webkit-user-drag: none;
      will-change: transform;
    }

    .close {
      position: absolute;
      top: 15px;
      right: 35px;
      color: #296654;
      font-size: 40px;
      font-weight: bold;
      transition: 0.3s;
    }

    .close:hover,
    .close:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }

    .no-scroll {
      overflow: hidden;
    }
    `;

    const styleTag = document.createElement('style');
    styleTag.textContent = styles;
    document.head.appendChild(styleTag);

    el._styleTag = styleTag;
    el.querySelectorAll('img').forEach((img) => {
      img.style.cursor = 'pointer';
    });

    let dialog = false;
    let imgSrc = '';
    let zoom = 1;
    let dragging = false;
    let initialX = 0;
    let initialY = 0;
    let translateX = 0;
    let translateY = 0;
    let modal = null;

    const createModal = () => {
      const modal = document.createElement('div');
      modal.className = 'modal';
      modal.innerHTML = `
          <span class="close">×</span>
          <img class="modal-content zoom" src="${imgSrc}" />
      `;
      document.body.appendChild(modal);
      return modal;
    };

    const openDialog = (src) => {
      imgSrc = src;
      dialog = true;
      document.body.classList.add('no-scroll');
      modal = createModal();
      modal.querySelector('.close').addEventListener('click', closeDialog);

      modal.addEventListener('wheel', (event) => {
        zoom += event.deltaY > 0 ? -0.1 : 0.1;
        zoom = Math.max(1, Math.min(zoom, 2.5));
        updateTransform();
      });

      modal.addEventListener('mousedown', (event) => {
        dragging = true;
        initialX = event.clientX;
        initialY = event.clientY;
      });

      modal.addEventListener('mousemove', (event) => {
        if (!dragging) return;

        const imgElement = modal.querySelector('.modal-content');
        if (imgElement) {
          const style = imgElement.style;
          if (style.cursor !== 'move') return;

          translateX += event.clientX - initialX;
          translateY += event.clientY - initialY;

          updateTransform();

          initialX = event.clientX;
          initialY = event.clientY;
        }
      });

      modal.addEventListener('mouseup', () => {
        dragging = false;
      });

      modal.addEventListener('click', (event) => {
        if (event.target === modal) {
          closeDialog();
        }
      });
    };

    const closeDialog = () => {
      dialog = false;
      zoom = 1;
      resetDrag();
      document.body.classList.remove('no-scroll');
      if (modal) {
        document.body.removeChild(modal);
        modal = null;
      }
    };

    const resetDrag = () => {
      initialX = 0;
      initialY = 0;
      translateX = 0;
      translateY = 0;
    };

    const updateTransform = () => {
      requestAnimationFrame(() => {
        const imgElement = document.querySelector('.modal-content');
        if (imgElement) {
          const style = imgElement.style;
          if (zoom === 1) {
            translateX = 0;
            translateY = 0;
          }
          style.transform = `translate(${translateX}px, ${translateY}px) scale(${zoom})`;
          style.cursor = zoom > 1 ? 'move' : 'default';
        }
      });
    };

    el.addEventListener('click', (event) => {
      if (event.target.tagName === 'IMG' && !dialog) {
        const srcset = event.target.getAttribute('srcset');
        if (srcset) {
          const srcsetUrls = srcset.split(',');
          openDialog(srcsetUrls[0].split(' ')[0]);
        } else {
          openDialog(event.target.src);
        }
      } else if (dialog) {
        closeDialog();
      }
    });
  },

  unbind(el) {
    if (el._styleTag && el._styleTag.parentNode) {
      el._styleTag.parentNode.removeChild(el._styleTag);
    }
  }
});