declare global {
  interface Window {
    ym: (token: string, type: string, code: string) => void,
    dataLayer: any[],
    yaContextCb: any[]
  }
}

let token = ''
let isDev = true

const initializeReachGoal = (t: string, d: boolean) => {
  token = t
  isDev = d
}

const reachGoal = (code: string) => {
  if (process.client && !isDev && window.ym) {
    try {
      window.ym(token, 'reachGoal', code)
    } catch (e) {}
  }
}

export {
  initializeReachGoal,
  reachGoal
}
