import { Plugin } from '@nuxt/types'
import s from '~/utils/s'

const showNotificationServer: Plugin = async ({ store }) => {
  if (process.browser) {
    s(store).notification.notificationQueueServer.forEach((e) => {
      s(store).notification.addNotification(e)
    })

    s(store).notification.clearNotificationServer()
  }
}

export default showNotificationServer
