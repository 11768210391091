import Vue from 'vue'

function setImageAttributes(img: HTMLImageElement, attribute: string) {
  const value = img.getAttribute(`data-${attribute}`)
  if (value) {
    if (attribute === 'src') {
      img.src = value
    } else if (attribute === 'srcset') {
      img.srcset = value
    }
    img.onload = () => {
      img.removeAttribute(`data-${attribute}`)
      img.style.imageRendering = 'auto'
    }
  }
}

Vue.directive('intersection-observe', {
  inserted: function (el) {
    const loadImage = (img: HTMLImageElement) => {
      setImageAttributes(img, 'src')
      setImageAttributes(img, 'srcset')
    }

    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            const img = el.querySelector('img[data-src], img[data-srcset]') as HTMLImageElement
            if (img) {
              loadImage(img)
            }
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1
        }
      )
      observer.observe(el)
    } else {
      const img = el.querySelector('img[data-src], img[data-srcset]') as HTMLImageElement
      if (img) {
        loadImage(img)
      }
    }
  },
  bind: function (el) {
    const img = el.querySelector('img[data-src], img[data-srcset]') as HTMLImageElement
    if (img) {
      const placeholder = img.getAttribute('placeholder')
      if (placeholder) {
        img.src = placeholder
      } else {
        img.src = require('~/assets/image/pill.png')
        img.classList.add('responsive-image')
      }
    }
  }
})
