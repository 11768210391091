import { Vue, Component, Prop } from 'vue-property-decorator'
import { CartItem } from '../../../types/api/Cart'
import s from '~/utils/s'
import ProductBasketForHover from '~/components/element/ProductBasketForHover'
import loc from '~/utils/loc'

@Component
export default class BasketHover extends Vue {
  @Prop({ required: true, type: Array })
  cartItems!: CartItem[];

  getUpTo () {
    const cond = s(this.$store).regions.currentRegionSectionDeliveryConditions

    if (cond.recommendDeliveryUpTo) {
      return (
        cond.recommendDeliveryUpTo >=
        s(this.$store).basket.price
      )
    } else { return false }
  }

  getFreeDelivery () {
    const cond = s(this.$store).regions.currentRegionSectionDeliveryConditions

    return (
      cond.recommendDeliveryUpTo -
      s(this.$store).basket.price
    ).toFixed(2)
  }

  async removeAll () {
    await s(this.$store).basket.selectAll()
    await s(this.$store).basket.removeRows(s(this.$store).basket.selectedIds)
  }

  render () {
    const cond = s(this.$store).regions.currentRegionSectionDeliveryConditions

    return (
      <div class="basket-on-hover__basket">
        <div class="basket-on-hover__basket-wrapper">
          <div class="basket-on-hover__basket-title">Корзина</div>
          <div class="basket-on-hover__basket-header">
            <div class="basket-on-hover__basket-products">
              Товары ({this.cartItems.length})
            </div>
            <div
              class="basket-on-hover__basket-del-all"
              onClick={this.removeAll}
            >
              Очистить корзину
            </div>
          </div>
          <div class="basket-on-hover__basket-list-wrapper">
            <div class="basket-on-hover__basket-list">
              {this.cartItems.map(e => (
                <div key={e.id} class="basket-on-hover__basket-item">
                  <ProductBasketForHover
                    {...{
                      props: {
                        cartItem: e
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div class="basket-on-hover__basket-total">
            <div class="basket-on-hover__basket-total-info">
              <div class="basket-on-hover__basket-total-info-title">Итого:</div>
              <div class="basket-on-hover__basket-total-info-number">
                {s(this.$store).basket.price} ₽
              </div>
            </div>
            {cond.deliveryAvailable &&
              this.getUpTo() &&
              !s(this.$store).basket.containsPrescription && (
              <div class="basket-on-hover__basket-total-info deliv">
                <div class="basket-on-hover__basket-total-info-title deliv">
                    До бесплатной доставки осталось:
                </div>
                <div class="basket-on-hover__basket-total-info-number">
                  {this.getFreeDelivery()} ₽
                </div>
              </div>
            )}
            <nuxt-link
              to={loc(this.$store, { name: 'basket' })}
              class="basket-on-hover__basket-total-link-wrapper"
            >
              <div class="button-text">В корзину</div>
            </nuxt-link>
          </div>
        </div>
      </div>
    )
  }
}
