import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import config from '~/utils/config'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import s from '~/utils/s'
import Img from '~/types/api/Img'
import diseaseCanonicalUrlHelper from '~/utils/diseaseCanonicalUrlHelper'

@Module({
  name: 'diseaseDetailPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule implements ISeoDynamic {
  _code = ''
  _productListId = ''
  _date!: Date
  _description = ''
  private _HTMLContent = ''
  _id = 1
  _name = ''
  _diseaseGroupId = ''
  _img?: Img = undefined

  @Mutation
  setProductListId (productListId: string) {
    this._productListId = productListId
  }

  @Mutation
  setId (id: number) {
    this._id = id
  }

  @Mutation
  setCode (code: string) {
    this._code = code
  }

  @Mutation
  setName (name: string) {
    this._name = name
  }

  @Mutation
  setDescription (description?: string) {
    if (description) {
      this._description = description
    } else {
      this._description = ''
    }
  }

  @Mutation
  setImg (img?: Img) {
    this._img = img
  }

  @Mutation
  setDate (date: Date) {
    this._date = date
  }

  @Mutation
  setDiseaseGroupId (sectionId: string) {
    this._diseaseGroupId = sectionId
  }

  @Mutation
  setHTMLContent (content: string) {
    this._HTMLContent = content
  }

  @Action({ rawError: config.rawError })
  async loadData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const params = route.params
    let code = ''
    if (typeof params.code === 'string') {
      code = params.code
    }

    if (!code) {
      s(this.store).general.redirectNotFound()
    }

    const result = await this.store.$api.getDisease(code)

    if (result.code === 200 && result.data) {
      this.setId(result.data.id)
      this.setCode(result.data.code)
      this.setName(result.data.name)
      this.setImg(undefined)
      this.setDescription(result.data.description)
      this.setDate(result.data.createdAt)
      this.setDiseaseGroupId(result.data.diseaseGroupId)
      this.setHTMLContent(result.data.HTMLContent)
      this.setProductListId(result.data.productListId)
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  @Action({ rawError: config.rawError })
  loadCanonical (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const defaultCategory = s(this.store).regions.region?.items.find(e => e.default)
    let categoryCode = 'apteki'
    if (defaultCategory) {
      categoryCode = defaultCategory.code
    }
    s(this.store).seo.setCanonical(diseaseCanonicalUrlHelper(route.path, categoryCode))
  }

  get productListId (): string {
    return this._productListId
  }

  get id (): number {
    return this._id
  }

  get code (): string {
    return this._code
  }

  get name (): string {
    return this._name
  }

  get description (): string {
    return this._description
  }

  get seoCode (): string {
    return this._code
  }

  get date (): Date {
    return this._date
  }

  get diseaseGroupId (): string {
    return this._diseaseGroupId
  }

  get img (): Img | undefined {
    return this._img
  }

  get HTMLContent (): string {
    return this._HTMLContent
  }
}
