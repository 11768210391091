import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import s from '~/utils/s'

@Component({
  components: {
    ValidationProvider
  }
})
export default class SwitchElement extends Vue {
  isSwitch: boolean = false

  @Prop({ type: Boolean, required: true })
  readonly value?: boolean

  @Prop({ type: String, required: true })
  readonly name?: string

  @Prop({ type: String, required: true })
  readonly label?: string

  @Prop({ type: Object, default: () => { /* This is intentional */ } })
  readonly rules?: any

  @Prop({ type: Array, default: () => [] })
  readonly modifiers?: string[]

  @Prop({ type: Boolean, default: true })
  readonly disabled?: boolean

  created () {
    if (this.value) {
      this.isSwitch = this.value
    }
  }

  get isShowText (): boolean {
    return this.$scopedSlots.textPayload !== undefined
  }

  get css () {
    if (this.modifiers) {
      return Object.fromEntries(this.modifiers?.map(e => [`common-switch_${e}`, true]))
    }
    return {}
  }

  toggleSwitch () {
    this.isSwitch = !this.isSwitch
  }

  renderScopTextPayload () {
    if (this.$scopedSlots.textPayload !== undefined) {
      return this.$scopedSlots.textPayload(this)
    }
  }

  @Watch('isSwitch')
  innerValueChange (value: boolean) {
    this.$emit('change', value)
  }

  @Watch('value')
  valueChange (value: boolean) {
    if (value !== this.isSwitch) {
      this.isSwitch = value
    }
  }

  render () {
    return (
      <validation-provider {...{
        props: {
          rules: this.rules,
          name: this.label,
          customMessages: ru.messages,
          vid: this.name
        }
      }}>
        {({ errors }: any) => (
          <div {...{
            class: {
              'common-switch': true,
              'common-switch_error': errors.length > 0,
              'common-switch_hide-text': !this.isShowText,
              'common-switch__disabled': !this.disabled,
              ...this.css
            },
            on: {
              click: (event: MouseEvent) => {
                const { isAuth } = s(this.$store).auth
                if (!isAuth && this.name === 'waiting') {
                  event.stopPropagation()
                  s(this.$store).general.showPopup('AuthPopup')
                } else {
                  this.toggleSwitch()
                }
              }
            }
          }}>
            <div {...{
              class: {
                'common-switch__switch-input': true,
                'common-switch__switch-input_selected': this.isSwitch
              }
            }}>
              <div class="common-switch__circle"></div>
            </div>
            <div class="common-switch__switch-text">
              {this.renderScopTextPayload()}
            </div>
            <div class="common-switch__error">
              {errors[0]}
            </div>
          </div>
        )}
      </validation-provider>
    )
  }
}
