import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import User from '~/types/api/User'
import config from '~/utils/config'
import s from '~/utils/s'
import { PopupName } from '~/components/general/popups/Popup'
import { RecipeStatus } from '~/types/api/recipe/RecipeStatus'

export enum RECIPE_STATUS_MOBILE {
  issued = 'Выписан',
  registered = 'В резерве',
  deferred = 'Отложен',
  sold = 'Обеспечен'
}

export interface Pharmacy {
  id: string;
  vendorCode: string;
  codeMSOD: string;
  code: string;
  name: string;
  address: string;
  workingHours: string;
  phoneNumber: string;
  lat: number;
  lon: number;
  parentId: string;
  isDeleted: boolean;
}

export interface Recipe {
  id: string;
  series: string;
  number: string;
  issuedDate: Date;
  incomeDate: Date;
  saleDate: string;
  mo: string;
  doctor: string;
  mnn: string;
  cureform: string;
  dosage: string;
  package: string;
  quantity: number;
  signa: string;
  isSigned: boolean;
  statusName: RECIPE_STATUS_MOBILE;
  pharmacyId?: string;
  pharmacyClientId?: string;
  pharmacy?: Pharmacy;
  users?: User[];
}

@Module({
  name: 'recipePage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _recipeList: Recipe[] = []
  _showFilters: boolean = false
  _filterStatuses: RecipeStatus[] = []
  _selectedFilters: string[] = []
  _recipesLoading = false

  @Mutation
  setShowFilters (state: boolean) {
    this._showFilters = state
  }

  @Mutation
  setRecipeList (list: Recipe[]) {
    this._recipeList = list
  }

  @Mutation
  setFilterStatuses (list: RecipeStatus[]) {
    this._filterStatuses = list
  }

  @Mutation
  setSelectedFilters (list: string[]) {
    this._selectedFilters = list
  }

  @Mutation
  setRecipesLoading (state: boolean) {
    this._recipesLoading = state
  }

  get recipeList () {
    return this._recipeList
  }

  get showFilters () {
    return this._showFilters
  }

  get filterStatuses () {
    return this._filterStatuses
  }

  get selectedFilters () {
    return this._selectedFilters
  }

  get recipesLoading () {
    return this._recipesLoading
  }

  @Action({ rawError: config.rawError })
  async changeFilter (id: string) {
    const filters = [...this.selectedFilters]
    const itemIndex = filters.indexOf(id)

    if (itemIndex < 0) {
      this.setSelectedFilters([...filters, id])
    } else {
      this.setSelectedFilters([
        ...filters.slice(0, itemIndex),
        ...filters.slice(itemIndex + 1)
      ])
    }
    await this.loadRecipes()
  }

  @Action({ rawError: config.rawError })
  async loadStatuses () {
    const response = await this.store.$api.fetchRecipeFilterStatuses()

    if (response.data && response.code === 200) {
      this.setFilterStatuses(response.data)
    }
  }

  @Action({ rawError: config.rawError })
  async loadRecipes () {
    const response = await this.store.$api.fetchRecipes({
      status: this.selectedFilters
    })

    if (response.data && response.code === 200) {
      this.setRecipeList(response.data.recipeInfo)
    } else if (response.code === 495) {
      if (process.client) {
        s(this.store).general.showPopup(PopupName.popupEsiaAuth)
      }
    }
  }

  @Action({ rawError: config.rawError })
  async downloadRecipes () {
    this.setRecipesLoading(true)
    const response = await this.store.$api.downloadRecipes()
    this.setRecipesLoading(false)

    if (response.code === 200) {
      await this.loadRecipes()
    } else if (response.code === 495) {
      s(this.store).general.showPopup(PopupName.popupEsiaAuth)
    }
  }
}
