import { Middleware, Context } from '@nuxt/types'
import SeoHelper from '~/utils/seoHelper'

const seoMiddleware: Middleware = async (context: Context) => {
  const ignore = [
    'region-all',
    'region-news-code',
    'region-pharmacies-id',
    'region-stock-code',
    'region-disease-code',
    'region-diseases-section'
  ]
  if (!ignore.includes(context.route.name ?? '')) {
    await new SeoHelper(context.route.name ?? '', context.store).load()
  }
}

export default seoMiddleware
