import { Vue, Component } from 'vue-property-decorator'
import ArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import GeneralListType from '~/types/enum/GeneralListType'
import s from '~/utils/s'

@Component
export default class BasketScrollMobile extends Vue {
  selectorTarget: string = '.basket-result'
  isRouter: boolean = true
  isScroll: boolean = true
  currentScrollY: number = 0
  isScrollWindow: boolean = false

  get isHaveProducts () {
    return s(this.$store).basket.items.length > 0
  }

  created () {
    this.isRouter = this.$route.name === 'region-basket'
    this.$router.afterEach((to) => {
      this.isRouter = to.name === 'region-basket'
      this.scrollHandler()
    })
  }

  mounted () {
    window.addEventListener('scroll', this.scrollHandler)
    this.scrollHandler()
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  scrollHandler () {
    const elem = document.querySelector(this.selectorTarget)
    if (elem) {
      this.isScroll = elem.getBoundingClientRect().top > window.innerHeight / 2
    }

    if (this.currentScrollY < window.scrollY) {
      this.isScrollWindow = true
    } else {
      this.isScrollWindow = false
    }
    this.currentScrollY = window.scrollY
  }

  scrollTo () {
    const elem = document.querySelector(this.selectorTarget)
    if (elem) {
      const offset = window.scrollY + elem.getBoundingClientRect().top - window.innerHeight / 4
      window.scroll({ top: offset, left: 0, behavior: 'smooth' })
    }
  }

  get isShow () {
    return this.isRouter && this.isScroll && s(this.$store).general.generalListType === GeneralListType.None
  }

  render () {
    if (this.isHaveProducts) {
      return (
        <transition name="basket-scroll-mobile__animate">
          {this.isShow &&
            <div {...{
              class: {
                'basket-scroll-mobile': true,
                'basket-scroll-mobile__scroll-window': this.isScrollWindow
              }
            }}>
              <div class="basket-scroll-mobile__content" onClick={this.scrollTo}>
                <div class="basket-scroll-mobile__scroll-text">К оформлению</div>
                <div class="basket-scroll-mobile__scroll-logo">
                  <ArrowSvg />
                </div>
              </div>
            </div>
          }
        </transition>
      )
    }
  }
}
