const list = {
  bySortAsc: 'По умолчанию',
  byNameAsc: 'По наименованию',
  byPriceDesc: 'По убыванию цены',
  byPriceAsc: 'По возрастанию цены',
  byRatingDesc: 'По рейтингу'
}

export function checkValue (val: any): string {
  if (typeof val === 'string') {
    if (Object.keys(list).includes(val)) {
      return val
    }
  }
  return Object.keys(list)[0]
}

export default list
