import { Store } from 'vuex'
import seoRoute from '~/utils/config/seoRoute'
import SeoRouteConfig from '~/types/seo/SeoRouteConfig'
import SeoPageType from '~/types/seo/SeoPageType'
import SeoDynamicOptions from '~/types/api/seo/SeoDynamicOptions'
import SeoStaticOptions from '~/types/api/seo/SeoStaticOptions'
import SeoDynamicObjectType from '~/types/seo/SeoDynamicObjectType'
import UnknownPageType from '~/types/enum/UnknownPageType'
import SeoDynamicSectionType from '~/types/seo/SeoDynamicSectionType'
import seoDefault from '~/utils/config/seoDefault'
import SeoData from '~/types/seo/SeoData'
import s from '~/utils/s'

export default class SeoHelper {
  private routerName: string
  private routeConfigs: SeoRouteConfig[]
  private pageType: SeoPageType = SeoPageType.Static
  private loadData?: SeoData
  private store: Store<any>

  constructor (routerName: string, store: Store<any>) {
    this.store = store
    this.routerName = routerName
    this.routeConfigs = seoRoute(store)
  }

  async load () {
    const options = this.getOptions()
    const request = this.store.$api.getMetaDynamics(options as SeoDynamicOptions)

    const result = await request
    if (result.code === 200 && result.data) {
      this.loadData = result.data
    } else {
      this.loadData = seoDefault
    }
 
    const title = this.loadData.title ? this.loadData.title : seoDefault.title
    const description = this.loadData.description ? this.loadData.description : seoDefault.description
    const keywords = this.loadData.keywords ? this.loadData.keywords : seoDefault.keywords
    const imgTitle = this.loadData.imgTitle ? this.loadData.imgTitle : seoDefault.imgTitle
    const imgAlt = this.loadData.imgAlt ? this.loadData.imgAlt : seoDefault.imgAlt

    if (this.loadData) {
      s(this.store).seo.setTitle(title)
      s(this.store).seo.setDescription(description)
      s(this.store).seo.setKeywords(keywords)
      // s(this.store).seo.setCanonical('')
      s(this.store).seo.setImgTitle(imgTitle)
      s(this.store).seo.setImgAlt(imgAlt)
      s(this.store).seo.load({
        host: s(this.store).general.host ?? '',
        subdomain: s(this.store).regions.subdomain
      })
    }
  }

  private getOptions (): SeoDynamicOptions | SeoStaticOptions {
    if (this.routerName === 'region-all') {
      return this.getRegionAllRouterOptions()
    }

    const config = this.routeConfigs.find(e => e.route === this.routerName)
    if (config && config.type === SeoPageType.Dinamic) {
      return this.getDynamicRouterOptions(config)
    } else if (config && config.type === SeoPageType.Static) {
      return this.getStaticRouterOptions(config)
    } else {
      return this.getDefaultOptions()
    }
  }

  private getRegionAllRouterOptions (): SeoDynamicOptions | SeoStaticOptions {
    const catalogPageType = s(this.store).unknownPage.pageType
    let code = ''
    if (catalogPageType === UnknownPageType.Category) {
      code = s(this.store).categoryPage.category.id
    } else if (catalogPageType === UnknownPageType.Product) {
      code = s(this.store).productPage.id
    }
    if (code) {
      this.pageType = SeoPageType.Dinamic
      return {
        code,
        object: SeoDynamicObjectType.Catalog,
        category: catalogPageType === UnknownPageType.Category ? SeoDynamicSectionType.Section : SeoDynamicSectionType.Item,
        regionId: s(this.store).regions.regionId ?? '',
        categoryCode: s(this.store).categoryPage.category.id
      }
    }
    return this.getDefaultOptions()
  }

  private getStaticRouterOptions (config: SeoRouteConfig): SeoStaticOptions {
    if (config.name) {
      this.pageType = SeoPageType.Static
      return {
        code: config.name,
        regionId: s(this.store).regions.regionId ?? ''
      }
    }
    return this.getDefaultOptions()
  }

  private getDynamicRouterOptions (config: SeoRouteConfig): SeoDynamicOptions | SeoStaticOptions {
    if (config.store && config.objectType && config.sectionType) {
      this.pageType = SeoPageType.Dinamic
      return {
        object: config.objectType,
        category: config.sectionType,
        code: config.store.seoCode,
        regionId: s(this.store).regions.regionId ?? ''
      }
    }
    return this.getDefaultOptions()
  }

  private getDefaultOptions (): SeoStaticOptions {
    this.pageType = SeoPageType.Static
    return {
      code: 'default',
      regionId: s(this.store).regions.regionId ?? ''
    }
  }
}
