import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import Pharmacy from '~/types/api/pharmacy/Pharmacy'
import ISeoDynamic from '~/types/seo/ISeoDynamic'

@Module({
  name: 'pharmacyDetailPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule implements ISeoDynamic {
  _item?: Pharmacy = undefined

  @Mutation
  setItem (item?: Pharmacy) {
    this._item = item
  }

  get item (): Pharmacy | undefined {
    return this._item
  }

  get seoCode (): string {
    const id = this._item?.id ?? 0
    return id.toString()
  }
}
