import { Component, Prop, Vue } from 'vue-property-decorator'
import ImageElement from '~/components/element/ImageElement'
import BasketNumber from '~/components/element/BasketNumber'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import SwitchElement from '~/components/element/SwitchElement'
import SuccessSvg from '~/assets/svg/product-success.svg?inline'
import { CartItem } from '~/types/api/Cart'
import Product from '~/types/api/Product'
import Img from '~/types/api/Img'
import { MetrikaLocalCodes } from '~/types/api/MetrikaCode'

@Component
export default class ProductBasketForHover extends Vue {
  @Prop({ required: true, type: Object })
  cartItem!: CartItem;

  @Prop({ required: false, type: Boolean, default: false })
  inWaitingList!: boolean;

  isShowSuccess: boolean = false;
  product: Product = this.cartItem.product;
  sussessTimeout: NodeJS.Timeout | undefined;

  inHover: boolean = true;

  beforeDestroy () {
    if (this.sussessTimeout) {
      clearTimeout(this.sussessTimeout)
    }
  }

  get isDiscount (): boolean {
    return this.product.price < this.product.basePrice
  }

  get isAvailable (): boolean {
    return this.product.available > 0
  }

  get isMnn (): boolean {
    return !!(this.product.mnn && this.product.mnn.length > 0)
  }

  get isSelected (): boolean {
    return s(this.$store).basket.selectedIds.includes(this.cartItem.id)
  }

  get isFavorite (): boolean {
    return s(this.$store).wishlist.ids.includes(this.product.id)
  }

  get link (): string {
    const helper = new SectionHelper(
      s(this.$store).general.sections,
      this.$store
    )
    return helper.getProductLink(
      s(this.$store).categoryPage.category.id,
      this.product.seoUrl ?? this.product.id
    )
  }

  get basketItem (): CartItem | undefined {
    return s(this.$store).basket.items.find(
      e => e.product.id === this.product.id
    )
  }

  get isBasket (): boolean {
    return this.basketItem !== undefined
  }

  get defaultImg (): Img {
    return {
      d: require('~/assets/image/pill.png'),
      t: require('~/assets/image/pill.png'),
      m: require('~/assets/image/pill.png')
    }
  }

  renderSuccess () {
    if (this.isShowSuccess) {
      return (
        <div class="product-card__favorite-success">
          <div class="product-card__favorite-success-icon">
            <SuccessSvg />
          </div>
          <div class="product-card__favorite-success-text">В корзине</div>
        </div>
      )
    }
  }

  async remove () {
    if (!this.inWaitingList) {
      await s(this.$store).basket.removeRows([this.cartItem.id])
    } else {
      await this.addToWaitingList(false)
    }
  }

  select (val: boolean) {
    if (val) {
      s(this.$store).basket.addSelectedId(this.cartItem.id)
    } else {
      s(this.$store).basket.removeSelectedId(this.cartItem.id)
    }
  }

  get isWaitingList (): boolean {
    return s(this.$store)
      .waitingList.items.map(id => id.product.id)
      .includes(this.product.id)
  }

  get isWaitingListModifier (): any {
    if (this.isWaitingList) {
      return ['waiting_selected']
    }
    return []
  }

  async addToWaitingList (value: boolean) {
    if (value && !this.inWaitingList) {
      await s(this.$store).waitingList.addProductToWaitingList(this.product.id)
    } else if (!value && this.inWaitingList) {
      await s(this.$store).waitingList.removeProductFromWaitingList(
        this.product.id
      )
    }
    await s(this.$store).basket.fillData()
  }

  async addToBasket () {
    this.isShowSuccess = await s(this.$store).basket.add(
      this.product.id
    )
    this.sussessTimeout = setTimeout(() => {
      this.isShowSuccess = false
    }, 2000)
    this.$reachGoal(
      await s(this.$store).seo.getMetrikaCode(MetrikaLocalCodes.ADD_PRODUCT)
    )
    if (this.inWaitingList) {
      await s(this.$store).waitingList.fillData()
    }
  }

  renderWaitingListButton () {
    return (
      <SwitchElement
        {...{
          props: {
            value: this.isWaitingList,
            name: 'waiting',
            label: 'Сообщим о поступлении',
            modifiers: [...['waiting'], ...this.isWaitingListModifier]
          },
          on: {
            change: async (v: boolean) => {
              await this.addToWaitingList(v)
            }
          },
          scopedSlots: {
            textPayload: () => 'Сообщим о поступлении'
          }
        }}
      />
    )
  }

  renderBasketButton () {
    if (this.isBasket) {
      return (
        <BasketNumber
          {...{
            props: {
              id: this.product.id,
              inHover: this.inHover
            }
          }}
        />
      )
    } else {
      return (
        <div class="button-text" onClick={this.addToBasket}>
          В корзину
        </div>
      )
    }
  }

  renderBasketPrice () {
    if (this.isAvailable) {
      return (
        <div class="basket-hover__price">
          <div class="basket-hover__price-wrapper">
            <div class="basket-hover__price-base">{this.product.price} ₽</div>
          </div>
          <div class="basket-hover__selector">{this.renderBasketButton()}</div>
        </div>
      )
    } else {
      return (
        <div class="basket-hover__price">
          <div class="basket-hover__price-wrapper">
            <div class="basket-hover__basket-text-not-available">
              Товара нет в наличии
            </div>
            {this.renderWaitingListButton()}
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <div
        class={{
          'basket-hover': true,
          'not-available': !this.isAvailable
        }}
      >
        <div class="basket-hover__row">
          <div class="basket-hover__col-image">
            <div class="basket-hover__image">
              {this.product.img && (
                <nuxt-link to={this.link}>
                  <ImageElement
                    {...{
                      props: {
                        img:
                          Object.keys(this.product.img).length !== 0
                            ? this.product.img
                            : this.defaultImg
                      }
                    }}
                  />
                </nuxt-link>
              )}
            </div>
          </div>
          <div class="basket-hover__col-content">
            <div class="basket-hover__content">
              <nuxt-link to={this.link} class="basket-hover__content-header">
                {this.product.title}
              </nuxt-link>
              {this.product.byPrescription && (
                <div class="basket-hover__content-prescription">По рецепту</div>
              )}
              {this.product.isVeterinary && (
                <div class="basket-hover__content-prescription">
                  Вет препарат
                </div>
              )}
            </div>
          </div>
          <div class="basket-hover__col-price">{this.renderBasketPrice()}</div>
          <transition name="basket-hover__animate">
            {this.renderSuccess()}
          </transition>
        </div>
      </div>
    )
  }
}
