import { Store } from 'vuex'
import arrayToTree from 'array-to-tree'
import TreeModel from '~/utils/libs/tree-model'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'

interface SectionPath {
  path: string,
  id: string
}

export default class SectionHelper {
  private sections: SfSection[] = []
  private sectionPathList: SectionPath[] = []
  private treeModel?: TreeModel
  private root?: TreeModel.Node<arrayToTree.Tree<SfSection>>
  private store: Store<any>

  constructor (sections: SfSection[], store: Store<any>) {
    this.store = store
    this.update(sections)
  }

  update (sections: SfSection[]) {
    this.sections = sections
    this.sectionPathList = []
    this.treeModel = new TreeModel({
      childrenPropertyName: 'children'
    })
    const items = arrayToTree<SfSection>(this.sections ?? [], {
      parentProperty: 'parentId'
    })

    const rootNode = {
      id: '0',
      name: 'Каталог',
      // code: 'catalog',
      // leftMargin: -1,
      // rightMargin: -1,
      // depthLevel: -1,
      parentId: '-1',
      // isPreset: false,
      children: items,
      seo: [{ 
        id: '0',
        seoUrl: 'catalog',
        categoryId: 'apteki', 
        regionId: '0', 
      }],
      alternativeDisplay: false,
      isPreset: false
    }

    this.root = this.treeModel.parse(rootNode)

    this.root.all((e) => {
      const pathList = e.getPath()
      this.sectionPathList.push({
        id: pathList[pathList.length - 1].model.id,
        path: pathList.reduce((r, v) => (`${r}/${v.model.seo?.[0]?.seoUrl?? v.model.id}`), `/${s(this.store).regions.sectionCode}`) + '/'
      })
      return true
    })
  }

  getSectionIdByPath (path: string) : string | undefined {
    const sectionPath = this.sectionPathList.find(e => e.path === path)
    return sectionPath?.id
  }

  getSectionPathById (id: string) {
    // const section = this.sections.find(e => e.id === id)
    // const parentSection = section ? this.sections.find(e => e.id === section.parentId) : null

    /* if (section && (section?.isPreset || parentSection?.isPreset)) {
      const link = `/${s(this.store).regions.sectionCode}/presets`

      return `${link}/${section.id}/`
    } */
    const sectionPath = this.sectionPathList.find(e => e.id === id)
    return sectionPath?.path
  }

  mapSectionIdWithPath () {
    return this.sectionPathList.reduce((acc, sp) => {
      return {
        ...acc,
        [sp.id]: sp.path
      }
    }, {})
  }

  getSectionsPath (id: string): SfSection[] {
    const target = this.root?.first(e => e.model.id === id)
    if (!target) {
      return []
    }
    return target.getPath().map(e => e.model)
  }

  getProductLink (sectionId: string, productCode: string): string {
    const sections = this.getSectionsPath(sectionId)
    return sections.reduce((p, e) => p + (e.seo?.[0]?.seoUrl ?? e.id) + '/', `/${s(this.store).regions.sectionCode}/`) + productCode + '/'
  }
}
