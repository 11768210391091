import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'
import BgSvg from '~/assets/svg/section-closed-bg.svg?inline'
import SectionClosedSvg from '~/assets/svg/section-closed-img.svg?inline'
import VeterinariyIcon from '~/assets/svg/region-veterinariy.svg?inline'
import AptekiIcon from '~/assets/svg/region-apteki.svg?inline'
import OrtopediaIcon from '~/assets/svg/region-ortopedia.svg?inline'

@Component
export default class SectionClosed extends Vue {
  closedTo: string = ''
  sectionName: string = ''
  reasonClosed: string = ''

  mounted () {
    this.updateSectionInfo()
  }

  updateSectionInfo () {
    this.closedTo = s(this.$store).regions.section?.closedTo ?? 'завтра'
    // if (this.closedTo && this.closedTo !== 'завтра') {
    //   this.closedTo = new Date(this.closedTo).toLocaleDateString()
    // }
    this.sectionName = s(this.$store).regions.section?.name ?? ''
    this.reasonClosed = s(this.$store).regions.section?.reasonClosed ?? ''
  }

  renderIcon (sectionCode: string) {
    switch (sectionCode) {
      case 'ortopedia':
        return (<OrtopediaIcon />)
      case 'veterinariy':
        return (<VeterinariyIcon />)
      default:
        return (<AptekiIcon />)
    }
  }

  renderOpenedSections () {
    if (s(this.$store).regions.region?.items !== undefined) {
      return (
        <div class="section-closed-page__opened-sections">
          <div class="section-closed-page__opened-sections-text">
            Вы можете сделать заказ в следующих направлениях:
          </div>
          <div>
            {s(this.$store).regions.region?.items.map((section) => {
              if (section.opened) {
                return (
                  <nuxt-link to={{
                    name: 'region',
                    params: {
                      region: section.code
                    }
                  }} class="section-closed-page__opened-section-block">
                    <div class="section-closed-page__opened-section-icon">
                      {this.renderIcon(section.code)}
                    </div>
                    <div class="section-closed-page__opened-section-name">
                      {section.name}
                    </div>
                  </nuxt-link>
                )
              }
            })}
          </div>
        </div>
      )
    }
  }

  render () {
    if (!s(this.$store).regions.section?.opened) {
      this.updateSectionInfo()
    }
    return (
      <div class="section-closed-page">
        <div class="section-closed-page__bg">
          <BgSvg />
        </div>
        <div class="section-closed-page__content">
          <div class="section-closed-page__header">
            Раздел &laquo;{this.sectionName}&raquo;
            временно недоступен до {this.closedTo}
            <div class="section-closed-page__apologise">
              Приносим извинения за доставленные неудобства
            </div>
            <div class="section-closed-page__apologise">
              {this.reasonClosed}
            </div>
            {this.renderOpenedSections()}
          </div>
          <div class="section-closed-page__image">
            <SectionClosedSvg />
          </div>
        </div>
      </div>
    )
  }
}
