import { Vue, Component } from 'vue-property-decorator'
import OrderRatingCommentSvg from '~/assets/svg/order-rating-comment.svg?inline'
import Btn from '~/components/element/Btn'
import s from '~/utils/s'
import TextInput from '~/components/element/TextInput'

@Component
export default class OrderRatingStepComment extends Vue {
  text = ''

  render () {
    return (
      <div class="popup-rating">
        <div class="popup-rating__header">
          {s(this.$store).orderRating.starValue >= 4
            ? 'Мы рады, что вам все понравилось!'
            : 'Уточните, что не так?'}
        </div>
        <div class="popup-rating__description">
          {s(this.$store).orderRating.starValue >= 4
            ? 'Спасибо за то, что выбираете нас'
            : 'Поделитесь своими пожеланиями по улучшению сайта. Ваш отзыв важен для нас'
          }
        </div>
        <div class="popup-rating__comment">
          {s(this.$store).orderRating.starValue >= 4 &&
          <div class="popup-rating__thanks-icon">
            <div class="popup-rating__thanks-icon-comment">
              <OrderRatingCommentSvg/>
            </div>
          </div>
          }
          <div class="popup-rating__comment-text">
            <TextInput
              key="text"
              {...{
                props: {
                  name: 'text',
                  label: 'Комментарий',
                  value: this.text,
                  type: 'textarea',
                  placeholder: ''
                },
                on: {
                  input: (v: string) => { s(this.$store).orderRating.setComment(v) }
                }
              }}
            ></TextInput>
          </div>
          <Btn
            {...{
              props: { label: 'Отправить', wide: true, type: 'button' },
              nativeOn: {
                click: async () => {
                  if (await s(this.$store).orderRating.sendOrderForRating()) {
                    s(this.$store).general.closePopup()
                  }
                }
              }
            }}
          ></Btn>
        </div>
      </div>
    )
  }
}
