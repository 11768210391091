import { Vue, Component } from 'vue-property-decorator'
import SmsAuthorization from '~/components/general/SmsAuthorization'
import s from '~/utils/s'

@Component
export default class AuthPopup extends Vue {
  onAuthSuccess () {
    s(this.$store).general.closePopup()
    this.$forceUpdate()
  }

  render () {
    return (
      <SmsAuthorization {...{
        props: {
          onAuthSuccess: this.onAuthSuccess
        }
      }}/>
    )
  }
}
