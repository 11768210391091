import { Middleware, Context } from '@nuxt/types'

const baseURLRedirectMiddleware: Middleware = (context: Context) => {
  const validUrls: string[] = [
    'apteki',
    'veterinariy',
    'elektronnii-sertifikat'
  ]

  if (!validUrls.includes(context.route.params?.region.toLowerCase())) {
    context.redirect(301, '/')
  }
}

export default baseURLRedirectMiddleware
