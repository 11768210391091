import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/advertising.ts'), 'advertising.ts')
  resolveStoreModules(require('../store/auth.ts'), 'auth.ts')
  resolveStoreModules(require('../store/bannerPage.ts'), 'bannerPage.ts')
  resolveStoreModules(require('../store/basket.ts'), 'basket.ts')
  resolveStoreModules(require('../store/categoriesPage.ts'), 'categoriesPage.ts')
  resolveStoreModules(require('../store/categoryPage.ts'), 'categoryPage.ts')
  resolveStoreModules(require('../store/collectionProduct.ts'), 'collectionProduct.ts')
  resolveStoreModules(require('../store/diseaseDetailPage.ts'), 'diseaseDetailPage.ts')
  resolveStoreModules(require('../store/diseasesPage.ts'), 'diseasesPage.ts')
  resolveStoreModules(require('../store/eCommerce.ts'), 'eCommerce.ts')
  resolveStoreModules(require('../store/filter.ts'), 'filter.ts')
  resolveStoreModules(require('../store/general.ts'), 'general.ts')
  resolveStoreModules(require('../store/helpPages.ts'), 'helpPages.ts')
  resolveStoreModules(require('../store/mainPage.ts'), 'mainPage.ts')
  resolveStoreModules(require('../store/markingAd.ts'), 'markingAd.ts')
  resolveStoreModules(require('../store/newsDetailPage.ts'), 'newsDetailPage.ts')
  resolveStoreModules(require('../store/newsPage.ts'), 'newsPage.ts')
  resolveStoreModules(require('../store/notification.ts'), 'notification.ts')
  resolveStoreModules(require('../store/noveltiesPage.ts'), 'noveltiesPage.ts')
  resolveStoreModules(require('../store/orderDetailPage.ts'), 'orderDetailPage.ts')
  resolveStoreModules(require('../store/orderPage.ts'), 'orderPage.ts')
  resolveStoreModules(require('../store/orderRating.ts'), 'orderRating.ts')
  resolveStoreModules(require('../store/partnerCardBlock.ts'), 'partnerCardBlock.ts')
  resolveStoreModules(require('../store/personalPage.ts'), 'personalPage.ts')
  resolveStoreModules(require('../store/pharmaciesPage.ts'), 'pharmaciesPage.ts')
  resolveStoreModules(require('../store/pharmacyDetailPage.ts'), 'pharmacyDetailPage.ts')
  resolveStoreModules(require('../store/popularPage.ts'), 'popularPage.ts')
  resolveStoreModules(require('../store/presetPage.ts'), 'presetPage.ts')
  resolveStoreModules(require('../store/productCardsCommercial.ts'), 'productCardsCommercial.ts')
  resolveStoreModules(require('../store/productPage.ts'), 'productPage.ts')
  resolveStoreModules(require('../store/recipePage.ts'), 'recipePage.ts')
  resolveStoreModules(require('../store/regions.ts'), 'regions.ts')
  resolveStoreModules(require('../store/reviewsPage.ts'), 'reviewsPage.ts')
  resolveStoreModules(require('../store/salePage.ts'), 'salePage.ts')
  resolveStoreModules(require('../store/searchBar.ts'), 'searchBar.ts')
  resolveStoreModules(require('../store/searchPage.ts'), 'searchPage.ts')
  resolveStoreModules(require('../store/seo.ts'), 'seo.ts')
  resolveStoreModules(require('../store/seoTextBlock.ts'), 'seoTextBlock.ts')
  resolveStoreModules(require('../store/staticPages.ts'), 'staticPages.ts')
  resolveStoreModules(require('../store/stockDetailPage.ts'), 'stockDetailPage.ts')
  resolveStoreModules(require('../store/stockPage.ts'), 'stockPage.ts')
  resolveStoreModules(require('../store/unknownPage.ts'), 'unknownPage.ts')
  resolveStoreModules(require('../store/userProductList.ts'), 'userProductList.ts')
  resolveStoreModules(require('../store/waitingList.ts'), 'waitingList.ts')
  resolveStoreModules(require('../store/wishlist.ts'), 'wishlist.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
