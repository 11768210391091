import { Plugin } from '@nuxt/types'
import { reachGoal, initializeReachGoal } from '~/utils/initializeReachGoal'

interface ReachGoal {
  (code: string): void
}

declare module 'vue/types/vue' {
  interface Vue {
    $reachGoal: ReachGoal
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $reachGoal: ReachGoal
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $reachGoal: ReachGoal
  }
}

const reachGoalPlugin: Plugin = (context, inject) => {
  const token = context.env.NODE_ENV_YANDEX_METRIKA_TOKEN as string
  initializeReachGoal(token, context.isDev)
  inject('reachGoal', reachGoal)
}

export default reachGoalPlugin
