import { Vue, Component } from 'vue-property-decorator'
import BellSvg from '~/assets/svg/bell.svg?inline'
import s from '~/utils/s'
import Btn from '~/components/element/Btn'

@Component
export default class WaitingListPopup extends Vue {
  render () {
    return (
      <div class="waiting-list-popup">
        <div class="waiting-list-popup__header">
          Мы уведомим вас о поступлении
        </div>
        <div class="waiting-list-popup__content">
          <div class="waiting-list-popup__icon">
            <BellSvg />
          </div>
          <div class="waiting-list-popup__text">
            Товар добавлен в лист ожидания
          </div>
        </div>
        <Btn
          {...{
            props: { label: 'Закрыть', wide: true, type: 'button' },
            nativeOn: {
              click: () => {
                s(this.$store).general.closePopup()
              }
            }
          }}
        ></Btn>
      </div>
    )
  }
}
