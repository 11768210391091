import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import config from '~/utils/config'
import s from '~/utils/s'
import { MainSliderItem } from '~/types/api/MainSliderItem'
import { BANNER_GROUP_CODE } from '~/types/enum/BannerGroups'

@Module({
  name: 'partnerCardBlock',
  stateFactory: true,
  namespaced: true
})
export default class PartnerCardBlock extends VuexModule {
  _partnerCards: MainSliderItem[] = []

  @Mutation
  setPartnerCards (items: MainSliderItem[]) {
    this._partnerCards = items
  }

  @Action({ rawError: config.rawError })
  async loadPartnerCardBlock (bannerGroupCode: BANNER_GROUP_CODE) {
    const result = await this.store.$api.getPartnerCardBlock({
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId,
      bannerGroup: bannerGroupCode
    })

    if (result.code === 200 && result.data) {
      this.setPartnerCards(result.data)
    } else {
      this.setPartnerCards([])
    }
  }

  get partnerCards (): MainSliderItem[] {
    return this._partnerCards
  }
}
