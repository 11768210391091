import { Plugin } from '@nuxt/types'
import { ReCaptchaInstance } from '@nuxtjs/recaptcha'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { initializeApi } from '~/utils/api'
import Api from '~/utils/api/Api'

declare module 'vue/types/vue' {
  interface Vue {
    $api: Api
    $cookies: NuxtCookies,
    $recaptcha: ReCaptchaInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $api: Api
    $cookies: NuxtCookies
  }

  interface Context {
    $api: Api
    $cookies: NuxtCookies
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: Api
    $cookies: NuxtCookies
  }
}

const api: Plugin = (context, inject) => {
  const isDebug = context.env.NUXT_ENV_DEBUG_API === 'true'
  const apiEnv = initializeApi(isDebug, context.$axios, context.store)
  inject('api', apiEnv)
}

export default api
