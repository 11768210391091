import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import qs from 'qs'
import FilterItem from '~/types/api/filter/FilterItem'
import FilterOptions from '~/types/api/options/FilterOptions'
import FilterType from '~/types/api/filter/FilterType'
import FilterRangeOptions from '~/types/api/options/FilterRangeOptions'
import config from '~/utils/config'

interface SelectData {
  code: string,
  type: FilterType
  val: string | boolean | FilterRangeOptions,
  payload?: boolean,
  value?: string
}

const listParser = (obj: any): string[] | null => {
  if (Array.isArray(obj) && obj.length > 0) {
    return obj.map(e => e.toString())
  }
  return null
}

const rangeParser = (obj: any): FilterRangeOptions | null => {
  if (typeof obj.MIN === 'string' && typeof obj.MAX === 'string') {
    const min = parseFloat(obj.MIN)
    const max = parseFloat(obj.MAX)
    if (!isNaN(min) && !isNaN(max)) {
      return {
        MIN: min,
        MAX: max
      }
    }
  }
  return null
}

const boolParser = (obj: any): boolean | null => {
  if (obj === 'true') {
    return true
  } else if (obj === 'false') {
    return false
  }
  return null
}

@Module({
  name: 'filter',
  stateFactory: true,
  namespaced: true
})
export default class FilterStore extends VuexModule {
  _list: FilterItem[] = []
  _selectedFilter: FilterOptions = {}
  _queryFilter: any = {}
  _filterLabels: { [x: string]: string } = {}
  _loading = false

  @Mutation
  setList(list?: FilterItem[]) {
    if (list) {
      this._list = list
    } else {
      this._list = []
    }
  }

  @Mutation
  setSelectedFilter(entity: FilterOptions) {
    this._selectedFilter = entity
  }

  @Mutation
  setQueryFilter(entity: any) {
    this._queryFilter = entity
  }

  @Mutation
  setLoading(value: boolean) {
    this._loading = value
  }

  @Action({ rawError: config.rawError })
  getFilterObject() {
    const route: Route = this.store.$router.currentRoute
    let query: any = {}
    const queryString = Object.entries(route.query).map(([key, value]) => `${key}=${value}`)
      .reduce((r, c) => r + c + '&', '')
    const queryEntity = qs.parse(queryString)
    delete queryEntity.filter
    if (queryEntity.page) {
      query = {
        ...queryEntity,
        page: '1'
      }
    } else {
      query = queryEntity
    }

    let prev = {}
    if (this.selectedFilter) {
      prev = {
        filter: this.selectedFilter
      }
    }

    query = Object.entries(query).reduce((p, [key, value]) => ({
      ...p,
      [key]: value
    }), prev)

    return query
  }

  @Action({ rawError: config.rawError })
  async applyFilter() {
    const route: Route = this.store.$router.currentRoute
    const query: any = await this.getFilterObject()
    await this.store.$router.push(`${route.path}?${encodeURI(qs.stringify(query))}`)
  }

  @Action({ rawError: config.rawError })
  async clearFilter() {
    this.setSelectedFilter({})
    await this.applyFilter()
  }

  @Action({ rawError: config.rawError })
  async clearByCode(code: string) {
    const filter = Object.entries(this.selectedFilter).reduce((r, [key, value]) => {
      if (key === code) {
        return r
      } else {
        return {
          ...r,
          [key]: value
        }
      }
    }, {})
    this.setSelectedFilter(filter)
    await this.applyFilter()
  }

  @Action({ rawError: config.rawError })
  fillQueryFilter(route: Route) {
    const queryString = Object.entries(route.query).map(([key, value]) => `${key}=${value}`)
      .reduce((r, c) => r + c + '&', '')
    const queryEntity = qs.parse(queryString)
    const filter = queryEntity.filter as FilterOptions
    this.setQueryFilter(filter)
  }

  @Action({ rawError: config.rawError })
  async fillSelectedFilter() {
    const options: FilterOptions = {}
    if (this.queryFilter) {
      for (const [key, value] of Object.entries(this.queryFilter)) {
        const filterItem = this.list.find(e => e.code === key)
        if (filterItem) {
          const parser = await this.getParser(filterItem)
          if (!parser) {
            continue
          }
          const data = parser(value)
          if (data !== null) {
            options[key] = data
          }
        }
      }
    }

    this.setSelectedFilter(options)
  }

  @Action({ rawError: config.rawError })
  getParser(filterItem: FilterItem) {
    if (filterItem.view === FilterType.Checkbox) {
      return listParser
    } else if (filterItem.view === FilterType.Range || filterItem.view === FilterType.Price) {
      return rangeParser
    } else if (filterItem.view === FilterType.FlagBoolean || filterItem.view === FilterType.FlagNumeric) {
      return boolParser
    } else {
      return null
    }
  }

  @Action({ rawError: config.rawError })
  async setValue({ code, type, val, payload, value }: SelectData) {
    const selected = this.selectedFilter
    if (type === FilterType.Checkbox) {
      this.setSelectedFilterCheckbox({ code, type, val, payload, value })
    } else if (type === FilterType.Range || type === FilterType.Price) {
      this.setSelectedFilter({
        ...selected,
        [code]: val
      })
    } else if (type === FilterType.FlagBoolean || type === FilterType.FlagNumeric) {
      if (!val) {
        await this.clearByCode(code)
      } else {
        this.setSelectedFilter({
          ...selected,
          [code]: val
        })
      }
    }

    await this.applyFilter()
  }

  @Action({ rawError: config.rawError })
  setSelectedFilterCheckbox({ code, val, payload, value }: SelectData) {
    const values = this.selectedFilter[code]
    const tempVal = value ?? val.toString()
    if (value) {
      this._filterLabels[value] = val.toString()
    }
    if (Array.isArray(values)) {
      const tempValues = [...values]
      if (payload && !tempValues.includes(tempVal)) {
        tempValues.push(tempVal)
      } else if (!payload && tempValues.includes(tempVal)) {
        tempValues.splice(tempValues.indexOf(tempVal), 1)
      }
      if (!tempValues.length) {
        const tempFilters = this.selectedFilter
        delete tempFilters[code]
        this.setSelectedFilter({
          ...tempFilters
        })
      } else {
        this.setSelectedFilter({
          ...this.selectedFilter,
          [code]: tempValues
        })
      }
    } else if (payload === true) {
      this.setSelectedFilter({
        ...this.selectedFilter,
        [code]: [tempVal]
      })
    }
  }

  get list() {
    return this._list
  }

  get selectedFilter() {
    return this._selectedFilter
  }

  get queryFilter() {
    return this._queryFilter
  }

  get loading() {
    return this._loading
  }
}
