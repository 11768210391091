import VueClickOutside from 'vue-click-outside'
import Vue from 'vue'

Vue.directive('VueClickOutside', VueClickOutside)

let handleClickOutside
Vue.directive('clickable', {
  bind (el, binding, vnode) {
    handleClickOutside = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false

      // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
      if (exclude) {
        exclude.forEach((refName) => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
      }

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        handler(e)
      }
    }
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
  },

  unbind () {
    document.removeEventListener('click', handleClickOutside)
    document.removeEventListener('touchstart', handleClickOutside)
  }
})
