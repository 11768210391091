import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import NotificationItem from '~/types/NotificationItem'

@Module({
  name: 'notification',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _notificationQueue: NotificationItem[] = []
  _notificationQueueServer: NotificationItem[] = []

  @Mutation
  addNotification (item: NotificationItem) {
    if (process.browser) {
      this._notificationQueue.push({
        ...item,
        time: performance.now(),
        id: Math.random() * (100000 - 0) + 0
      })
    } else {
      this._notificationQueueServer.push({
        ...item,
        time: 0,
        id: Math.random() * (100000 - 0) + 0
      })
    }
  }

  @Mutation
  remove (id?: number) {
    let index = 0
    if (id) {
      index = this._notificationQueue.findIndex(e => e.id === id)
    }
    this._notificationQueue.splice(index, 1)
  }

  @Mutation
  clearNotificationServer () {
    this._notificationQueueServer = []
  }

  get notificationQueue (): NotificationItem[] {
    return this._notificationQueue
  }

  get notificationQueueServer (): NotificationItem[] {
    return this._notificationQueueServer
  }
}
