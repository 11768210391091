import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { DeliveryCondition, FlatRegionItem, Region, RegionItem } from '~/types/api/Region'
import config from '~/utils/config'
import BasketRegionCount from '~/types/api/BasketRegionCount'
import s from '~/utils/s'

@Module({
  name: 'regions',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _regions: Region[] = []
  _regionCode: string = ''
  _sectionCode: string = ''
  _sectionId: string = ''
  _regionId: string = ''
  _basketCounts: BasketRegionCount[] = []
  _choiceCity = false

  @Mutation
  setChoiceCity (state: boolean) {
    this._choiceCity = state
  }

  @Mutation
  setRegions (regions: Region[]) {
    this._regions = regions
  }

  @Mutation
  setRegionCode (code: string) {
    this._regionCode = code
  }

  @Mutation
  setSectionId (id: string) {
    this._sectionId = id
  }

  @Mutation
  setSetcionCode (code: string) {
    this._sectionCode = code
  }

  @Mutation
  setBasketCounts (items: BasketRegionCount[]) {
    this._basketCounts = items
  }

  @Mutation
  setRegionId (id: string) {
    this._regionId = id
  }

  @Action({ rawError: config.rawError })
  async fillRegions () {
    const regions = await this.store.$api.getRegions()
    if (regions && regions.code === 200 && regions.data) {
      this.setRegions(regions.data)
    }
  }

  @Action({ rawError: config.rawError })
  async fillBasketRegionCounts () {
    const result = await this.store.$api.getBasketRegionCounts()
    if (result.code === 200 && result.data) {
      this.setBasketCounts(result.data)
    } else {
      this.setBasketCounts([])
    }
  }

  @Action({ rawError: config.rawError })
  setRegionByCode (code: string) {
    const region = this.regions?.find(e => e.code === code)
    if (region) {
      if (region.isActive) {
        s(this.store).general.setRegionClosed(false)
      } else {
        s(this.store).general.setRegionClosed(true)
      }
      this.setRegionCode(code)
      this.setRegionId(region.id)
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  @Action({ rawError: config.rawError })
  setRegionByDomain (subdomain: string) {
    const region = this.regions?.find(e => e.subdomain === subdomain)
    if (region) {
      if (region.isActive) {
        s(this.store).general.setRegionClosed(false)
      } else {
        s(this.store).general.setRegionClosed(true)
      }
      this.setRegionCode(region.code)
      this.setRegionId(region.id)
    } else {
      s(this.store).general.redirectFatal()
    }
  }

  @Action({ rawError: config.rawError })
  async setSectionByCode (code: string) {
    const section = this.region?.items?.find(e => e.code === code)
    if (section) {
      this.setSetcionCode(code)
      this.setSectionId(section.sectionId)
      if (section.opened) {
        await s(this.store).general.fillData()
        s(this.store).general.setSectionClosed(false)
      } else {
        s(this.store).general.setSectionClosed(true)
      }
    } else {
      await this.setDefaultSection()
    }
  }

  @Action({ rawError: config.rawError })
  setDefaultRegion () {
    const region = this.regions?.find(e => e.subdomain === '' || e.name === 'Красноярск')
    if (region) {
      if (region.isActive) {
        s(this.store).general.setRegionClosed(false)
      } else {
        s(this.store).general.setRegionClosed(true)
      }
      this.setRegionCode(region.code)
      this.setRegionId(region.id)
    } else {
      s(this.store).general.redirectFatal()
    }
  }

  @Action({ rawError: config.rawError })
  async setDefaultSection () {
    const section = this.region?.items.find(e => e.default)
    if (section) {
      this.setSetcionCode(section.code)
      this.setSectionId(section.sectionId)
      if (section.opened) {
        await s(this.store).general.fillData()
        s(this.store).general.setSectionClosed(false)
      } else {
        s(this.store).general.setSectionClosed(true)
      }
    }
  }

  @Action({ rawError: config.rawError })
  async redirectRegionByCode (code: string) {
    const target = this.regions.find(e => e.code === code)
    if (target) {
      let targetLocation = window.location.protocol + '//'
      if (target.subdomain !== '') {
        targetLocation += target.subdomain + '.'
      }
      targetLocation += s(this.store).general.host
      if (window.location.port) {
        targetLocation += ':' + window.location.port
      }

      targetLocation = await this.checkGeneralPage({ target, path: targetLocation })
      s(this.store).general.enableLoader()
      window.location.href = targetLocation
    }
  }

  @Action({ rawError: config.rawError })
  redirectBasketById (regionId: string) {
    const targetRegion = this.flatRegions.find(e => e.id === regionId)
    if (targetRegion) {
      let targetLocation = window.location.protocol + '//'
      if (targetRegion.subdomain !== '') {
        targetLocation += targetRegion.subdomain + '.'
      }
      targetLocation += s(this.store).general.host + '/' + targetRegion.code + '/basket'
      window.location.href = targetLocation
    }
  }

  /**
   * если мы на странице корзины или оформления заказа модифицируем адрес перехода под новый регион
   * @param param0
   */
  @Action({ rawError: config.rawError })
  checkGeneralPage ({ target, path }: {
    target: Region,
    path: string
  }) {
    const section = target.items.find(e => e.code === this.section?.code)

    if (section) {
      path = path + '/' + section.code
      if (this.store.$router.currentRoute.name === 'region-basket' || this.store.$router.currentRoute.name === 'region-order') {
        path = path + '/basket/'
      }
    }
    return path
  }

  @Action({ rawError: config.rawError })
  redirectSctionByCode (code: string) {
    const router = this.store.$router
    const target = this.region?.items.find(e => e.code === code)
    if (router && target) {
      let routeName = router.currentRoute.name ?? ''
      if (routeName === 'index') {
        routeName = 'region'
      } else if (!/region/g.test(routeName)) {
        routeName = `region-${routeName}`
      }
      router.push({
        name: routeName,
        params: {
          ...router.currentRoute.params,
          region: target.code
        }
      })
    }
  }

  get choiceCity (): boolean {
    return this._choiceCity
  }

  get regions (): Region[] {
    return this._regions
  }

  get regionCode (): string {
    return this._regionCode
  }

  get regionId (): string {
    return this._regionId
  }

  get sectionCode (): string {
    return this._sectionCode
  }

  get region (): Region | undefined {
    return this.regions.find(e => e.code === this.regionCode)
  }

  get section (): RegionItem | undefined {
    return this.region?.items.find(e => e.code === this.sectionCode)
  }

  get basketCounts (): BasketRegionCount[] {
    return this._basketCounts
  }

  get subdomain (): string {
    return this.region?.subdomain ?? ''
  }

  get phone (): string {
    if (!this.section) {
      return ''
    }

    return this.section.phone ?? ''
  }

  get phoneLink (): string {
    if (!this.section) {
      return ''
    }

    return 'tel:' + this.section.phone?.replace(/[^\d+]/g, '')
  }

  get email (): string {
    if (!this.section) {
      return ''
    }

    return this.section.email ?? ''
  }

  get emailLink (): string {
    if (!this.section) {
      return ''
    }

    return 'mailto:' + this.section.email
  }

  get flatRegions (): FlatRegionItem[] {
    return this.regions.reduce<FlatRegionItem[]>((p, c) => [
      ...p,
      ...c.items.map(e => ({
        id: e.id,
        code: e.code,
        subdomain: c.subdomain,
        regionName: c.name
      }))
    ], [])
  }

  get sectionId (): string {
    return this._sectionId
  }

  get currentRegionSectionDeliveryConditions (): DeliveryCondition {
    let cond = this.region?.deliveryConditions.find(dc => dc.sectionId === this.sectionId)

    if (!cond) {
      cond = {
        sectionId: this.sectionId,
        recommendDeliveryUpTo: Number.MAX_SAFE_INTEGER,
        deliveryAvailable: false
      }
    }
    return cond
  }
}
