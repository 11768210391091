import { Vue, Component, Prop } from 'vue-property-decorator'
import * as animate from 'gsap'
import FilterItem from '~/types/api/filter/FilterItem'
import FilterStore from '~/store/filter'
import Checkbox from '~/components/element/Checkbox'
import ArrowSvg from '~/assets/svg/link-arrow.svg?inline'

interface CheckboxData {
  selected: boolean,
  name: string,
  text: string
  value: string;
}

@Component
export default class FilterCheckoxList extends Vue {
  isShow: boolean = true
  maxShortView: number = 5
  search: string = ''
  isLoad: boolean = false

  created () {
    this.isLoad = true
  }

  @Prop({ required: true, type: Boolean })
  readonly isShowMore!: boolean

  @Prop({ required: true, type: Object })
  readonly item!: FilterItem

  @Prop({ required: true, type: Object })
  readonly store!: FilterStore

  enter (el: any, done: any) {
    animate.gsap.to(el, {
      opacity: 1,
      height: 'auto',
      onComplete: done,
      ease: animate.Linear.easeNone
    }).delay(0).duration(0.3)
  }

  beforeEnter (el: any, done: any) {
    el.style.opacity = 0
    el.style.height = 0
  }

  leave (el: any, done: any) {
    animate.gsap.to(el, {
      opacity: 0,
      height: 0,
      onComplete: done,
      ease: animate.Linear.easeNone
    }).delay(0.1).duration(0.3)
  }

  get values (): CheckboxData[] {
    if (this.item.values && this.item.values.length) {
      return this.item.values.map(e => ({
        selected: this.getSelectStatus(e),
        name: `${this.item.code}_${e}`,
        text: e,
        value: e
      }))
    } else if (this.item.valuesId && this.item.valuesId.length) {
      return this.item.valuesId.map(e => ({
        selected: this.getSelectStatus(e.value),
        name: `${this.item.code}_${e.value}`,
        text: e.title,
        value: e.value
      }))
    } else {
      return []
    }
  }

  get sortedList () {
    return this.values
      .sort((a, b) => {
        if (a.text > b.text) {
          return 1
        }
        if (b.text > a.text) {
          return -1
        }
        return 0
      })
      .sort((a, b) => {
        if (a.selected && b.selected || !a.selected && !b.selected) {
          return 0
        } else if (a.selected) {
          return -1
        } else {
          return 1
        }
      })
  }

  get searchList () {
    let list = this.sortedList
    if (this.search && this.search.length > 1) {
      list = this.sortedList.filter(e => e.text.toLowerCase().includes(this.search.toLowerCase()))
    }

    if (this.isShowMore) {
      return list
    } else {
      return list.slice(0, this.maxShortView)
    }
  }

  get isLongList () {
    return this.sortedList.length > this.maxShortView
  }

  showMore () {
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = this.$el.getBoundingClientRect()
    const offset = elemRect.top - bodyRect.top
    this.$emit('open', {
      isOpen: !this.isShowMore,
      offset
    })
  }

  toggle () {
    this.isShow = !this.isShow
  }

  getSelectStatus (val: string) {
    const values = this.store.selectedFilter[this.item.code]
    return Array.isArray(values) && values.includes(val)
  }

  async change (val: boolean, text: string, value: string) {
    await this.store.setValue({
      code: this.item.code,
      type: this.item.view,
      val: text,
      payload: val,
      value
    })
  }

  render () {
    return (
      <div class={{
        'filter-checkox-list': true,
        'filter-checkox-list_open': this.isShowMore,
        'filter-checkox-list_long': this.isLongList
      }}>
        <div class="filter-checkox-list__header" onClick={this.toggle}>
          <div class="filter-checkox-list__header-name">
            {this.item.name}
          </div>
          <div {...{
            class: {
              'filter-checkox-list__header-arrow': true,
              'filter-checkox-list__header-arrow_open': this.isShow
            }
          }} >
            <ArrowSvg />
          </div>
        </div>
        <transition {...{
          props: {
            name: 'filter-checkox-list__animate',
            css: false
          },
          on: {
            'before-enter': this.beforeEnter,
            enter: this.enter,
            leave: this.leave
          }
        }}>
          {this.isShow &&
          <div class="filter-checkox-list__content">
            {this.isLongList && this.isShowMore &&
              <div class="filter-checkox-list__search">
                <input type="text" v-model={this.search} name={`searcg_${this.item.code}`} placeholder="Поиск" />
              </div>
            }
            <div class="filter-checkox-list__list-wrapper">
              <div class="filter-checkox-list__list">
                {this.searchList.map((e) => {
                  return (
                    <div key={e.name} class="filter-checkox-list__item">
                      <Checkbox {...{
                        props: {
                          value: e.selected,
                          name: e.name,
                          label: e.name,
                          modifiers: ['filter']
                        },
                        on: {
                          change: async (v: boolean) => {
                            return await this.change(v, e.text, e.value)
                          }
                        },
                        scopedSlots: {
                          textPayload: () => e.text
                        }
                      }} />
                    </div>
                  )
                })}
              </div>
            </div>
            {this.isLongList &&
              <div class="filter-checkox-list__show-more" onClick={this.showMore}>
                {this.isShowMore ? 'Скрыть' : 'Показать все'}
              </div>
            }
          </div>
          }
        </transition>
      </div>
    )
  }
}
