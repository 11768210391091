import { Context, Plugin } from '@nuxt/types'
import { getCookieClient, removeCookie, setCookie } from '~/utils/cookie'

const firebase: Plugin = (context: Context) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  if (!isSafari) {
    const fcmKey = 'fcmToken'
    const oldFcmToken = getCookieClient(fcmKey)

    if (typeof Notification !== 'undefined') {
      const pushDenied = Notification.permission === 'denied'

      const getToken = () => {
        context.$fire.messaging.getToken().then(async (fcmToken) => {
          if (fcmToken) {
            setCookie(context, fcmKey, fcmToken)

            if (oldFcmToken !== fcmToken) {
              await context.$api.addFirebaseToken(fcmToken)
            }
          } else {
            removeCookie(context, fcmKey)
          }
        })
      }

      if (pushDenied) {
        removeCookie(context, fcmKey)
      } else if (!oldFcmToken) {
        getToken()
      }
    }
  }
}

export default firebase
