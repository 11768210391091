import { Vue, Component } from 'vue-property-decorator'
import ArrowSvg from '~/assets/svg/personal-menu-arrow.svg?inline'
import LinkItem from '~/types/LinkItem'
import s from '~/utils/s'
import * as itemsMenu from '~/utils/config/itemsMenu'
import { MetrikaLocalCodes } from '~/types/api/MetrikaCode'

@Component
export default class MenuList extends Vue {
  get menu (): LinkItem[] {
    return [...itemsMenu.helpMenu(this.$store), ...itemsMenu.aboutMenu(this.$store)]
  }

  async openPopup (event: MouseEvent) {
    event.stopPropagation()
    s(this.$store).general.showPopup('CallbackPopup')
    this.$reachGoal(await s(this.$store).seo.getMetrikaCode(MetrikaLocalCodes.CONTACT_US_STEP_1))
  }

  render () {
    return (
      <div class="general-list-menu">
        <div class="general-list-menu__container">
          {this.menu.map(e => (
            <div class="general-list-menu__item-wrapper">
              <nuxt-link to={e.location} class="general-list-menu__item" {...{
                nativeOn: {
                  click: () => s(this.$store).general.closeGeneralList()
                }
              }}>
                <div class="general-list-menu__item-text">
                  {e.title}
                </div>
                <div class="general-list-menu__item-arrow">
                  <ArrowSvg />
                </div>
              </nuxt-link>
            </div>
          ))}
          {/* <div class="general-list-menu__item-wrapper">
            <div class="general-list-menu__item" onClick={this.openPopup}>
              <div class="general-list-menu__item-text">
                Связаться с нами
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}
