import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import LogoShortSvg from '~/assets/svg/logo-short.svg?inline'
import TextInput from '~/components/element/TextInput'
import NotificationType from '~/types/enum/NotificationType'
import VerificationOperation from '~/types/api/options/VerificationOperation'
import s from '~/utils/s'
import { Route } from 'vue-router'

export enum Step {
  Phone,
  Code
}

interface Form {
  setErrors: (errors: { [key: string]: string[] }) => void;
  handleSubmit: (fn: () => void) => void;
}

@Component({
  components: {
    ValidationObserver
  }
})
export default class SmsAuthorization extends Vue {
  timeLeft: number = 0
  step: Step = 0;

  @Prop({ type: Boolean, default: false })
  phoneChange!: boolean
  isDisableButton = false

  phone: string = '';
  code: string = '';

  @Prop({ type: Function })
  readonly onAuthSuccess?: Function;

  get clearPhone () {
    return this.phone.replace(/^(\+)|[^\d+]/g, '')
  }

  get clearCode () {
    return this.code.replace(/\s/g, '')
  }

  @Ref()
  readonly form!: Form;

  async mounted () {
    await s(this.$store).general.getCodeGenerationCooldownTime()
    await this.$recaptcha.init()
    const timerEnd = this.$cookies.get('sms_timer')
    if (timerEnd) {
      const now = new Date().getTime()
      const difference = timerEnd - now
      if (difference > 0) {
        this.timeLeft = difference % 1000
        this.runTimer()
      } else {
        this.$cookies.remove('sms_timer')
      }
    }
    this.$nextTick(() => this.setFocusOnInput('phone'))
  }

  created() {
    this.$router.afterEach((to: Route, from: Route) => {
      if (from.path === '/personal/login/') {
        this.isDisableButton = false;
      }
    });
  }

  setFocusOnInput (key: string) {
    const input: any = document.getElementsByName(key)[0]
    if (input) {
      input.focus()
    }
  }

  async nextStep () {
    s(this.$store).general.enableLoader()
    await this.sendAuthCode()
    s(this.$store).general.disableLoader()
    this.$nextTick(() => this.setFocusOnInput('code'))
  }

  async smsLogin () {
    if (this.phoneChange) {
      const result = await this.$api.confirmPhoneNumber({
        code: this.clearCode
      })

      if (result.code === 200) {
        s(this.$store).auth.setUserPhoneNumber(result.data!)
        s(this.$store).notification.addNotification({
          type: NotificationType.Success,
          text: 'Вы успешно сменили номер телефона!'
        })

        s(this.$store).general.closePopup()
      } else if (result.code === 422) {
        s(this.$store).notification.addNotification({
          text:
            'Вы ввели неверный код. Проверьте 4 последние цифры входящего звонка и введите код еще раз',
          type: NotificationType.Error
        })
      } else {
        s(this.$store).notification.addNotification({
          type: NotificationType.Error,
          text: 'Неизвестная ошибка'
        })
      }
    } else {
      const result = await this.$api.smsAuth({
        phone: this.clearPhone,
        code: this.clearCode,
        tokenBitrix: s(this.$store).auth.token,
        regionId: s(this.$store).regions.regionId,
        sectionId: s(this.$store).regions.sectionId
      })

      if (result.code === 200 && result.data) {
        this.isDisableButton = true;
        s(this.$store).auth.setSfTokenAction({ token: result.data.tokens.jwt, refreshToken: result.data.tokens.rt })
        s(this.$store).auth.setTokenAction(result.data!.tokens.tokenBitrix!)
        await s(this.$store).auth.fillUser()
        s(this.$store).notification.addNotification({
          type: NotificationType.Success,
          text: 'Вы успешно авторизовались'
        })
        if (this.onAuthSuccess) {
          this.onAuthSuccess()
        } else {
          await this.$store.$router.push({ path: '/personal/' })
        }
      }

      if (result.code === 422) {
        s(this.$store).notification.addNotification({
          text:
            'Вы ввели неверный код. Проверьте 4 последние цифры входящего звонка и введите код еще раз',
          type: NotificationType.Error
        })
      }
    }
  }

  async sendAuthCode () {
    let token = ''
    if (!s(this.$store).general.isDevelopment) {
      try {
        token = await this.$recaptcha.execute(VerificationOperation.SmsAuth)
      } catch (error) {
        s(this.$store).notification.addNotification({
          text: 'Капча не прошла проверку',
          type: NotificationType.Error
        })
        return
      }
    }

    const result = await this.$api.verificationPhone({
      phone: this.clearPhone,
      bitrixToken: s(this.$store).auth.token,
      token,
      phoneChange: this.phoneChange,
      userId: s(this.$store).auth.user?.id
    })

    if (result.code === 200) {
      this.step = Step.Code
      this.$cookies.set('sms_timer', new Date().getTime() + s(this.$store).general.codeGenerationCooldownTime)
      this.runTimer()
      if (process.env.NODE_ENV === 'local') {
        this.code = result.data?.code ?? ''
      }
      s(this.$store).notification.addNotification({
        type: NotificationType.Success,
        text: 'Ожидайте звонок на номер: ' + this.phone
      })
    } else {
      s(this.$store).notification.addNotification({
        text: result.error
          ? result.error.description
          : 'Не удалось совершить звонок',
        type: NotificationType.Error
      })
    }
  }

  renderPhone () {
    return (
      <TextInput
        key="phone"
        {...{
          props: {
            name: 'phone',
            label: 'Телефон',
            value: this.phone,
            rules:
              'required|regex:^(\\+7\\s\\(\\9\\d{2}\\)\\s\\d{3}-\\d{2}-\\d{2})$',
            mask: '+7 (###) ###-##-##',
            type: 'tel',
            placeholder: '+7 (999) 999-99-99'
          },
          on: {
            input: (v: string) => {
              this.phone = v
            }
          }
        }}
      />
    )
  }

  renderCode () {
    return (
      <div>
        <div class="sms-auth-form__description">Введите последние 4 цифры входящего номера или код из уведомления</div>
        <TextInput
          key="code"
          {...{
            props: {
              name: 'code',
              label: 'Код',
              value: this.code,
              rules: 'required|regex:^(\\d{2}\\s\\d{2})$',
              mask: '## ##',
              type: 'text',
              placeholder: '__ __',
              customName: 'Код'
            },
            on: {
              input: (v: string) => (this.code = v)
            }
          }}
        />
      </div>
    )
  }

  renderResend () {
    if (this.timeLeft > 0) {
      return (
        <div class="sms-auth-form__resend-code-countdown">
          Запросить код повторно через {this.timeLeft}
        </div>
      )
    } else {
      return (
        <div class="sms-auth-form__resend-code" onClick={() => this.sendAuthCode()}>
          Запросить код повторно
        </div>
      )
    }
  }

  runTimer () {
    setInterval(() => {
      const now = new Date().getTime()
      const end = this.$cookies.get('sms_timer')

      if (!end) {
        return
      }

      if (now - 1000 <= parseInt(end)) {
        const nowSeconds = Math.floor(now / 1000)
        const endSeconds = Math.floor(parseInt(end) / 1000)
        const difference = endSeconds - nowSeconds
        if (difference < 0) {
          this.timeLeft = 0
          this.$cookies.remove('sms_timer')
        } else {
          this.timeLeft = difference
        }
      }
    }, 200)
  }

  renderStepData () {
    return (
      <validation-observer ref="form">
        {({ handleSubmit }: any) => (
          <div class="sms-auth-form__auth-data" {...{
            on: {
              keyup: (e: KeyboardEvent) => e.key === 'Enter' && handleSubmit(this.nextStep)
            }
          }}>
            {this.renderPhone()}
            <div class="sms-auth-form__step-data-form-submit button-text"
              onClick={() => {
                if (this.timeLeft <= 0) {
                  handleSubmit(this.nextStep)
                }
              }}>
              {this.timeLeft > 0 ? 'Повторный звонок через ' + this.timeLeft : 'Получить код'}
            </div>
          </div>

        )}
      </validation-observer>
    )
  }

  renderStepSmsAuth () {
    return (
      <validation-observer ref="form">
        {({ handleSubmit }: any) => (
          <div class="sms-auth-form__auth-data" {...{
            on: {
              keyup: (e: KeyboardEvent) => e.key === 'Enter' && handleSubmit(this.smsLogin)
            }
          }}>
            {this.renderCode()}
            {!this.phoneChange &&
              <div class="sms-auth-form__change-step" onClick={(event: MouseEvent) => {
                event.stopPropagation()
                this.step = Step.Phone
              }}>
                Изменить номер</div>}
            {!this.phoneChange && this.renderResend()}
            <div class={{
              "sms-auth-form__step-data-form-submit button-text": true,
              "common-switch__disabled": this.isDisableButton || s(this.$store).general.isLoaded
            }}
              onClick={() => handleSubmit(this.smsLogin)
              }>
              {this.phoneChange ? 'Подтвердить' : 'Войти'}
            </div>
          </div>
        )}
      </validation-observer>
    )
  }

  renderStep () {
    switch (this.step) {
      case Step.Phone:
        return this.renderStepData()
      case Step.Code:
        return this.renderStepSmsAuth()
    }
  }

  render () {
    const text = this.step === Step.Phone ? 'Авторизация по номеру телефона' : 'Подтверждение'
    return (
      <div class="sms-auth-form">
        <div class="sms-auth-form__content">
          <div class="sms-auth-form__content-logo-top">
            <nuxt-link to="/">
              <LogoShortSvg/>
            </nuxt-link>
          </div>
          <h1 class="sms-auth-form__content-header">
            {this.phoneChange ? 'Смена номера телефона' : text}
          </h1>
          {this.renderStep()}
        </div>
      </div>
    )
  }
}
