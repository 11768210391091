import { Vue, Component } from 'vue-property-decorator'
import LocationSvg from '~/assets/svg/mobile-menu-location.svg?inline'
import { Region } from '~/types/api/Region'
import s from '~/utils/s'
import loc from '~/utils/loc'

@Component
export default class LocationButton extends Vue {
  get selectedParentRegion (): Region | undefined {
    return s(this.$store).regions.region
  }

  openPopup () {
    this.$router.push(loc(this.$store, { name: 'pharmacies' }))
  }

  render () {
    return (
      <div onClick={this.openPopup} class={{
        'mobile-menu__item-location': true,
        'mobile-menu__item-location_selected': s(this.$store).general.popupName !== null
      }}>
        <LocationSvg />
      </div>
    )
  }
}
