import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import config from '~/utils/config'
import s from '~/utils/s'
import ListResponse from '~/types/api/ListResponse'
import Review from '~/types/api/Review'
import Result from '~/types/api/Result'
import BooleanStatus from '~/types/api/BooleanStatus'

type Dictionary<T> = { [key: string]: T }

@Module({
  name: 'reviewsPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _reviews: ListResponse<Review> = {
    count: 0,
    items: []
  }

  _userReview: Review = {
    id: 0,
    text: '',
    rating: 0
  }

  _page: number = 0
  _limit: number = 6

  _isLoadMore: boolean = false

  @Mutation
  setReviews (reviews: ListResponse<Review>) {
    this._reviews = reviews
  }

  @Mutation
  setUserReview (review: Review) {
    this._userReview = review
  }

  @Mutation
  setPage (page: number) {
    this._page = page
  }

  @Mutation
  enableLoadMore (enable: boolean = true) {
    this._isLoadMore = enable
  }

  @Action({ rawError: config.rawError })
  async showPopup () {
    const { isAuth } = s(this.store).auth
    const popupType = isAuth ? 'ReviewPopup' : 'AuthPopup'

    await s(this.store).general.showPopup(popupType)
  }

  @Action({ rawError: config.rawError })
  async addReview (review: Review): Promise<Result<BooleanStatus>> {
    const result = await this.store.$api.addReview({
      regionId: s(this.store).regions.regionId ?? 0,
      review
    })

    this.setUserReview({
      ...this.userReview,
      ...review
    })

    return result
  }

  @Action({ rawError: config.rawError })
  async updateReview (review: Review): Promise<Result<BooleanStatus>> {
    const result = await this.store.$api.updateReview({
      regionId: s(this.store).regions.regionId ?? 0,
      review
    })

    this.setUserReview({
      ...this.userReview,
      ...review
    })

    return result
  }

  @Action({ rawError: config.rawError })
  async fetchUserReview (_route?: Route): Promise<void> {
    const { id: productId } = s(this.store).productPage
    const { isAuth } = s(this.store).auth

    if (isAuth && productId) {
      const result = await this.store.$api.getReviewByUser({
        regionId: s(this.store).regions.regionId ?? 0,
        productId
      })

      if (result.code === 200 && result.data) {
        this.setUserReview(result.data)
      } else {
        this.setUserReview({
          id: 0,
          rating: 0,
          text: ''
        })
      }
    }
  }

  @Action({ rawError: config.rawError })
  async fetchReviews (route?: Route): Promise<void> {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const routeProductCode = await this.parseProductCode(route.path)
    const query = route.query
    const page = await this.getPageNumber(query)
    const limit = this._limit
    const offset = page * limit
    const { code: productCode } = s(this.store).productPage
    const shouldLoadProduct = !productCode || (productCode !== routeProductCode)

    s(this.store).general.enableLoader()

    if (routeProductCode && shouldLoadProduct) {
      await this.loadProduct(routeProductCode)
    }

    const { id: productId } = s(this.store).productPage

    if (productId) {
      const result = await this.store.$api.getReviews({
        regionId: s(this.store).regions.regionId ?? 0,
        productId,
        limit,
        offset
      })

      this.parseResult(result)
      this.setPage(page)
    }

    s(this.store).general.disableLoader()

    this.enableLoadMore(false)
  }

  @Action({ rawError: config.rawError })
  async loadProduct (productCode: string): Promise<boolean> {
    const result = await this.store.$api.getProduct({
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId,
      code: productCode
    })

    if (result.code === 200 && result.data) {
      s(this.store).productPage.setProduct(result.data)

      return true
    }

    return false
  }

  @Action({ rawError: config.rawError })
  private parseProductCode (url: string): string | null {
    const folders = url.split('/').filter(v => !!v)

    if (folders.length < 2 || folders.pop() !== 'reviews') {
      return null
    }

    return folders.pop() || null
  }

  @Action({ rawError: config.rawError })
  private parseResult (result: Result<ListResponse<Review>>): void {
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        this.setReviews({
          ...this.reviews,
          items: [...this._reviews.items, ...result.data.items]
        })

        this.scrollToTop()
      } else {
        this.setReviews(result.data)
      }
    } else {
      this.setReviews({ count: 0, items: [] })
    }
  }

  @Action({ rawError: config.rawError })
  private scrollToTop (): void {
    const offsetScroll = window.scrollY

    setTimeout(() => window.scroll({
      top: offsetScroll,
      left: 0
    }), 0)
  }

  @Action({ rawError: config.rawError })
  private getPageNumber (query: Dictionary<string | (string | null)[]>): number {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  get reviews (): ListResponse<Review> {
    return this._reviews
  }

  get userReview (): Review {
    return this._userReview
  }

  get page (): number {
    return this._page
  }

  get limit (): number {
    return this._limit
  }
}
