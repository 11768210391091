import { Vue, Component, Prop } from 'vue-property-decorator'
import { NuxtError } from '@nuxt/types'
import BgSvg from '~/assets/svg/404-bg.svg?inline'
import ErrorSvg from '~/assets/svg/500.svg?inline'

@Component
export default class UnknownError extends Vue {
  @Prop({ required: true, type: Object })
  readonly error!: NuxtError

  render () {
    return (
      <div class="unknown-error-page">
        <div class="unknown-error-page__bg">
          <BgSvg />
        </div>
        <div class="unknown-error-page__content">
          <div class="unknown-error-page__header-wrapper">
            <div class="unknown-error-page__header">
              {this.error.message ? this.error.message : 'Внутреняя ошибка сервера'}
            </div>
          </div>
          <div class="unknown-error-page__main-link-wrapper">
            <nuxt-link to="/" class="unknown-error-page__main-link button-text">
              На главную
            </nuxt-link>
          </div>
          <div class="unknown-error-page__image">
            <ErrorSvg />
          </div>
        </div>
      </div>
    )
  }
}
