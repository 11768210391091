import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import config from '~/utils/config'
import s from '~/utils/s'
import CategoriesResponse from '~/types/api/categories/CategoriesResponse'
import Result from '~/types/api/Result'

@Module({
  name: 'categoriesPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _list: CategoriesResponse = {
    sectionId: '',
    id: '',
    name: '',
    items: [],
    filterFirstLetters: []
  }

  _isLoaded: boolean = false

  @Mutation
  setList (list: CategoriesResponse) {
    this._list = list
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Action({ rawError: config.rawError })
  async loadData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }

    const query = route.query
    const params = route.params

    let id: string = ''
    let letter: string = ''

    if (typeof params.id === 'string') {
      id = params.id
    }

    if (typeof query.letter === 'string') {
      letter = query.letter
    }

    if (!id) {
      s(this.store).general.redirectNotFound()
    }

    const sectionId = s(this.store).regions.section?.sectionId!

    let request = this.store.$api.fetchCategoriesByParentId({ id, sectionId })
    if (letter.length > 0) {
      request = this.store.$api.fetchCategoriesByLetter({ id, letter, sectionId })
    }

    const result = await request

    this.resultParse(result)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  resultParse (result: Result<CategoriesResponse>) {
    if (result.code === 200 && result.data) {
      this.setList(result.data)
    } else {
      this.setList({
        sectionId: '',
        id: '',
        name: '',
        filterFirstLetters: [],
        items: []
      })
    }
  }

  get list (): CategoriesResponse {
    return this._list
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }
}
