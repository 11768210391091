import { Store } from 'vuex'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import ErrorResponse from '~/types/api/ErrorResponse'
import TokenErrorHandler from '~/utils/api/errorHandler/TokenErrorHandler'
import ErrorHandler from '~/utils/api/errorHandler/ErrorHandler'
import UnknownErrorHandler from '~/utils/api/errorHandler/UnknownErrorHandler'
import FatalErrorHandler from '~/utils/api/errorHandler/FatalErrorHandler'
import ClosedSiteErrorHandler from '~/utils/api/errorHandler/ClosedSiteErrorHandler'
import s from '~/utils/s'

export default class ManagerErrorHandler {
  private readonly store: Store<any>
  private readonly axios?: NuxtAxiosInstance

  constructor (store: Store<any>, axios?: NuxtAxiosInstance) {
    this.store = store
    this.axios = axios
  }

  public async check (codes: number[], error: ErrorResponse, callback: any, ...params: any): Promise<any> {
    codes.push(503)
    const code = error?.response?.status ?? 422

    if (code === 401 &&
      !error.response.config.url.includes('refresh-token') &&
      error.response.config.baseURL.includes(process.server ? (process.env.NUXT_ENV_API_URL_SF || 'http://api:9997/api')
        : (process.env.NUXT_ENV_API_BROWSER_URL_SF || 'http://localhost:9997/api'))) {
      if (s(this.store).auth.refreshToken.length) {
        await s(this.store).auth.refreshTokenAction()
        return await callback.apply(this, [...params])
      }
    }

    if ((!code || codes.includes(code)) && code !== 401) {
      const handler = this.create(code)
      return handler.check(callback, params)
    }

    return {
      code,
      error: {
        description: error.response?.data?.error?.message ?? error?.response?.data.error ?? error?.response?.data ?? error
      },
      fields: error?.response?.data?.fields
    }
  }

  private create (code: number):
    ErrorHandler {
    if (code === 420) {
      return new TokenErrorHandler(this.store)
    } else if (code >= 400 && code < 500) {
      return new UnknownErrorHandler(this.store)
    } else if (code === 503) {
      return new ClosedSiteErrorHandler(this.store)
    } else {
      return new FatalErrorHandler(this.store)
    }
  }
}
