import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import StarSvg from '~/assets/svg/star.svg?inline'
import StarFilledSvg from '~/assets/svg/star-product.svg?inline'
import RatingStars from '~/components/element/RatingStars'

@Component({
  components: {
    ValidationProvider
  }
})
export default class RatingInput extends Vue {
  innerValue: number|null = null;

  @Prop({ type: String, required: true })
  readonly label?: string;

  @Prop({ type: String, required: true })
  readonly name?: string;

  @Prop({ type: Number })
  readonly value?: number;

  @Prop({ type: String, default: "" })
  readonly rules?: string;

  @Watch('innerValue')
  innerValueChange (value: number) {
    this.$emit('input', value)
  }

  @Watch('value')
  valueChange (value: number) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  get hasValue(): boolean {
    return !!this.innerValue;
  }

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  changeRating (value: number) {
    this.innerValue = value
  }

  renderStar (rating: number) {
    return (
      <div class={{
        'rating-input__item': true,
        'rating-input__item_selected': rating === this.value
      }}
      onclick={() => this.changeRating(rating) }
      >
        <span class="rating-input__star rating-input__star_filled">
          <StarFilledSvg />
        </span>

        <span class="rating-input__star rating-input__star_unfilled">
          <StarSvg />
        </span>
      </div>
    )
  }

  renderInput () {
    return (
      <div class="rating-input">
        <input type="hidden" v-model={this.innerValue} name={this.name}/>

        {[...Array(RatingStars.maxValue)].map((v, i) => this.renderStar(RatingStars.maxValue - i))}
      </div>
    )
  }

  renderScopLabelPayload () {
    if (this.$scopedSlots.labelPayload !== undefined) {
      return this.$scopedSlots.labelPayload(this)
    }
  }

  render () {
    return (
      <validation-provider
        {...{
          props: {
            rules: this.rules,
            name: this.label,
            customMessages: ru.messages
          }
        }}
      >
        {({ errors, required }: any) => (
          <div
            {...{
              class: {
                'common-input': true,
                'common-input_required': required,
                'common-input_error': errors.length > 0
              }
            }}
          >
            <div class="common-input__name">
              <div class="common-input__name-text">{this.label}</div>
              <div class="common-input__name-payload">
                {this.renderScopLabelPayload()}
              </div>
            </div>
            {this.renderInput()}
            <div class="common-input__error">{errors[0]}</div>
          </div>
        )}
      </validation-provider>
    )
  }
}
