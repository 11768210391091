import { Vue, Component } from 'vue-property-decorator'
import FilterStore from '~/store/filter'
import FilterProduct from '~/components/block/filter/FilterProduct'
import s from '~/utils/s'

interface OffsetData {
  isOpen: boolean,
  offset: number
}

@Component
export default class FilterPopup extends Vue {
  isMounted: boolean = false
  isCreated: boolean = false
  offset: number = 0

  get filter (): FilterStore | undefined {
    const store: { [key: string]: FilterStore } = {
      'region-all': s(this.$store).categoryFilter,
      'region-wishlist': s(this.$store).wishlistFilter,
      'region-search': s(this.$store).searchFilter,
      'region-catalog-popular': s(this.$store).popularFilter,
      'region-catalog-sale': s(this.$store).saleFilter
    }

    if (this.$route.name && (this.isMounted || this.isCreated)) {
      return store[this.$route.name]
    }
  }

  created () {
    this.isCreated = true
  }

  mounted () {
    this.isMounted = true
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    if (s(this.$store).general.popupName === 'FilterPopup' && window.innerWidth > 1024) {
      s(this.$store).general.closePopup()
    }
  }

  toggle (event: OffsetData) {
    const popup: HTMLElement | null = document.querySelector('.popup')
    if (event.isOpen && popup) {
      popup.style.position = 'fixed'
      popup.style.top = `-${event.offset - 10}px`
      this.offset = event.offset
    } else if (!event.isOpen && popup) {
      popup.style.position = 'absolute'
      popup.style.top = '0'
      window.scrollTo(0, this.offset)
    }
  }

  render () {
    return (
      <div class="filter-popup">
        <div class="filter-popup__header">
          Фильтры
        </div>
        {this.filter &&
          <div class="filter-popup__container">
            <FilterProduct {...{
              props: {
                store: this.filter
              },
              on: {
                open: (e: OffsetData) => this.toggle(e)
              }
            }} />
          </div>
        }
      </div>
    )
  }
}
