import { Plugin } from '@nuxt/types'
import s from '~/utils/s'

const hourDifference = (previousDate: Date, currentDate = new Date()) => {
  return (currentDate.getTime() - previousDate.getTime()) / (3600 * 1000)
}

const getOrderForRating: Plugin = async (context) => {
  if (process.browser) {
    const lastOrderForRatingPopup = localStorage.getItem('orderForRatingLastPopup')
    if (!lastOrderForRatingPopup || hourDifference(new Date(parseInt(lastOrderForRatingPopup))) >= 24) {
      await s(context.store).orderRating.getOrderForRating()
      setTimeout(() => {
        if (s(context.store).orderRating.orderForRating) {
          localStorage.setItem('orderForRatingLastPopup', Date.now().toString())
          s(context.store).general.showPopup('OrderRatingPopup')
        }
      }, 1000)
    }
  }
}

export default getOrderForRating
