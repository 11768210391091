import { Vue, Component } from 'vue-property-decorator'
import PickupSelectorPopupDesktop from '~/components/general/popups/PickupSelectorPopupDesktop'
import PickupSelectorPopupMobile from '~/components/general/popups/PickupSelectorPopupMobile'

@Component
export default class PickupSelectorPopup extends Vue {
  isMobile: boolean = true

  mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    this.isMobile = window.innerWidth < 768
  }

  render () {
    if (this.isMobile) {
      return <PickupSelectorPopupMobile />
    } else {
      return <PickupSelectorPopupDesktop />
    }
  }
}
