import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import config from '~/utils/config'
import s from '~/utils/s'
import BannerOptions from '~/types/api/options/BannerOptions'
import Img from '~/types/api/Img'
import FilterOptions from '~/types/api/options/FilterOptions'

@Module({
  name: 'bannerPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // banner
  _id: string = ''
  _img?: Img = undefined
  _link?: string = undefined
  _description?: string = ''
  _descriptionHTML?: string = ''
  _title: string = ''
  _code: string = ''
  _categoryId?: string = undefined
  _productId?: string = undefined
  _productListId: string = ''
  _buttonText: string = ''
  _bannerText: string = ''
  _bannerPlainText: string = ''
  _requiredTextCode?: string = ''
  _button: string = ''
  _creativeTokenId: string = ''
  _name: string = ''
  queryFilter: any
  list: any

  // start banner mutation
  @Mutation
  setId(id: string) {
    this._id = id
  }

  @Mutation
  setImg(img?: Img) {
    this._img = img
  }

  @Mutation
  setTitle(title: string) {
    this._title = title
  }

  @Mutation
  setName(name: string) {
    this._name = name
  }

  @Mutation
  setCode(code: string) {
    this._code = code
  }

  @Mutation
  setProductListId(productListId: string) {
    this._productListId = productListId
  }

  @Mutation
  setBannerText(bannerText: string) {
    this._bannerText = bannerText
  }

  @Mutation
  setRequiredTextCode(requiredTextCode: string) {
    this._requiredTextCode = requiredTextCode
  }

  @Mutation
  setCreativeToken(token: string) {
    this._creativeTokenId = token
  }
  // end banner mutation

  @Action({ rawError: config.rawError })
  async loadData(route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    await this.loadBannerInfo(route)
  }

  getParser(filterItem: any) {
    throw new Error('Method not implemented.')
  }

  setSelectedFilter(options: FilterOptions) {
    throw new Error('Method not implemented.')
  }

  @Action({ rawError: config.rawError })
  async loadBannerInfo(route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const params = route.params
    let code = ''
    if (typeof params.code === 'string') {
      code = params.code
    }

    if (!code) {
      s(this.store).general.redirectNotFound()
    }

    const options: BannerOptions = {
      code,
      regionId: s(this.store).regions.regionId ?? 0
    }

    const result = await this.store.$api.getBanner(options)
    if (result.code === 200 && result.data) {
      this.setId(result.data.id)
      this.setImg(result.data.img)
      this.setTitle(result.data.title)
      this.setCode(result.data.code)
      this.setProductListId(result.data.productListId!)
      this.setBannerText(result.data.bannerText)
      this.setRequiredTextCode(result.data.requiredTextCode!)
      this.setName(result.data.name)
    }
  }

  // start banner props
  get id(): string {
    return this._id
  }

  get img(): Img | undefined {
    return this._img
  }

  get title(): string {
    return this._title
  }

  get code(): string {
    return this._code
  }

  get productListId(): string {
    return this._productListId
  }

  get bannerText(): string {
    return this._bannerText
  }

  get requiredTextCode(): string | undefined {
    return this._requiredTextCode
  }

  get creativeToken(): string | undefined {
    return this._creativeTokenId
  }

  get name(): string | undefined {
    return this._name
  }
  // end banner props
}
