import { Plugin } from '@nuxt/types'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
import cookie from 'cookie'

// access the store, http request and environment from the Nuxt context
// https://nuxtjs.org/api/context/
const localStoragePlugin: Plugin = ({ store, req, isDev, env }) => {
  createPersistedState({
    key: 'ga-cookies', // choose any name for your cookie
    paths: [
      'auth._sfToken',
      'auth._refreshToken',
      'regions._choiceCity'
    ],
    storage: {
      // if on the browser, parse the cookies using js-cookie otherwise parse from the raw http request
      getItem: key => process.client ? Cookies.get(key) : cookie.parse(req.headers.cookie || '')[key],
      // js-cookie can handle setting both client-side and server-side cookies with one method
      // use isDev to determine if the cookies is accessible via https only (i.e. localhost likely won't be using https)
      setItem: (key, value) => Cookies.set(key, value, { expires: 30, secure: !isDev, domain: env.NUXT_ENV_DOMAIN }),
      // also allow js-cookie to handle removing cookies
      removeItem: key => Cookies.remove(key)
    }
  })(store)
}

export default localStoragePlugin
