import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const loginMiddleware: Middleware = (context: Context) => {
  const isAuth = s(context.store).auth.isAuth
  if (isAuth) {
    return context.redirect(`/${s(context.store).regions.sectionCode}/personal/`)
  }
}

export default loginMiddleware
