import { Vue, Component, Ref } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import TextInput from '~/components/element/TextInput'
import SelectInput from '~/components/element/SelectInput'
import Theme from '~/types/api/Theme'
import Btn from '~/components/element/Btn'
import SelectOption from '~/types/SelectOption'
import Checkbox from '~/components/element/Checkbox'
import s from '~/utils/s'

interface Form {
  setErrors: (errors: { [key: string]: string[] }) => void;
  handleSubmit: (fn: () => void) => void;
}

@Component({
  components: {
    ValidationObserver
  }
})
export default class CallbackPopup extends Vue {
  themeListInit: Theme[] = [];
  userName = '';
  email = '';
  phone = '';
  text = '';
  regionId = '';
  sectionId = '';
  themeId = '';
  isPolicy = false;
  isCallback = false;

  initLoading = true;

  submitLoading = false;

  successSubmit = false;

  @Ref()
  form!: Form;

  get regionsList (): SelectOption[] {
    return s(this.$store).regions.regions.map(region => ({
      title: region.name,
      value: region.id
    })) ?? []
  }

  get sectionsList (): SelectOption[] {
    if (!this.regionId) {
      return []
    }
    const region = s(this.$store).regions.regions.find(r => r.id === this.regionId)

    return region?.items.map(section => ({
      title: section.name,
      value: section.sectionId
    })) ?? []
  }

  get themeList (): SelectOption[] {
    const get = (item: Theme) => {
      return {
        title: item.name,
        value: '' + item.id
      }
    }

    return this.themeListInit?.map(get) || []
  }

  async created () {
    const result = await this.$api.getThemeList()

    if (result.code === 200 && result.data) {
      this.themeListInit = result.data
    }

    this.regionId = s(this.$store).regions.regionId
    this.sectionId = s(this.$store).regions.sectionId

    this.userName = s(this.$store).auth.user?.fullName ?? ''
    this.phone = s(this.$store).auth.user?.phoneNumber ?? ''
    this.email = s(this.$store).auth.user?.email ?? ''

    this.initLoading = false
  }

  async submit () {
    this.submitLoading = true

    const result = await this.$api.sendFeedbackForm({
      email: this.email,
      phone: this.phone.replace(/^(\+7)|[^\d+]/g, ''),
      text: this.text,
      feedbackTopicId: this.themeId,
      regionId: this.regionId,
      sectionId: this.sectionId,
      isCallback: this.isCallback
    })

    if (result.code !== 200) {
      const error = result as any
      this.form.setErrors(error.fields)
    } else {
      this.successSubmit = true
    }

    this.submitLoading = false
  }

  closePopup () {
    s(this.$store).general.closePopup()
  }

  render () {
    return (
      <div class="popup-callback">
        {this.successSubmit ? this.renderSuccess() : this.renderForm()}
      </div>
    )
  }

  renderForm () {
    return (
      <div class="popup-callback__wrapper">
        <div class="popup-callback__header">Связаться с нами</div>
        <div class="popup-callback__body">
          <div class="popup-callback__description">
            Наш менеджер свяжется с вами в ближайшее время
          </div>
          <validation-observer ref="form">
            {({ handleSubmit }: any) => (
              <form
                class="popup-callback__form"
                onSubmit={(event: MouseEvent) => {
                  event.preventDefault()
                  handleSubmit(this.submit)
                }}
              >
                <SelectInput
                  key="regionId"
                  {...{
                    props: {
                      label: 'Регион*',
                      items: this.regionsList,
                      selected: this.regionId,
                      name: 'regionId',
                      rules: 'required'
                    },
                    on: {
                      change: (v: string) => {
                        this.regionId = v
                      }
                    }
                  }}
                />
                <SelectInput
                  key="sectionId"
                  {...{
                    props: {
                      label: 'Направление*',
                      items: this.sectionsList,
                      selected: this.sectionId,
                      name: 'sectionId',
                      rules: 'required'
                    },
                    on: {
                      change: (v: string) => {
                        this.sectionId = v
                      }
                    }
                  }}
                />
                <SelectInput
                  key="themeId"
                  {...{
                    props: {
                      label: 'Тема сообщения*',
                      items: this.themeList,
                      selected: this.themeId,
                      name: 'themeId',
                      rules: 'required'
                    },
                    on: {
                      change: (v: string) => {
                        this.themeId = v
                      }
                    }
                  }}
                />
                <TextInput
                  key="phone"
                  {...{
                    props: {
                      name: 'phone',
                      label: 'Контактный номер',
                      value: this.phone,
                      rules:
                        'required|regex:^(\\+7\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2})$',
                      mask: '+7 (###) ###-##-##',
                      type: 'tel',
                      placeholder: '+7 (999) 999-99-99'
                    },
                    on: {
                      input: (v: string) => (this.phone = v)
                    }
                  }}
                />
                <TextInput
                  key="email"
                  {...{
                    props: {
                      name: 'email',
                      label: 'E-mail',
                      value: this.email,
                      rules: 'required|email',
                      type: 'email'
                    },
                    on: {
                      input: (v: string) => (this.email = v)
                    }
                  }}
                />
                <TextInput
                  key="text"
                  {...{
                    props: {
                      name: 'text',
                      label: 'Текст сообщения',
                      value: this.text,
                      rules: 'required',
                      type: 'textarea',
                      placeholder: ''
                    },
                    on: {
                      input: (v: string) => (this.text = v)
                    }
                  }}/>
                <Checkbox {...{
                  props: {
                    value: this.isCallback,
                    name: 'isCallback',
                    label: 'Перезвонить мне'
                  },
                  on: {
                    change: (v: boolean) => { this.isCallback = v }
                  },
                  scopedSlots: {
                    textPayload: () => <div>Перезвонить мне</div>
                  }
                }} />
                <Btn {...{ props: { label: 'Отправить', wide: true } }}/>
              </form>
            )}
          </validation-observer>
        </div>
      </div>
    )
  }

  renderSuccess () {
    return (
      <div class="popup-callback__wrapper">
        <div class="popup-callback__header">Ваша заявка успешно отправлена</div>
        <div class="popup-callback__body">
          <div class="popup-callback__description">
            Наш менеджер свяжется с вами в ближайшее время
          </div>

          <Btn
            {...{
              props: { label: 'Ок', wide: true, type: 'button' },
              nativeOn: { click: this.closePopup }
            }}
          />
        </div>
      </div>
    )
  }
}
