import { RequestHandler } from 'express'

const redirects: Record<string, string> = {
  '/apteki/': '/apteki/',
  '/veterinariy/': '/veterinariy/',
  '/elektronnii-sertifikat/': '/elektronnii-sertifikat/'
}

const redirectMiddleware: RequestHandler = (req, res, next) => {
  const originalPath = req.url.split('?')[0]
  const urlSegments = req.url.split('/')
  const lowerCasePath = originalPath.toLowerCase()
  const query = req.url.split('?')[1]

  if (redirects[lowerCasePath] && originalPath !== redirects[lowerCasePath]) {
    const newPath = redirects[lowerCasePath] + (query ? `?${query}` : '')
    res.writeHead(301, { Location: newPath })
    return res.end()
  }

  if (urlSegments.length === 3 && `/${urlSegments[1]}/` !== redirects[lowerCasePath]) {
    if (urlSegments[1] !== '_loading' && urlSegments[1] !== '_nuxt' && (urlSegments[0] === '' && urlSegments[2] === '')) {
      res.writeHead(301, { Location: '/' })
      return res.end()
    }
  }

  next()
}

export default redirectMiddleware
