import { Vue, Component, Prop } from 'vue-property-decorator'
import Section from '~/types/api/Section'
import s from '~/utils/s'
import SectionHelper from '~/utils/sectionHelper'
import { SfSection } from '~/types/api/SfSection'

@Component
export default class FilterSectionChildren extends Vue {
  @Prop({ type: Array, required: true })
  items!: SfSection[]

  @Prop({ type: Object, required: true })
  section!: SfSection

  @Prop({ type: String })
  title?: string

  isShowMore: boolean = false

  get filteredItems () {
    if (this.items.length <= 3) {
      return this.items
    }

    if (!this.isShowMore) {
      return this.items.slice(0, 3)
    }
    return this.items
  }

  toggle () {
    this.isShowMore = !this.isShowMore
  }

  render () {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    const title = this.title || this.section.name

    return (
      <div class="filter-product-sections">
        <div class="filter-product-sections__parent-name">
          { title }
        </div>
        <div class="filter-product-sections__items">
          {this.filteredItems.map(e => (
            <nuxt-link key={e.id} to={helper.getSectionPathById(e.id)} class="filter-product-sections__item">
              {e.name}
            </nuxt-link>
          ))}
        </div>
        {this.items.length > 3 &&
          <div class="filter-product-sections__show-more" onClick={this.toggle}>
            {this.isShowMore ? 'Скрыть' : 'Показать все'}
          </div>
        }
      </div>
    )
  }
}
