import { Vue, Component } from 'vue-property-decorator'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import GeneralListType from '~/types/enum/GeneralListType'
import s from '~/utils/s'

@Component
export default class HeaderCloseGenrealList extends Vue {
  get isShow (): boolean {
    return s(this.$store).general.generalListType !== GeneralListType.None
  }

  mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  }

  close () {
    s(this.$store).general.closeGeneralList()
  }

  resize () {
    if (window.innerWidth > 1024 && this.isShow) {
      this.close()
    }
  }

  block () {
    if (this.isShow) {
      return (
        <div class="header-close-general-list">
          <div class="header-close-general-list__close" onClick={this.close}>
            <CloseSvg />
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      this.block()
    )
  }
}
