import { Vue, Component, Prop } from 'vue-property-decorator'
import GeneralListType from '~/types/enum/GeneralListType'
import HeaderLogo from '~/components/general/header/HeaderLogo'
import HeaderSearch from '~/components/general/header/HeaderSearch'
import HeaderCatalogButton from '~/components/general/header/HeaderCatalogButton'
import HeaderCatalogLinks from '~/components/general/header/HeaderCatalogLinks'
import CatalogMenu from '~/components/general/catalogMenu/CatalogMenu'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class HeaderBottom extends Vue {
  isHoverCatalogButton: boolean = false
  isHoverCatalogMenu: boolean = false
  isIndexPage: boolean = false
  isMobile: boolean = false

  @Prop({ type: Boolean, default: false })
  readonly inSticky?: boolean

  created () {
    this.isIndexPage = this.$router.currentRoute.name === 'index' || this.$router.currentRoute.name === 'region'
    this.$router.afterEach(to => this.isIndexPage = to.name === 'index' || this.$router.currentRoute.name === 'region')
  }

  mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    this.isMobile = window.innerWidth < 768
  }

  onHoverCatalogButton (value: boolean): void {
    this.isHoverCatalogButton = value
  }

  onClickCatalogButton () {
    this.$router.push(loc(this.$store, { name: 'catalog' }))
  }

  get isShowMenu () {
    return this.isHoverCatalogButton || this.isHoverCatalogMenu
  }

  get isShowGeneralList (): boolean {
    return s(this.$store).general.generalListType !== GeneralListType.None
  }

  get isGeneralSearchOrCatalog (): boolean {
    return s(this.$store).general.generalListType === GeneralListType.Search ||
      s(this.$store).general.generalListType === GeneralListType.Category
  }

  showLogo () {
    if (!this.isShowGeneralList && (!this.isMobile || this.isIndexPage)) {
      return (
        <div class="common-header-bottom__col-logo">
          <div class="common-header-bottom__logo">
            <HeaderLogo />
          </div>
        </div>
      )
    }
  }

  showSearch () {
    if (s(this.$store).general.generalListType !== GeneralListType.Personal) {
      return (
        <div class="common-header-bottom__col-search">
          <div class="common-header-bottom__search">
            <HeaderSearch />
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <div class={{
        'common-header-bottom': true,
        'common-header-bottom_full-search': this.isGeneralSearchOrCatalog
      }}>
        <div class="common-header-bottom__container">
          <div class="common-header-bottom__row">
            {this.showLogo()}
            <div class="common-header-bottom__col-catalog-btn">
              <div class="common-header-bottom__catalog-btn">
                <HeaderCatalogButton {...{
                  on: {
                    hover: this.onHoverCatalogButton,
                    click: this.onClickCatalogButton
                  }
                }} />
              </div>
            </div>
            {this.showSearch()}
            <div class="common-header-bottom__col-catalog-links">
              <div class="common-header-bottom__catalog-links">
                <HeaderCatalogLinks {...{
                  props: {
                    inSticky: this.inSticky
                  }
                }}/>
              </div>
            </div>
          </div>
        </div>
        <div class="common-header-bottom__catalog-menu">
          <div class="common-header-bottom__container">
            <div
              class="common-header-bottom__catalog-menu-wrapper"
              onMouseenter={() => this.isHoverCatalogMenu = true}
              onMouseleave={() => this.isHoverCatalogMenu = false}
            >
              <CatalogMenu {...{
                props: {
                  isShow: this.isShowMenu
                },
                on: {
                  close: () => this.isHoverCatalogMenu = false
                }
              }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
