import { Vue, Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import Section from '~/types/api/Section'
import ArrowSvg from '~/assets/svg/catalog-menu-arrow.svg?inline'
import ThirdLevelList from '~/components/general/catalogMenu/ThirdLevelList'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'

@Component({
  components: {
    ArrowSvg,
    ThirdLevelList
  }
})
export default class SecondLevelList extends Vue {
  @Prop({ type: Array, required: true })
  readonly sections!: SfSection[]

  get allSections (): SfSection[] {
    return s(this.$store).general.sections
  }

  getChildrenById (id: string) {
    return s(this.$store).general.sections.filter(e => e.parentId === id)
  }

  render (h: CreateElement) {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    return (
      <div class="catalog-menu__second-level-list">
        {this.sections.map((e) => {
          const children = this.getChildrenById(e.id)
          let arrow = null
          let thirdLevel = null
          if (children.length > 0) {
            arrow = (
              <div class="catalog-menu__second-level-item-link-arrow">
                <ArrowSvg />
              </div>
            )
            thirdLevel = h(ThirdLevelList, {
              props: {
                sections: children
              },
              on: {
                close: () => this.$emit('close')
              }
            })
          }
          return (
            <div key={e.id} class="catalog-menu__second-level-item">
              <div class="catalog-menu__second-level-item-wrapper">
                <nuxt-link class='catalog-menu__second-level-item-link' to={helper.getSectionPathById(e.id)} nativeOn={{ click: () => this.$emit('close') }}>
                  <div class="catalog-menu__second-level-item-link-text">
                    {e.name}
                    {arrow}
                  </div>
                </nuxt-link>
                {thirdLevel}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
