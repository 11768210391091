import { Vue, Component, Prop } from 'vue-property-decorator'
import * as NotificationItemEntity from '~/types/NotificationItem'
import animate from '~/utils/animate'
import NotificationType from '~/types/enum/NotificationType'
import CloseSvg from '~/assets/svg/close-notification.svg?inline'
import ErrorSvg from '~/assets/svg/notification-error.svg?inline'
import SuccessSvg from '~/assets/svg/notification-success.svg?inline'
import WarnginSvg from '~/assets/svg/notification-warning.svg?inline'
import s from '~/utils/s'

@Component
export default class NotificationItem extends Vue {
  widht: number = 0
  isRemove: boolean = false

  @Prop({ type: Object, required: true })
  readonly notification?: NotificationItemEntity.default

  async mounted () {
    /* const sound = new Audio('/point-blank-589.mp3')
    await sound.play() */
    animate({
      duration: 10000,
      timing: timeFraction => Math.pow(timeFraction, 2),
      draw: (progress) => {
        this.widht = progress * 100
        if (progress === 1) {
          this.remove()
        }
      }
    })
  }

  remove () {
    if (!this.isRemove) {
      this.isRemove = true
      s(this.$store).notification.remove(this.notification?.id)
    }
  }

  get header () {
    if (this.notification?.title) {
      return this.notification.title
    }
    switch (this.notification?.type) {
      case NotificationType.Success:
        return 'Успех'
      case NotificationType.Warning:
        return 'Предупреждение'
      default:
        return 'Ошибка'
    }
  }

  get color () {
    switch (this.notification?.type) {
      case NotificationType.Success:
        return '#37B992'
      case NotificationType.Warning:
        return '#F5A800'
      default:
        return '#EB5757'
    }
  }

  renderLogo () {
    switch (this.notification?.type) {
      case NotificationType.Success:
        return (<SuccessSvg />)
      case NotificationType.Warning:
        return (<WarnginSvg />)
      default:
        return (<ErrorSvg />)
    }
  }

  render () {
    return (
      <div class="notification-list__item">
        <div class="notification-list__item-close" onClick={() => this.remove()}>
          <CloseSvg />
        </div>
        <div class="notification-list__item-icon">
          {this.renderLogo()}
        </div>
        <div class="notification-list__item-content">
          <div class="notification-list__item-header">
            {this.header}
          </div>
          <div class="notification-list__item-text">
            {this.notification?.text}
          </div>
        </div>
        <div class="notification-list__item-line-wrapper">
          <div class="notification-list__item-line" {...{
            style: {
              background: this.color,
              width: `${this.widht}%`
            }
          }}></div>
        </div>
      </div>
    )
  }
}
