import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'
import loc from '~/utils/loc'
import AddToCartSvg from '~/assets/svg/add-to-cart-success.svg?inline'

@Component
export default class ResultToCartPopup extends Vue {
  closePopup () {
    s(this.$store).general.closePopup()
  }

  render () {
    return (
      <div class="popup-callback">
        <div class="popup-result-to-cart__wrapper">
          <div class="popup-result-to-cart__image">
            <AddToCartSvg/>
          </div>
          <div class="popup-result-to-cart__success-text">Товары были успешно добавлены в корзину</div>
          <div class="popup-result-to-cart__buttons">
            <div class="popup-result-to-cart__continue-button">
              <div class="button-text" onclick = {this.closePopup}>Продолжить покупки</div>
            </div>
            <div class="popup-result-to-cart__cart-button">
              <nuxt-link
                to={loc(this.$store, { name: 'basket' })}
                class="basket-on-hover__basket-total-link-wrapper"
              >
                <div class="button-text" onclick={this.closePopup}>В корзину</div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
