import { Vue, Component } from 'vue-property-decorator'
import ManSvg from '~/assets/svg/header-man.svg?inline'
import s from '~/utils/s'

@Component
export default class HeaderPersonal extends Vue {
  openPersonal () {
    this.$store.$router.push({ path: '/personal/login/' })
  }

  render () {
    return (
      <div onclick={this.openPersonal} class="header-link header-link_great">
        <div class="header-link__icon">
          <ManSvg />
        </div>
        <div class="header-link__text header-link__user">
          {s(this.$store).auth.isAuth ? s(this.$store).auth.user?.fullName : 'Войти'}
        </div>
      </div>
    )
  }
}
