import { Vue, Component } from 'vue-property-decorator'
import VkSvg from '~/assets/svg/social-vk.svg?inline'

@Component
export default class FooterSocial extends Vue {
  render () {
    return (
      <div class="common-footer-social">
        <a href="https://vk.com/gubernskie_apteki" target="_blank" class="common-footer-social__item">
          <VkSvg />
        </a>
      </div>
    )
  }
}
