import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'

@Component({
  components: {
    ValidationProvider
  }
})
export default class Checkbox extends Vue {
  innerValue: boolean = false

  @Prop({ type: Boolean, required: true })
  readonly value?: boolean

  @Prop({ type: String, required: true })
  readonly name?: string

  @Prop({ type: String, required: true })
  readonly label?: string

  @Prop({ type: Object, default: () => { /* This is intentional */ } })
  readonly rules?: any

  @Prop({ type: Array, default: () => [] })
  readonly modifiers?: string[]

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  get hasValue (): boolean {
    return !!this.innerValue
  }

  get isShowText (): boolean {
    return this.$scopedSlots.textPayload !== undefined
  }

  get css () {
    if (this.modifiers) {
      return Object.fromEntries(this.modifiers?.map(e => [`common-checkbox_${e}`, true]))
    }
    return {}
  }

  renderScopTextPayload () {
    if (this.$scopedSlots.textPayload !== undefined) {
      return this.$scopedSlots.textPayload(this)
    }
  }

  @Watch('innerValue')
  innerValueChange (value: boolean) {
    this.$emit('change', value)
  }

  @Watch('value')
  valueChange (value: boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value
    }
  }

  render () {
    return (
      <validation-provider {...{
        props: {
          rules: this.rules,
          name: this.label,
          customMessages: ru.messages,
          vid: this.name
        }
      }}>
        {({ errors }: any) => (
          <label {...{
            class: {
              'common-checkbox': true,
              'common-checkbox_error': errors.length > 0,
              'common-checkbox_hide-text': !this.isShowText,
              ...this.css
            }
          }}>
            <input
              class="common-checkbox__checkbox"
              type="checkbox"
              v-model={this.innerValue}
              name={this.name} />
            <span class="common-checkbox__checkmark"></span>
            <div class="common-checkbox__text">
              {this.renderScopTextPayload()}
            </div>
            <div class="common-checkbox__error">
              {errors[0]}
            </div>
          </label>
        )}
      </validation-provider>
    )
  }
}
