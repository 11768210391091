import { Vue, Component } from 'vue-property-decorator'
import LinkSelector from '~/components/element/LinkSelector'
import LinkItem from '~/types/LinkItem'
import * as sort from '~/utils/config/productSort'
import s from '~/utils/s'
import UnknownPageType from '~/types/enum/UnknownPageType'

@Component
export default class CategoryFilterHeaderSticky extends Vue {
  isSticky: boolean = false;
  currentYOffset: number = 0;

  mounted () {
    window.addEventListener('scroll', this.scrollHandler)
    this.scrollHandler()
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  get selectedSort (): string {
    const query = this.$route.query
    return sort.checkValue(query.sort)
  }

  get sortList (): LinkItem[] {
    const query = this.$route.query
    const get = (sortCode: string, name: string) => (
      {
        code: sortCode,
        title: name,
        location: {
          path: this.$route.path,
          query: {
            ...query,
            sort: sortCode
          }
        }
      }
    )

    return Object.entries(sort.default).map(([k, v]) => get(k, v))
  }

  scrollHandler () {
        const isCatalogPage = this.$route.name === 'region-all'
    const isCategoryPage = s(this.$store).unknownPage.pageType === UnknownPageType.Category
    if (s(this.$store).unknownPage.showSticky) {
      this.isSticky = (window.pageYOffset > 400) && (window.pageYOffset < this.currentYOffset)
      this.currentYOffset = window.pageYOffset
    }
    else {
      this.isSticky = false
    }
  }

  render () {
    return (
      <div class={{
        'category-filter-header': true,
        'category-filter-header__sticky': this.isSticky
      }}>
        <div class="category-filter-header__sort">
          <div class="category-filter-header__sort-product">
            <LinkSelector {...{
              props: {
                items: this.sortList,
                selected: this.selectedSort
              }
            }} />
          </div>
        </div>
        <div class="category-filter-header__filter">
          <div class="category-filter-header__filter-button" onClick={(e: MouseEvent) => {
            e.stopPropagation()
            s(this.$store).general.showPopup('FilterPopup')
          }}>
            Фильтр
          </div>
        </div>
      </div>
    )
  }
}
