import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import ListResponse from '~/types/api/ListResponse'
import Result from '~/types/api/Result'
import SearchResult from '~/types/api/search/SearchResult'
import Product from '~/types/api/Product'
import EntitySearch from '~/types/api/search/EntitySearch'
import * as sort from '~/utils/config/searchSort'
import config from '~/utils/config'
import s from '~/utils/s'

type Dictionary<T> = { [key: string]: T }

@Module({
  name: 'searchPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _list: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _searchString: string = ''
  _articles: EntitySearch[] = []
  _stocks: EntitySearch[] = []
  _limit: number = 12
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  @Mutation
  setList (list: ListResponse<Product>) {
    this._list = list
  }

  @Mutation
  setArticles (items?: EntitySearch[]) {
    if (items) {
      this._articles = items
    } else {
      this._articles = []
    }
  }

  @Mutation
  setStocks (items?: EntitySearch[]) {
    if (items) {
      this._stocks = items
    } else {
      this._stocks = []
    }
  }

  @Mutation
  setSearchString (val: string) {
    this._searchString = val
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Action({ rawError: config.rawError })
  setDefault () {
    this.setList({
      count: 0,
      items: []
    })
    this.setArticles([])
    this.setStocks([])
  }

  @Action({ rawError: config.rawError })
  async loadResult (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    // если переход из другой страницы, непоказывает лоадер
    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    // перед загрузкой очищаем баннеры
    s(this.store).productCardsCommercial.setProductCardsCommercial([])
    const query = route.query
    const q = await this.checkTypeQuery(query)
    if (q.length > 2) {
      const sortType = sort.checkValue(query.sort)
      const page = await this.getPageNumber(query)
      const offset = page * this._limit
      s(this.store).searchFilter.fillQueryFilter(route)

      const options = {
        limit: this._limit,
        offset,
        regionId: s(this.store).regions.regionId ?? 0,
        sortType,
        q,
        filter: s(this.store).searchFilter.queryFilter,
        sectionId: s(this.store).regions.sectionId
      }

      const result = await this.store.$api.search(options)

      await this.resultParse(result)
      this.setPage(page)
    } else {
      this.setDefault()
    }
    this.setSearchString(q)
    s(this.store).searchBar.closeClear()
    s(this.store).searchBar.setInput(q)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  getPageNumber (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      let page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
      return page
    }
    return 0
  }

  @Action({ rawError: config.rawError })
  checkTypeQuery (query: Dictionary<string | (string | null)[]>) {
    if (typeof query.q === 'string') {
      try {
        return decodeURIComponent(query.q)
      } catch (error) {
        return query.q
      }
    }
    return ''
  }

  @Action({ rawError: config.rawError })
  async resultParse (result: Result<SearchResult>) {
    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.products.items]
        })
        this.disableLoadMore()
      } else {
        this.setList(result.data.products)
        this.setArticles(result.data.articles)
        this.setStocks(result.data.stocks)
      }
      s(this.store).searchFilter.setList(result.data.products.filters)
      await s(this.store).searchFilter.fillSelectedFilter()
    } else {
      this.setDefault()
    }
  }

  get list (): ListResponse<Product> {
    return this._list
  }

  get articles (): EntitySearch[] {
    return this._articles
  }

  get stocks (): EntitySearch[] {
    return this._stocks
  }

  get searchString (): string {
    return this._searchString
  }

  get isFindProduct (): boolean {
    return this.list.count > 0
  }

  get isFindArticles (): boolean {
    return this.articles.length > 0
  }

  get isFindStocks (): boolean {
    return this.stocks.length > 0
  }

  get isNotFound (): boolean {
    return !this.isFindProduct && !this.isFindArticles && !this.isFindStocks
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }
}
