import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import * as axios from 'axios'
import SearchBar from '~/types/api/search/SearchBar'
import config from '~/utils/config'
import GeneralListType from '~/types/enum/GeneralListType'
import s from '~/utils/s'

@Module({
  name: 'searchBar',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _isActive: boolean = false
  _searchResult: SearchBar = {}
  _value: string = ''
  _delay: number = 300
  _minLength: number = 2
  _cancelSource?: axios.CancelTokenSource
  _timeout?: NodeJS.Timeout

  @Mutation
  setInput (val: string) {
    this._value = val
  }

  @Mutation
  setTimeout (val: NodeJS.Timeout) {
    this._timeout = val
  }

  @Mutation
  setCancelSource (val: axios.CancelTokenSource) {
    this._cancelSource = val
  }

  @Mutation
  setSearchResult (val: SearchBar) {
    this._searchResult = val
  }

  @Mutation
  setActive (val: boolean) {
    this._isActive = val
  }

  @Mutation
  stopRequest () {
    if (this._cancelSource) {
      this._cancelSource.cancel()
      this._cancelSource = undefined
    }
    if (this._timeout) {
      clearTimeout(this._timeout)
      this._timeout = undefined
    }
  }

  @Action({ rawError: config.rawError })
  enableSearch () {
    this.setActive(true)
  }

  @Action({ rawError: config.rawError })
  disableSearch () {
    this.setActive(false)
  }

  @Action({ rawError: config.rawError })
  changeInput (value: string) {
    this.setInput(value)
    this.stopRequest()
    if (this.value.length > this.minLength) {
      const timeout = setTimeout(async () => {
        const cancelSource = axios.default.CancelToken.source()
        this.setCancelSource(cancelSource)
        const result = await this.store.$api.searchBar({
          limit: 10,
          regionId: s(this.store).regions.regionId ?? 0,
          q: this.value,
          sectionId: s(this.store).regions.sectionId
        }, cancelSource)
        if (result.code === 200 && result.data) {
          this.setSearchResult(result.data)
          s(this.store).general.showGeneralList(GeneralListType.Search)
        }
      }, this.delay)
      this.setTimeout(timeout)
    }
  }

  @Action({ rawError: config.rawError })
  closeClear () {
    this.clearResult()
    this.close()
    s(this.store).general.closeGeneralList()
  }

  @Action({ rawError: config.rawError })
  clearResult () {
    this.setInput('')
    this.setSearchResult({})
  }

  @Action({ rawError: config.rawError })
  close () {
    this.disableSearch()
    this.stopRequest()
  }

  get isActive (): boolean {
    return this._isActive
  }

  get searchResult (): SearchBar {
    return this._searchResult
  }

  get value (): string {
    return this._value
  }

  get delay (): number {
    return this._delay
  }

  get minLength (): number {
    return this._minLength
  }
}
