import { Component, Vue } from 'vue-property-decorator'
import NotificationItem from '~/components/general/notificationList/NotificationItem'
import s from '~/utils/s'
import NotificationType from '~/types/enum/NotificationType'

interface INotificationMessage {
  data: Record<string, string>
  notification: {
    body: string;
    title: string
  }
  fcmMessageId: string
  from: string
  priority: string
}

@Component({
  components: {
    NotificationItem
  }
})
export default class NotificationList extends Vue {
  mounted () {
    if (this.$fire.messaging) {
      this.$fire.messaging.onMessage((payload: INotificationMessage) => {
        s(this.$store).notification.addNotification({
          text: payload.notification.body,
          type: NotificationType.Success,
          title: payload.notification.title
        })
      })
    }
  }

  render () {
    return (
      <div class="notification-list">

        <div class="notification-list__items">
          <transition-group name="notification-list__animate">
            {s(this.$store).notification.notificationQueue.map(e => (
              <notification-item key={e.id} notification={e}/>
            ))}
          </transition-group>
        </div>
      </div>
    )
  }
}
