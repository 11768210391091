import { Vue, Component } from 'vue-property-decorator'
import { CreateElement } from 'vue'

@Component({
  layout: 'empty'
})
export default class ErrorClosedSite extends Vue {
  render (h: CreateElement) {
    return (
      <div class="closed-site-error-page">
        {h('img', {
          attrs: { src: require('~/assets/image/closed_logo.png') }
        })}
        <h1 class="closed-site-error-page__title">Интернет-аптека Фармация</h1>
        <h3 class="closed-site-error-page__sub-title">Уважаемые клиенты!</h3>
        <p class="closed-site-error-page__text">
          На сайте ведутся технические работы.
        </p>
        <p class="closed-site-error-page__text">
          Приносим свои извинения за доставленные неудобства.
        </p>
        <p class="closed-site-error-page__text">
          Оформить заказ на доставку возможно по телефону 2911-555.
        </p>
      </div>
    )
  }
}
