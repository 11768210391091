import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ru from 'vee-validate/dist/locale/ru.json'
import { ValidationProvider } from 'vee-validate'
import ArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import SelectOption from '~/types/SelectOption'

@Component({
  components: {
    ValidationProvider
  }
})
export default class SelectInput extends Vue {
  @Prop({ type: Array, required: true })
  readonly items?: SelectOption[];

  @Prop({ type: String, required: true })
  readonly selected!: string;

  @Prop({ type: String, default: '' })
  readonly label?: string;

  @Prop({ type: String, required: true })
  readonly name!: string;

  @Prop({ type: String, default: '' })
  readonly rules?: string;

  innerSelected: string = '';

  isShow: boolean = false;

  @Watch('selected')
  selectedWatch (value: string) {
    this.innerSelected = value
  }

  get selectedText () {
    return this.items?.find(e => e.value === this.selected)?.title
  }

  get list () {
    return this.items?.filter(e => e.value !== this.selected)
  }

  showList () {
    if (this.isShow) {
      return (
        <div class="link-selector__list">
          {this.list?.map(e => (
            <div
              {...{
                class: 'link-selector__item',
                on: {
                  click: (event: MouseEvent) => {
                    event.stopPropagation()
                    this.select(e.value)
                  }
                }
              }}
            >
              {e.title}
            </div>
          ))}
        </div>
      )
    }
  }

  select (value: string) {
    this.$emit('change', value)
    this.close()
  }

  close () {
    this.isShow = false
  }

  toggle () {
    this.isShow = !this.isShow
  }

  renderLabel () {
    return this.label ? (
      <div class="link-selector__label">{this.label}</div>
    ) : (
      ''
    )
  }

  render () {
    return (
      <validation-provider
        {...{
          props: {
            rules: this.rules,
            name: this.label,
            customMessages: ru.messages,
            vid: this.name
          }
        }}
      >
        {({ errors, required }: any) => (
          <div
            {...{
              class: {
                'common-input_required': required,
                'common-input_error': errors.length > 0,
                'link-selector__wrapper': true,
                'common-input': true
              }
            }}
          >
            {this.renderLabel()}

            <div
              {...{
                class: 'link-selector',
                directives: [
                  {
                    name: 'VueClickOutside',
                    value: this.close
                  }
                ]
              }}
            >
              <div
                {...{
                  class: {
                    'link-selector__selected': true,
                    'link-selector__error': errors.length > 0
                  },
                  on: {
                    click: this.toggle
                  }
                }}
              >
                <div class="link-selector__selected-text">
                  {this.selectedText}
                </div>
                <div class="link-selector__selected-arrow">
                  <ArrowSvg />
                </div>
              </div>
              {this.showList()}
            </div>
            <input type="hidden" name={this.name} value={this.selected} />
            <div class="common-input__error">{errors[0]}</div>
          </div>
        )}
      </validation-provider>
    )
  }
}
