import { Vue, Component } from 'vue-property-decorator'
import ArrowSvg from '~/assets/svg/arrow-search.svg?inline'
import SearchSvg from '~/assets/svg/search-icon.svg?inline'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import SectionHelper from '~/utils/sectionHelper'
import GeneralListType from '~/types/enum/GeneralListType'
import loc from '~/utils/loc'
import s from '~/utils/s'
import Product from '~/types/api/Product'

@Component
export default class HeaderSearch extends Vue {
  placeholder: string = 'Название, действующее вещество или симптом'
  isFocusSearchResultIndex: number = -1

  get isOffset (): boolean {
    return s(this.$store).general.generalListType !== GeneralListType.None ||
      (this.$route.name !== 'index' && this.$route.name !== 'region')
  }

  getLink (product: Product) {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    const sections = product.sectionId
    return helper.getProductLink(sections?.length ? sections[0] : s(this.$store).categoryPage.category.id, product.seoUrl ?? product.id)
  }

  openLink (e: Product) {
    const link = this.getLink(e)
    if (link) {
      s(this.$store).searchBar.closeClear()
      this.$router.push(link)
    }
  }

  created () {
    this.$router.afterEach((to, _from) => {
      if (to.name !== 'region-search') {
        s(this.$store).searchBar.closeClear()
      }
    })
  }

  mounted () {
    if (s(this.$store).general.generalListType === GeneralListType.Search) {
      const input: any = this.$refs.input
      if (input) {
        input.focus()
      }
    }
    window.addEventListener('click', this.clickOutsideHandler.bind(this))
  }

  scrollTo () {
    const elem = document.querySelector('.app__content')
    if (elem) {
      const offset = window.scrollY + elem.getBoundingClientRect().top
      window.scroll({ top: offset, left: 0, behavior: 'smooth' })
    }
  }

  openSearchPage () {
    const value = s(this.$store).searchBar.value
    s(this.$store).searchBar.close()
    if (this.$route.name === 'region-search') {
      this.scrollTo()
    }
    const input: any = this.$refs.input
    if (input) {
      input.blur()
    }
    this.$router.push(loc(this.$store, {
      name: 'search',
      query: {
        q: encodeURIComponent(value)
      }
    }))
  }

  clickOutsideHandler (event: any) {
    const classCheck = 'header-search__input-wrapper'
    const isContainerClick = event.target.className === classCheck
    const isChildClick = event.target.closest(`.${classCheck}`) !== null
    if ((!isContainerClick && !isChildClick)) {
      s(this.$store).searchBar.close()
      this.isFocusSearchResultIndex = -1
    }
  }

  setFocusSearchResult (e: KeyboardEvent, offsetIndex: number = 1) {
    e.preventDefault()
    const list: any = this.$refs.searchResult
    let indexItem: number = 0
    const elems = list.querySelectorAll('.header-search__item-link')
    let elemFocus = elems[indexItem]
    if (this.isFocusSearchResultIndex !== -1) {
      indexItem = this.isFocusSearchResultIndex + offsetIndex
      elemFocus = elems[indexItem]
    }
    if (elemFocus) {
      elemFocus.focus()
      this.isFocusSearchResultIndex = indexItem
    }
  }

  beforeDestroy () {
    window.removeEventListener('click', this.clickOutsideHandler)
  }

  renderInput () {
    return (
      <div class="header-search__input-wrapper">
        <transition name="header-search__animate-logo">
          {!s(this.$store).searchBar.isActive &&
          <div class="header-search__logo">
            <SearchSvg />
          </div>
          }
        </transition>
        <input class='header-search__input' {...{
          ref: 'input',
          domProps: {
            value: s(this.$store).searchBar.value
          },
          attrs: {
            name: 'header-search',
            type: 'text',
            placeholder: this.placeholder,
            autocomplete: 'off'
          },
          on: {
            focus: () => {
              s(this.$store).searchBar.enableSearch()
              this.isFocusSearchResultIndex = -1
            },
            input: (e: any) => s(this.$store).searchBar.changeInput(e.target.value)
          }
        }} />
        <transition name="header-search__animate-close">
          {(s(this.$store).searchBar.isActive || s(this.$store).searchBar.value.length > 0) &&
          <div class="header-search__close" onClick={s(this.$store).searchBar.closeClear}>
            <CloseSvg />
          </div>
          }
        </transition>
      </div>
    )
  }

  renderProducts () {
    const items = s(this.$store).searchBar.searchResult.products?.items
    if (items && items.length > 0) {
      return (
        <div class="header-search__item-container">
          {items.map((e) => {
            return (
              <div class="header-search__item-link" key={e.id} onClick={() => this.openLink(e)}>
                {e.title}
              </div>
            )
          })}
          <div class="header-search__item-container-line"></div>
        </div>
      )
    }
  }

  renderStocks () {
    const items = s(this.$store).searchBar.searchResult.stocks
    if (items && items.length > 0) {
      return (
        <div class="header-search__item-container">
          {items.map((e) => {
            const link = loc(this.$store, {
              name: 'stock',
              params: {
                code: e.code
              }
            })
            return (
              <nuxt-link {...{
                nativeOn: {
                  click: s(this.$store).searchBar.closeClear
                }
              }} key={e.id} to={link} class="header-search__item-link header-search__item-link_stock">
                {e.title}
              </nuxt-link>
            )
          })}
          <div class="header-search__item-container-line"></div>
        </div>
      )
    }
  }

  renderArticles () {
    const items = s(this.$store).searchBar.searchResult.articles
    if (items && items.length > 0) {
      return (
        <div class="header-search__item-container">
          {items.map((e) => {
            const link = loc(this.$store, {
              name: 'news',
              params: {
                code: e.code
              }
            })
            return (
              <nuxt-link {...{
                nativeOn: {
                  click: s(this.$store).searchBar.closeClear
                }
              }} key={e.id} to={link} class="header-search__item-link">
                Статья: «{e.title}»
              </nuxt-link>
            )
          })}
          <div class="header-search__item-container-line"></div>
        </div>
      )
    }
  }

  render () {
    return (
      <div {...{
        class: {
          'header-search': true,
          'header-search_offset': this.isOffset
        },
        on: {
          keyup: (e: KeyboardEvent) => {
            e.key === 'Enter' && this.openSearchPage()
          },
          keydown: (e: KeyboardEvent) => {
            e.key === 'ArrowDown' && this.setFocusSearchResult(e, 1)
            e.key === 'ArrowUp' && this.setFocusSearchResult(e, -1)
          }
        }
      }}>
        <div {...{
          class: {
            'header-search__container': true,
            'header-search__container_active': s(this.$store).searchBar.isActive
          }
        }}>
          {this.renderInput()}
          <transition name="header-search__animate-list">
            {s(this.$store).searchBar.isActive &&
            <div class="header-search__result-wrapper" {...{
              ref: 'searchResult'
            }}>
              {this.renderProducts()}
              {this.renderStocks()}
              {this.renderArticles()}
            </div>
            }
          </transition>
        </div>
        <div class="header-search__button" onClick={this.openSearchPage}>
          <ArrowSvg />
        </div>
      </div>
    )
  }
}
