export default function ({ app: { router }, isDev, env }) {
  if (process.client && !isDev) {
    router.onReady(() => {
      setTimeout(() => {
        (function (m, e, t, r, i, k, a) {
          m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) }
          m[i].l = 1 * new Date()
          k = e.createElement(t)
          a = e.getElementsByTagName(t)[0]
          k.async = 1
          k.src = r
          a.parentNode.insertBefore(k, a)
        })(window, window.document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

        const token = env.NODE_ENV_YANDEX_METRIKA_TOKEN

        window.ym(token, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          ecommerce: true
        })

        window.dataLayer = window.dataLayer || []
        window.yaContextCb = window.yaContextCb || []

        router.afterEach((to, from) => {
          window.ym(token, 'hit', to.fullPath, {
            referer: from.fullPath
          })
        })
      }, 2000)
    })
  }
}
