import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { MetaInfo } from 'vue-meta'
import config from '~/utils/config'
import { MetrikaCode, MetrikaLocalCodes } from '~/types/api/MetrikaCode'

@Module({
  name: 'seo',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _title: string = ''
  _keywords: string = ''
  _description: string = ''
  _canonical: string = ''
  _host: string = ''
  _subdomain: string = ''
  _imgTitle: string = ''
  _imgAlt: string = ''
  _metrikaCodes: MetrikaCode[] = []

  @Mutation
  setTitle (val: string) {
    this._title = val
  }

  @Mutation
  setKeywords (val: string) {
    this._keywords = val
  }

  @Mutation
  setDescription (val: string) {
    this._description = val
  }

  @Mutation
  setCanonical (val: string) {
    this._canonical = val
  }

  @Mutation
  setHost (val: string) {
    this._host = val
  }

  @Mutation
  setSubdomain (val: string) {
    this._subdomain = val
  }

  @Mutation
  setImgTitle (val: string) {
    this._imgTitle = val
  }

  @Mutation
  setImgAlt (val: string) {
    this._imgAlt = val
  }

  @Mutation
  setMetrikaCodes (items: MetrikaCode[]) {
    this._metrikaCodes = items
  }

  @Action({ rawError: config.rawError })
  load ({ host, subdomain }: {host: string, subdomain: string}) {
    this.setHost(host)
    this.setSubdomain(subdomain)
  }

  @Action({ rawError: config.rawError })
  async loadMetrikaCodes () {
    const result = await this.store.$api.getMetrikaCodes()

    if (result.data) {
      this.setMetrikaCodes(result.data.codes)
    }
  }

  @Action({ rawError: config.rawError })
  async getMetrikaCode (code: MetrikaLocalCodes): Promise<string> {
    return this._metrikaCodes.find(i => i.code === code)?.metrikaCode ?? ''
  }

  get title (): string {
    return this._title
  }

  get keywords (): string {
    return this._keywords
  }

  get description (): string {
    return this._description
  }

  get canonical (): string {
    return this._canonical
  }

  get imgTitle (): string {
    return this._imgTitle
  }

  get imgAlt (): string {
    return this._imgAlt
  }

  get meta (): MetaInfo {
    const data: MetaInfo = {
      title: this.title,
      meta: [
        {
          name: 'keywords',
          content: this.keywords
        },
        {
          name: 'description',
          content: this.description
        }
      ]
    }

    if (this.canonical) {
      let host = this._host
      if (this._subdomain) {
        host = this._subdomain + '.' + this._host
      }
      data.link = [
        {
          rel: 'canonical',
          href: 'https://' + host + this.canonical
        }
      ]
    }

    return data
  }
}
