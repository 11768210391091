import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'
import BgSvg from '~/assets/svg/section-closed-bg.svg?inline'
import RegionClosedSvg from '~/assets/svg/section-closed-img.svg?inline'

@Component
export default class RegionClosed extends Vue {
  closedTo: string = ''
  regionName: string = ''
  regionClosingReason: string = ''

  mounted () {
    this.closedTo = s(this.$store).regions.region?.closedTo ?? 'завтра'
    // if (this.closedTo && this.closedTo !== 'завтра') {
    //   this.closedTo = new Date(this.closedTo).toLocaleDateString()
    // }
    this.regionName = s(this.$store).regions.region?.name ?? ''
    this.regionClosingReason = s(this.$store).regions.region?.closingReason ?? ''
  }

  render () {
    return (
      <div class="region-closed-page">
        <div class="region-closed-page__bg">
          <BgSvg />
        </div>
        <div class="region-closed-page__content">
          <div class="region-closed-page__header">
            Регион &laquo;{this.regionName}&raquo;
            временно недоступен до {this.closedTo}
            <div class="region-closed-page__apologise">
              Приносим извинения за доставленные неудобства
            </div>
            <div class="region-closed-page__apologise">
              {this.regionClosingReason}
            </div>
          </div>
          <div class="region-closed-page__image">
            <RegionClosedSvg />
          </div>
        </div>
      </div>
    )
  }
}
