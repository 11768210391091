import { Vue, Component } from 'vue-property-decorator'
import { RegionItem } from '~/types/api/Region'
import s from '~/utils/s'

interface Link {
  code: string
  name: string
  selected: boolean
}

@Component
export default class HeaderTabs extends Vue {
  get items (): Link[] {
    return s(this.$store).regions.region?.items.map<Link>((e: RegionItem) => ({
      code: e.code,
      name: e.name,
      selected: e.id === s(this.$store).regions.section?.id
    })) ?? []
  }

  getCountBasket (code: string) {
    const section = s(this.$store).regions.region?.items.find(e => e.code === code)
    const item = s(this.$store).regions.basketCounts.find(e => e.directory === section?.code)
    return item?.count ?? 0
  }

  render () {
    return (
      <div class="header-tabs">
        {this.items.map((link) => {
          const classCollection = {
            'header-tabs__item': true,
            'header-tabs__item_selected': link.selected
          }

          const countBasket = this.getCountBasket(link.code)

          return (
            <nuxt-link to={{
              name: 'region',
              params: {
                region: link.code
              }
            }} key={link.code}
            class={classCollection}>
              <div class="header-tabs__item-text">{link.name}</div>
              {countBasket > 0 && !link.selected &&
                <div class="header-tabs__item-basket-count">
                  {countBasket}
                </div>
              }
            </nuxt-link>
          )
        })}
      </div>
    )
  }
}
