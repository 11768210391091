import { Plugin } from '@nuxt/types'
import { getCookieClient, parseCookiesServer } from '~/utils/cookie'

const construction: Plugin = (context) => {
  const keyPressed: string[] = []
  const keyword = 'iamadmin'

  if (process.browser) {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (keyPressed.length === keyword.length) {
        keyPressed.shift()
      }

      keyPressed.push(e.key)

      if (keyPressed.join('') === keyword) {
        const cokkie = `test-api=Y; domain=${context.store.getters['general/host']}; path=/;` + 'max-age=3600'
        document.cookie = cokkie
        window.location.reload()
      }
    })
  }
  let constructionValue = ''
  if (process.server) {
    const cookies = parseCookiesServer(context.req)
    constructionValue = cookies['test-api'] || ''
  } else if (process.client) {
    constructionValue = getCookieClient('test-api') || ''
  }

  context.store.$api.setConstructionHeader(constructionValue)
}

export default construction
