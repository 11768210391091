import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'
import OrderRatingStep from '~/types/enum/OrderRatingStep'
import OrderRatingStepStars from '~/components/general/popups/oraderRatingSteps/OrderRatingStepStars'
import OrderRatingStepComment from '~/components/general/popups/oraderRatingSteps/OrderRatingStepComment'

@Component
export default class OrderRatingPopup extends Vue {
  render () {
    if (OrderRatingStep.Stars === s(this.$store).orderRating.orderRatingStep) {
      return <OrderRatingStepStars />
    } else if (OrderRatingStep.Comment === s(this.$store).orderRating.orderRatingStep) {
      return <OrderRatingStepComment />
    }
  }
}
