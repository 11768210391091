import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const trainingMiddleware: Middleware = (context: Context) => {
  if (context.app.$cookies.get('training') === true) {
    s(context.store).general.setIsShowTraining(false)
  } else {
    s(context.store).general.setIsShowTraining(true)
  }
}

export default trainingMiddleware
