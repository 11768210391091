import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Store } from 'vuex'
import Api from '~/utils/api/Api'
import DebugApi from '~/utils/api/DebugApi'
import ServerApi from '~/utils/api/ServerApi'

export function initializeApi (isDebug: boolean, axiosInstance: NuxtAxiosInstance, store: Store<any>): Api {
  if (isDebug) {
    return new DebugApi()
  } else {
    return new ServerApi(axiosInstance, store)
  }
}
