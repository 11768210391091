import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as animate from 'gsap'
import FilterItem from '~/types/api/filter/FilterItem'
import FilterStore from '~/store/filter'
import FilterRangeOptions from '~/types/api/options/FilterRangeOptions'
import ArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import FilterOptions from '~/types/api/options/FilterOptions'

@Component
export default class FilterRange extends Vue {
  isShow: boolean = true
  pMin: number = 0
  pMax: number = 100000000

  @Prop({ required: true, type: Object })
  readonly item!: FilterItem

  @Prop({ required: true, type: Object })
  readonly store!: FilterStore

  @Prop({ default: false, type: Boolean })
  readonly isPrice!: boolean

  created () {
    const queryRange = this.store.selectedFilter[this.item.code] as FilterRangeOptions
    if (queryRange) {
      this.pMin = queryRange.MIN
      this.pMax = queryRange.MAX
    }
  }

  @Watch('store.selectedFilter', { immediate: true })
  onQueryChange (value: FilterOptions) {
    const queryRange = value[this.item.code] as FilterRangeOptions
    if (queryRange) {
      this.pMin = queryRange.MIN
      this.pMax = queryRange.MAX
    }
  }

  enter (el: any, done: any) {
    animate.gsap.to(el, {
      opacity: 1,
      height: 'auto',
      onComplete: done,
      ease: animate.Linear.easeNone
    }).delay(0).duration(0.3)
  }

  beforeEnter (el: any, _done: any) {
    el.style.opacity = 0
    el.style.height = 0
  }

  leave (el: any, done: any) {
    animate.gsap.to(el, {
      opacity: 0,
      height: 0,
      onComplete: done,
      ease: animate.Linear.easeNone
    }).delay(0.1).duration(0.3)
  }

  get min () {
    const minValue = this.item.range?.min ?? 0
    if (minValue > this.pMin) {
      return minValue
    } else {
      return this.pMin
    }
  }

  get max () {
    const maxValue = this.item.range?.max ?? 1
    if (maxValue < this.pMax) {
      return maxValue
    } else {
      return this.pMax
    }
  }

  set min (val: number) {
    const min = this.item.range?.min ?? 1
    if (val > this.max) {
      val = this.max
    } else if (val < min) {
      val = min
    }
    this.store.setValue({
      code: this.item.code,
      type: this.item.view,
      val: {
        MIN: val,
        MAX: this.max
      }
    })
  }

  set max (val: number) {
    const max = this.item.range?.max ?? 1
    if (val < this.min) {
      val = this.min
    } else if (val > max) {
      val = max
    }
    this.store.setValue({
      code: this.item.code,
      type: this.item.view,
      val: {
        MIN: this.min,
        MAX: val
      }
    })
  }

  render () {
    return (
      <div class="filter-range">
        <div class="filter-range__header" onClick={() => { this.isShow = !this.isShow }}>
          <div class="filter-range__header-name">
            {this.item.name}
          </div>
          <div {...{
            class: {
              'filter-range__header-arrow': true,
              'filter-range__header-arrow_open': this.isShow
            }
          }} >
            <ArrowSvg />
          </div>
        </div>
        <transition {...{
          props: {
            name: 'filter-range__animate',
            css: false
          },
          on: {
            'before-enter': this.beforeEnter,
            enter: this.enter,
            leave: this.leave
          }
        }}>
          {this.isShow &&
          <div class="filter-range__content">
            <div class="filter-range__item">
              <div class="filter-range__input">
                <input vModel_number_lazy={this.min} {...{
                  attrs: {
                    type: 'text',
                    name: `${this.item.code}_min`
                  }
                }} />
              </div>
              {this.isPrice &&
                <div class="filter-range__symbol">₽</div>
              }
            </div>
            <div class="filter-range__separator">
              -
            </div>
            <div class="filter-range__item">
              <div class="filter-range__input">
                <input vModel_number_lazy={this.max} {...{
                  attrs: {
                    type: 'text',
                    name: `${this.item.code}_max`
                  }
                }} />
              </div>
              {this.isPrice &&
                <div class="filter-range__symbol">₽</div>
              }
            </div>
          </div>
          }
        </transition>
      </div>
    )
  }
}
