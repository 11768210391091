import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import * as sort from '~/utils/config/productSort'
import * as count from '~/utils/config/productCount'
import ListResponse from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import NotificationType from '~/types/enum/NotificationType'
import config from '~/utils/config'
import s from '~/utils/s'

@Module({
  name: 'wishlist',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _ids: string[] = []
  _list: ListResponse<Product> = {
    items: [],
    count: 0
  }

  _limit: number = 15
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  @Mutation
  setIds (ids: string[]) {
    this._ids = ids
  }

  @Mutation
  setList (list: ListResponse<Product>) {
    this._list = list
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Action({ rawError: config.rawError })
  async changeProduct (productId: string) {
    s(this.store).general.enableLoader()
    let result
    const inList = this.ids.filter(i => i === productId).length > 0

    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    if (inList) {
      result = await this.store.$api.removeFromFavorite(productId, regionId, sectionId)
    } else {
      result = await this.store.$api.addToFavorite(productId, regionId, sectionId)
    }

    if (result.code === 200) {
      let notificationText = 'Товар добавлен в избранноe'
      if (inList) {
        notificationText = 'Товар удален из избранного'
      }
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: notificationText
      })
      this.updateIds()
    } else if (result.error) {
      s(this.store).notification.addNotification({
        type: NotificationType.Warning,
        text: result.error.description
      })
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Неизвестная ошибка'
      })
    }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async updateIds () {
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId

    const result = await this.store.$api.getFavoritesIds(regionId, sectionId)
    
    if (result.code === 200 && result.data) {
      this.setIds(result.data.ids)
    } else {
      this.setIds([])
    }

    if (this.store.$router.currentRoute.name === 'region-wishlist') {
      this.enableLoader()
      await this.loadProducts()
    }
  }

  @Action({ rawError: config.rawError })
  async loadProducts (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    // если переход из другой страницы, непоказывает лоадер
    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    s(this.store).wishlistFilter.fillQueryFilter(route)
    const query = route.query
    const limit = count.checkValue(query.count)
    const sortType = sort.checkValue(query.sort)
    let page = 0
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
    }
    const offset = page * limit
    const sectionId = s(this.store).regions.sectionId

    const result = await this.store.$api.getFavorites({
      limit,
      offset,
      regionId: s(this.store).regions.regionId ?? 0,
      sortType,
      filter: s(this.store).wishlistFilter.queryFilter,
      sectionId
    })

    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({ top: offsetScroll, left: 0 }), 0)
        this.disableLoadMore()
      } else {
        this.setList(result.data)
      }
      this.setIds(result.data.ids)
      s(this.store).wishlistFilter.setList(result.data.filters)
      await s(this.store).wishlistFilter.fillQueryFilter(route)
    } else {
      this.setList({
        items: [],
        count: 0
      })
    }
    this.setLimit(limit)
    this.setPage(page)
    s(this.store).general.disableLoader()
  }

  get ids (): string[] {
    return this._ids
  }

  get list (): ListResponse<Product> {
    return this._list
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }
}
