import { Vue, Component } from 'vue-property-decorator'
import PhoneSvg from '~/assets/svg/footer-phone.svg?inline'
import EmailSvg from '~/assets/svg/footer-email.svg?inline'
import s from '~/utils/s'

@Component
export default class FooterContact extends Vue {
  render () {
    return (
      <div class="common-footer-contact">
        <a href={s(this.$store).regions.phoneLink} class="common-footer-contact__item common-footer-contact__item_important">
          <div class="common-footer-contact__logo">
            <PhoneSvg />
          </div>
          <div class="common-footer-contact__text">
            {s(this.$store).regions.phone}
          </div>
        </a>
        <a href={'mailto:' + s(this.$store).helpPages.email} class="common-footer-contact__item">
          <div class="common-footer-contact__logo">
            <EmailSvg />
          </div>
          <div class="common-footer-contact__text">
            {s(this.$store).helpPages.email}
          </div>
        </a>
      </div>
    )
  }
}
