import { Vue, Component } from 'vue-property-decorator'
import PhoneSvg from '~/assets/svg/header-phone.svg?inline'
import ManSvg from '~/assets/svg/header-man.svg?inline'
import HeaderLocation from '~/components/general/header/HeaderLocation'
import HeaderPersonal from '~/components/general/header/HeaderPersonal'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component({
  components: {
    PhoneSvg,
    ManSvg
  }
})
export default class HeaderLinks extends Vue {
  render () {
    return (
      <div class="header-links">
        <div class="header-links__row">
          <div class="header-links__col-delivery">
            <nuxt-link to={loc(this.$store, { name: 'help-delivery-payment' })} class="header-link header-link_type_delivery">
              <div class="header-link__text">
                Доставка и оплата
              </div>
            </nuxt-link>
          </div>
          <div class="header-links__col-phone-location">
            <div class="header-links__phone">
              <a href={s(this.$store).regions.phoneLink} class="header-link header-link_great">
                <div class="header-link__icon">
                  <PhoneSvg />
                </div>
                <div class="header-link__text">
                  {s(this.$store).regions.phone}
                </div>
              </a>
            </div>
            <div class="header-links__location">
              <HeaderLocation />
            </div>
          </div>
          <div class="header-links__col-personal">
            <HeaderPersonal />
          </div>
        </div>
      </div>
    )
  }
}
