import { Component, Prop, Vue } from 'vue-property-decorator'
import FilterItem from '~/types/api/filter/FilterItem'
import FilterType from '~/types/api/filter/FilterType'
import FilterCheckoxList from '~/components/block/filter/FilterCheckoxList'
import FilterRange from '~/components/block/filter/FilterRange'
import FilterSwitch from '~/components/block/filter/FilterSwitch'
import FilterStore from '~/store/filter'
import RemoveFilterSvg from '~/assets/svg/remove-filter.svg?inline'
import FilterRangeOptions from '~/types/api/options/FilterRangeOptions'
import s from '~/utils/s'
import UnknownPageType from '~/types/enum/UnknownPageType'
import FilterSectionChildren from '~/components/block/filter/FilterSectionChildren'
import declOfNum from '~/utils/declOfNum'

interface SelectedItem {
  value: string,
  code: string,
  name: string
  text?: string;
}

@Component
export default class FilterProduct extends Vue {
  openedCheckBox: string[] = []

  @Prop({ required: true, type: Object })
  readonly store!: FilterStore

  get textProduct (): string {
    return declOfNum(this.countProduct, [
      'товар',
      'товара',
      'товаров'
    ])
  }

  closePopup () {
    s(this.$store).general.closePopup()
  }

  isFilterEmpty (filterItem: FilterItem): boolean {
    return filterItem.view === FilterType.Checkbox && (filterItem.values?.length === 0 || filterItem.valuesId?.length === 0)
  }

  get countProduct (): any {
    const pages: { [key: string]: number } = {
      'region-all': s(this.$store).categoryPage.category.count,
      'region-wishlist': s(this.$store).wishlist.list.count,
      'region-userProductList': s(this.$store).userProductList.items.count,
      'region-search': s(this.$store).searchPage.list.count,
      'region-catalog-popular': s(this.$store).popularPage.list.count,
      'region-catalog-sale': s(this.$store).salePage.list.count
    }
    if (this.$route.name) {
      return pages[this.$route.name]
    }
  }

  get selectedItems (): SelectedItem[] {
    const selectedKeys = Object.keys(this.store.selectedFilter)
    return this.store.list.filter(e => selectedKeys.includes(e.code)).map((e) => {
      const entity = this.store.selectedFilter[e.code]
      let mapLabels: string[] = []

      if (Array.isArray(entity)) {
        mapLabels = entity.map(e => s(this.$store).categoryFilter._filterLabels[e])
      }

      let value = ''
      if (e.view === FilterType.Price) {
        value = `от ${(entity as FilterRangeOptions).MIN} ₽ до ${(entity as FilterRangeOptions).MAX} ₽`
      } else if (e.view === FilterType.Range) {
        value = `от ${(entity as FilterRangeOptions).MIN} до ${(entity as FilterRangeOptions).MAX}`
      } else if (e.view === FilterType.Checkbox) {
        value = mapLabels.length ? (mapLabels as string[] ?? []).join(', ') : (entity as string[] ?? []).join(', ')
      } else if (e.view === FilterType.FlagBoolean || e.view === FilterType.FlagNumeric) {
        value = 'Да'
      }
      return {
        value,
        code: e.code,
        name: e.name
      }
    }).filter(e => e.value)
  }

  toggleCheckBox (code: string, event: any) {
    if (event.isOpen === false && this.openedCheckBox.includes(code)) {
      const index = this.openedCheckBox.indexOf(code)
      this.openedCheckBox.splice(index, 1)
    } else if (event.isOpen === true && !this.openedCheckBox.includes(code)) {
      this.openedCheckBox.push(code)
    }
    this.$emit('open', event)
  }

  renderItem (e: FilterItem) {
    const props = {
      item: e,
      store: this.store
    }

    switch (e.view) {
      case FilterType.Checkbox:
        return <FilterCheckoxList {...{
          props: {
            ...props,
            isShowMore: this.openedCheckBox.includes(e.code)
          },
          on: {
            open: (event: any) => this.toggleCheckBox(e.code, event)
          }
        }} />
      case FilterType.Price:
        return <FilterRange props={{
          ...props,
          isPrice: true
        }} />
      case FilterType.Range:
        return <FilterRange props={props} />
      case FilterType.FlagNumeric:
      case FilterType.FlagBoolean:
        return <FilterSwitch props={props} />
    }
  }

  renderSelected () {
    return (
      <div class="filter-product__selected">
        {this.selectedItems.map(e => (
          <div key={e.code} class="filter-product__selected-item" onClick={() => this.store.clearByCode(e.code)}>
            <div class="filter-product__selected-name">
              {e.name}:
            </div>
            <div class="filter-product__selected-value">
              {e.value}
            </div>
            <div class="filter-product__selected-close">
              <RemoveFilterSvg />
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderReset () {
    return (
      <div class="filter-product__reset" onClick={() => this.store.clearFilter()}>
        <div class="filter-product__reset-text">
          Сбросить фильтры
        </div>
      </div>
    )
  }

  renderSections () {
    const isCatalogPage = this.$route.name === 'region-all'
    const isCategoryPage = s(this.$store).unknownPage.pageType === UnknownPageType.Category
    if (isCatalogPage && isCategoryPage) {
      const categoryId = s(this.$store).categoryPage.category.id
      const sections = s(this.$store).general.sections.filter(e => e.parentId === categoryId)
      if (sections.length > 0) {
        return (
          <div class="filter-product__sections">
            <FilterSectionChildren props={{
              items: sections,
              section: s(this.$store).general.sections.find(e => e.id === categoryId)
            }} />
          </div>
        )
      }
    }
  }

  render () {
    return (
      <div class="filter-product">
        {this.renderSections()}
        {Object.keys(this.store.selectedFilter).length > 0 &&
          <div class="filter-product__manage">
            {this.renderSelected()}
            {this.renderReset()}
          </div>
        }
        <div class="filter-product__list">
          {this.store.list.map((e) => {
            if (e.code === 'code') {
              if (s(this.$store).regions.section?.eCertificate) {
                return (<div key={e.id} class="filter-product__item">
                  {this.renderItem(e)}
                </div>)
              }
            } else if (!this.isFilterEmpty(e)) {
              return (<div key={e.id} class="filter-product__item">
                {this.renderItem(e)}
              </div>)
            }
          }
          )}
        </div>
        <div class="button-text" onclick={this.closePopup}>
          Показать&nbsp;{this.countProduct}&nbsp;{this.textProduct}
        </div>
      </div>
    )
  }
}
