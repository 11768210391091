import { Vue, Component } from 'vue-property-decorator'
import FooterLogo from '~/components/general/footer/FooterLogo'
import FooterContact from '~/components/general/footer/FooterContact'
import FooterSocial from '~/components/general/footer/FooterSocial'
import FooterInfo from '~/components/general/footer/FooterInfo'
import LinkItem from '~/types/LinkItem'
import FooterMenu from '~/components/general/footer/FooterMenu'
import FooterApps from '~/components/general/footer/FooterApps'
import FooterCopyright from '~/components/general/footer/FooterCopyright'
import * as itemsMenu from '~/utils/config/itemsMenu'
import s from '~/utils/s'
import StaticPage from '~/types/api/StaticPage'
import loc from '~/utils/loc'
import FooterCallback from '~/components/general/footer/FooterCallback'

@Component
export default class Footer extends Vue {
  menuHeaderHelp = 'Помощь'
  menuHeaderAbout = 'О компании'
  staticPages: StaticPage[] = []
  licenses: LinkItem[] = []

  get helpMenu (): LinkItem[] {
    return itemsMenu.helpMenu(this.$store)
  }

  get aboutMenu (): LinkItem[] {
    return itemsMenu.aboutMenu(this.$store)
  }

  async mounted() {
    const regionId = s(this.$store).regions.regionId
    const sectionId = s(this.$store).regions.sectionId
    await s(this.$store).staticPages.getStaticPages({ regionId, sectionId })
    await s(this.$store).staticPages.getLicenses({ regionId, sectionId })
    this.staticPages = s(this.$store).staticPages.staticPages
    this.licenses = s(this.$store).staticPages.licenses.map(license => ({
      title: license.title,
      location: license.url,
      isBlank: true
    }))
  }

  render () {
    return (
      !s(this.$store).general.isLoaded &&
      <footer class="common-footer">
        <div class="common-footer__container">
          <div class="common-footer__row">
            <div class="common-footer__col-logo">
              <FooterLogo />
            </div>
            <div class="common-footer__col-contact">
              <FooterContact />
            </div>
            <div class="common-footer__col-menu-header-help">
              <div class="common-footer__menu-header">
                {this.menuHeaderHelp}
              </div>
            </div>
            <div class="common-footer__col-menu-header-about">
              <div class="common-footer__menu-header">
                {this.menuHeaderAbout}
              </div>
            </div>
            <div class="common-footer__col-social">
              <FooterSocial />
            </div>
            <div class="common-footer__col-info">
              <FooterInfo />
            </div>
            <div class="common-footer__col-help">
              <FooterMenu {...{
                props: {
                  header: this.menuHeaderHelp,
                  items: this.helpMenu.concat(this.staticPages.filter(p => p.type === 'Помощь').map(p => ({
                    title: p.title,
                    location: loc(this.$store, { name: 'help-code', params: { code: p.code } })
                  })))
                }
              }} />
            </div>
            <div class="common-footer__col-about">
              <FooterMenu {...{
                props: {
                  header: this.menuHeaderAbout,
                  items: this.aboutMenu.concat(this.licenses).concat(this.staticPages.filter(p => p.type === 'О компании').map(p => ({
                    title: p.title,
                    location: loc(this.$store, { name: 'about-code', params: { code: p.code } })
                  })))
                }
              }} />
            </div>
            <div class="common-footer__col-apps">
              <div class="common-footer__feedback-button">
                <FooterCallback />
              </div>
              <FooterApps />
            </div>
            <div class="common-footer__col-copyright">
              <FooterCopyright />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
