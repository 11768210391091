import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import StarSvg from '~/assets/svg/star.svg?inline'
import StarFilledSvg from '~/assets/svg/star-product.svg?inline'
import declOfNum from '~/utils/declOfNum'

@Component({
  components: {
    ValidationProvider
  }
})
export default class RatingStars extends Vue {
  @Prop({ type: Number, required: true })
  readonly rating?: number

  @Prop({ type: Number })
  readonly reviewsCount?: number;

  static maxValue: number = 5

  get textReviewCount (): string {
    return declOfNum(this.reviewsCount || 0, [
      'отзыв',
      'отзыва',
      'отзывов'
    ])
  }

  renderReviewsCount () {
    return (
      <span>
        { this.reviewsCount || 'Нет' }
        { ' ' + this.textReviewCount }
      </span>
    )
  }

  renderStar (index: number) {
    const isFilled = index && this.rating && index <= Math.round(this.rating)

    return (
      <div class={{
        rating__star: true,
        rating__star_filled: isFilled
      }}>
        { isFilled ? <StarFilledSvg /> : <StarSvg /> }
      </div>
    )
  }

  render () {
    const stars = [...Array(RatingStars.maxValue)]

    return (
      <div class="rating">
        { stars.map((v, i) => this.renderStar(i + 1)) }

        <div class="rating__reviews-count">
          { this.reviewsCount !== undefined && this.renderReviewsCount() }
        </div>
      </div>
    )
  }
}
