import { Vue, Component } from 'vue-property-decorator'
import SmsAuthorization, { Step } from '~/components/general/SmsAuthorization'
import s from '~/utils/s'

@Component
export default class PhoneConfirmationPopup extends Vue {
  async onAuthSuccess () {
    s(this.$store).general.closePopup()
    await s(this.$store).reviewsPage.fetchUserReview()
    this.$forceUpdate()
  }

  render () {
    return (
      <SmsAuthorization {...{
        props: {
          onAuthSuccess: this.onAuthSuccess,
          step: Step.Code,
          phoneChange: true
        }
      }}/>
    )
  }
}
