import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ProductDetail from '~/types/api/ProductDetail'
import SectionHelper from '~/utils/sectionHelper'
import config from '~/utils/config'
import Img from '~/types/api/Img'
import ProductDetailProperty from '~/types/api/ProductDetailProperty'
import Product from '~/types/api/Product'
import s from '~/utils/s'
import ListResponse from '~/types/api/ListResponse'
import Review from '~/types/api/Review'
import Rating from '~/types/api/Rating'
import { SfSection } from '~/types/api/SfSection'
import ProductDetailOptions from '~/types/api/options/ProductDetailOptions'

const clearDetail: ProductDetail = {
  descriptionHTML: '',
  id: '',
  sectionId: [],
  title: '',
  code: '',
  description: '',
  trait: '',
  img: undefined,
  byPrescription: false,
  isVeterinary: false,
  isProductHit: false,
  isProductDay: false,
  isProductNew: false,
  mnn: '',
  releaseForm: '',
  primaryPackaging: '',
  manufacturer: '',
  country: '',
  basePrice: '0',
  price: '0',
  available: 0,
  textProps: [],
  certificates: [],
  canReview: false,
  canUserReview: false,
  rating: {
    value: 0,
    reviews: {
      total: 0,
      byRating: []
    }
  },
  reviews: {
    count: 0,
    items: []
  },
  promoAction: false,
  images: [],
  categoryIds: []
}

@Module({
  name: 'productPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _pathSections: SfSection[] = []
  _id: string = ''
  _sectionId: string[] = []
  _title: string = ''
  _code: string = ''
  _description: string = ''
  _descriptionHTML: string = ''
  _trait: string = ''
  _img?: Img = undefined
  _byPrescription: boolean = false
  _isVeterinary: boolean = false
  _isProductDay: boolean = false
  _isProductNew: boolean = false
  _isProductHit: boolean = false
  _expirationDate?: string
  _expirationTooltip?: string
  _mnn: string = ''
  _releaseForm: string = ''
  _primaryPackaging: string = ''
  _manufacturer: string = ''
  _country: string = ''
  _basePrice: number = 0
  _price: number = 0
  _available: number = 0
  _textProps: ProductDetailProperty[] = []
  _certificates: Img[] = []
  _recommended: Product[] = []
  _analogs: Product[] = []
  _canReview: boolean = false
  _canUserReview: boolean = false
  _rating: Rating = {
    value: 0,
    reviews: {
      total: 0,
      byRating: []
    }
  }

  _images: Img[] = []

  _promoAction: boolean = false
  _viewedProducts: ListResponse<Product> = {
    count: 0,
    items: []
  }

  _reviews: Review[] = []

  @Mutation
  setPathSections (pathSections: SfSection[]) {
    this._pathSections = pathSections
  }

  @Mutation
  setId (val: string) {
    this._id = val
  }

  @Mutation
  setSectionId (val: string[]) {
    this._sectionId = val
  }

  @Mutation
  setTitle (val: string) {
    this._title = val
  }

  @Mutation
  setCode (val: string) {
    this._code = val
  }

  @Mutation
  setDescription (val: string) {
    this._description = val
  }

  @Mutation
  setDescriptionHTML (val: string) {
    this._descriptionHTML = val
  }

  @Mutation
  setTrait (val: string) {
    this._trait = val
  }

  @Mutation
  setImg (val?: Img) {
    this._img = val
  }

  @Mutation
  setByPrescription (val: boolean) {
    this._byPrescription = val
  }

  @Mutation
  setIsVeterinary (val: boolean) {
    this._isVeterinary = val
  }

  @Mutation
  setIsProductDay (val: boolean) {
    this._isProductDay = val
  }

  @Mutation
  setIsProductNew (val: boolean) {
    this._isProductNew = val
  }

  @Mutation
  setIsProductHit (val: boolean) {
    this._isProductHit = val
  }

  @Mutation
  setExpirationDate (val?: string) {
    this._expirationDate = val
  }

  @Mutation
  setExpirationTooltip (val?: string) {
    this._expirationTooltip = val
  }

  @Mutation
  setMnn (val: string) {
    this._mnn = val
  }

  @Mutation
  setReleaseForm (val: string) {
    this._releaseForm = val
  }

  @Mutation
  setPrimaryPackaging (val: string) {
    this._primaryPackaging = val
  }

  @Mutation
  setManufacturer (val: string) {
    this._manufacturer = val
  }

  @Mutation
  setCountry (val: string) {
    this._country = val
  }

  @Mutation
  setBasePrice (val: string) {
    this._basePrice = parseFloat(val)
  }

  @Mutation
  setPrice (val: string) {
    this._price = parseFloat(val)
  }

  @Mutation
  setAvailable (val: number) {
    this._available = val
  }

  @Mutation
  setTextProps (val: ProductDetailProperty[]) {
    this._textProps = val
  }

  @Mutation
  setCertificates (val: Img[]) {
    this._certificates = val
  }

  @Mutation
  setRecommended (val: Product[]) {
    this._recommended = val
  }

  @Mutation
  setAnalogs (val: Product[]) {
    this._analogs = val
  }

  @Mutation
  setRating (val: Rating) {
    this._rating = val
  }

  @Mutation
  setPromoAction (val: boolean) {
    this._promoAction = val
  }

  @Mutation
  setViewedProducts (viewedProducts: ListResponse<Product>) {
    this._viewedProducts = viewedProducts
  }

  @Mutation
  setCanReview (canReview: boolean) {
    this._canReview = canReview
  }

  @Mutation
  setCanUserReview (canUserReview: boolean) {
    this._canUserReview = canUserReview
  }

  @Mutation
  setReviews (reviews: Review[]) {
    this._reviews = reviews
  }

  @Mutation
  setImages (images: Img[]) {
    this._images = images
  }

  @Action({ rawError: config.rawError })
  setProduct (product: ProductDetail) {
    const result = Object.assign({}, clearDetail, product)
    this.setId(result.id)
    this.setSectionId(result.categoryIds)
    this.setTitle(result.title)
    this.setCode(result.code)
    this.setDescription(result.description)
    this.setDescriptionHTML(result.descriptionHTML)
    this.setTrait(result.trait)
    this.setImg(result.img)
    this.setImages(result.images)
    this.setByPrescription(result.byPrescription)
    this.setIsVeterinary(result.isVeterinary)
    this.setIsProductDay(result.isProductDay)
    this.setIsProductNew(result.isProductNew)
    this.setIsProductHit(result.isProductHit)
    this.setExpirationDate(result.expirationDate)
    this.setExpirationTooltip(result.expirationTooltip)
    this.setMnn(result.mnn)
    this.setReleaseForm(result.releaseForm)
    this.setPrimaryPackaging(result.primaryPackaging)
    this.setManufacturer(result.manufacturer)
    this.setCountry(result.country)
    this.setBasePrice(result.basePrice)
    this.setPrice(result.price)
    this.setAvailable(result.available)
    this.setTextProps(result.textProps)
    this.setCertificates(result.certificates)
    // this.setRecommended(result.recommended)
    // this.setAnalogs(result.analogs)
    this.setRating(result.rating)
    // this.setReviews(result.reviews.items)
    this.setPromoAction(result.promoAction)
    this.setCanReview(result.canReview)
    this.setCanUserReview(result.canUserReview)
  }

  @Action({ rawError: config.rawError })
  clearAnalogsAndRecommended () {
    this.setAnalogs([])
    this.setRecommended([])
  }

  @Action({ rawError: config.rawError })
  async loadReviewsForProduct (productId: string, regionId: string) {
    const reviewsResult = await this.store.$api.getReviews({
      productId,
      regionId,
      offset: 0,
      limit: 5
    })

    if (reviewsResult.data) {
      await s(this.store).productPage.setReviews(reviewsResult.data?.items)
    }
  }

  @Action({ rawError: config.rawError })
  async loadProduct (product: ProductDetail) {
    const helper = new SectionHelper(s(this.store).general.sections, this.store)
    this.setPathSections(helper.getSectionsPath(product.categoryIds?.[0]))
    this.setProduct(product)

    /* this.store.$api.addProductViewed({
      productId: product.id,
      regionId: s(this.store).regions.section?.id ?? 0
    }).then() */
  }

  @Action({ rawError: config.rawError })
  async loadRecommended (options: ProductDetailOptions) {
    const result = await this.store.$api.getRecommendedProducts(options)
    if (result.data) {
      this.setRecommended(result.data.items)
    } else {
      this.setRecommended([])
    }
  }

  @Action({ rawError: config.rawError })
  async loadAnalogs (options: ProductDetailOptions) {
    const result = await this.store.$api.getAnalogs(options)
    if (result.data) {
      this.setAnalogs(result.data.items)
    } else {
      this.setAnalogs([])
    }
  }

  @Action({ rawError: config.rawError })
  async loadViewedProducts () {
    const result = await this.store.$api.getProductViewed({
      limit: 5,
      offset: 0,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId
    })

    if (result.code === 200 && result.data) {
      this.setViewedProducts(result.data)
    } else {
      this.setViewedProducts({
        count: 0,
        items: []
      })
    }
  }

  get pathSections (): SfSection[] {
    return this._pathSections
  }

  get id (): string {
    return this._id
  }

  get sectionId (): string[] {
    return this._sectionId
  }

  get title (): string {
    return this._title
  }

  get code (): string {
    return this._code
  }

  get description (): string {
    return this._description
  }

  get descriptionHTML (): string {
    return this._descriptionHTML
  }

  get trait (): string {
    return this._trait
  }

  get img (): Img | undefined {
    return this._img
  }

  get byPrescription (): boolean {
    return this._byPrescription
  }

  get isVeterinary (): boolean {
    return this._isVeterinary
  }

  get isProductDay (): boolean {
    return this._isProductDay
  }

  get isProductNew (): boolean {
    return this._isProductNew
  }

  get isProductHit (): boolean {
    return this._isProductHit
  }

  get expirationDate (): string | undefined {
    return this._expirationDate
  }

  get expirationTooltip (): string | undefined {
    return this._expirationTooltip
  }

  get mnn (): string {
    return this._mnn
  }

  get releaseForm (): string {
    return this._releaseForm
  }

  get primaryPackaging (): string {
    return this._primaryPackaging
  }

  get manufacturer (): string {
    return this._manufacturer
  }

  get country (): string {
    return this._country
  }

  get basePrice (): number {
    return this._basePrice
  }

  get price (): number {
    return this._price
  }

  get available (): number {
    return this._available
  }

  get textProps (): ProductDetailProperty[] {
    return this._textProps
  }

  get certificates (): Img[] {
    return this._certificates
  }

  get recommended (): Product[] {
    return this._recommended
  }

  get analogs (): Product[] {
    return this._analogs
  }

  get rating (): Rating {
    return this._rating
  }

  get promoAction (): boolean {
    return this._promoAction
  }

  get viewedProducts (): ListResponse<Product> {
    return this._viewedProducts
  }

  get canReview (): boolean {
    return this._canReview
  }

  get canUserReview (): boolean {
    return this._canUserReview
  }

  get reviews (): Review[] {
    return this._reviews
  }

  get images (): Img[] {
    return this._images
  }
}
