import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import Pharmacy from '~/types/api/pharmacy/Pharmacy'
import config from '~/utils/config'
import City from '~/types/api/pharmacy/City'
import Disctrict from '~/types/api/pharmacy/Disctrict'
import s from '~/utils/s'

@Module({
  name: 'pharmaciesPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _list: Pharmacy[] = []
  _cities: City[] = []
  _disctricts: Disctrict[] = []

  @Mutation
  setList (list: Pharmacy[]) {
    list.forEach((item) => {
      item.point = {
        lat: item.lat,
        lng: item.lon
      }
    })
    this._list = list
  }

  @Mutation
  setCities (items: City[]) {
    this._cities = items
  }

  @Mutation
  setDisctricts (items: Disctrict[]) {
    this._disctricts = items
  }

  @Action({ rawError: config.rawError })
  async fillData () {
    const result = await this.store.$api.getPharmacies()
    if (result.code === 200 && result.data) {
      this.setList(result.data.pharmacies)
      this.setCities(result.data.cities)
      this.setDisctricts(result.data.districts)
      await s(this.store).seoTextBlock.loadSeoText('pharmacies')
    }
  }

  @Action({ rawError: config.rawError })
  async getPharmacyById (id: string) {
    const result = await this.store.$api.getPharmacyById(id)
    return result
  }

  get list (): Pharmacy[] {
    return this._list
  }

  get cities () {
    return this._cities
  }

  get disctricts () {
    return this._disctricts
  }
}
