import { Vue, Component, Prop } from 'vue-property-decorator'
import FilterItem from '~/types/api/filter/FilterItem'
import FilterStore from '~/store/filter'
import SwitchElement from '~/components/element/SwitchElement'

@Component
export default class FilterSwitch extends Vue {
  isShow: boolean = true

  @Prop({ required: true, type: Object })
  readonly item!: FilterItem

  @Prop({ required: true, type: Object })
  readonly store!: FilterStore

  toggle () {
    this.isShow = !this.isShow
  }

  getSelectStatus () {
    const values = this.store.selectedFilter[this.item.code]
    return values !== undefined
  }

  change (value: boolean, codeFilter: string) {
    this.store.setValue({
      code: codeFilter,
      type: this.item.view,
      val: value
    })
  }

  render () {
    return (
      <div class={{
        'filter-switch': true
      }}>
        {this.isShow &&
        <div class="filter-switch__content">
          <div class="filter-switch__item">
            <SwitchElement {...{
              props: {
                value: this.getSelectStatus(),
                name: this.item.code,
                label: this.item.name,
                modifiers: ['filter']
              },
              on: {
                change: (v: boolean) => this.change(v, this.item.code)
              },
              scopedSlots: {
                textPayload: () => this.item.name
              }
            }} />
          </div>
        </div>
        }
      </div>
    )
  }
}
