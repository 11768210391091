import { Vue, Component } from 'vue-property-decorator'
import FbSvg from '~/assets/svg/footer-fb.svg?inline'

@Component
export default class FooterCopyright extends Vue {
  getCurrentYear (): number {
    return new Date().getFullYear()
  }

  render () {
    return (
      <div class="common-footer-copyright">
        <div class="common-footer-copyright__copyright">
          © {this.getCurrentYear()} АО «Губернские аптеки»
        </div>
        {/* <a href="#" class="common-footer-copyright__dev-link">
          <FbSvg />
        </a> */}
      </div>
    )
  }
}
