import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import config from '~/utils/config'
import SeoTextBlock from '~/types/api/SeoTextBlock'
import s from '~/utils/s'

@Module({
  name: 'seoTextBlock',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _seoTextBlock: SeoTextBlock = {
    id: 0,
    text: '',
    img: { m: '', d: '', t: '' }
  }

  @Mutation
  setSeoTextBlock (seoTextBlock: SeoTextBlock) {
    this._seoTextBlock = seoTextBlock
  }

  @Action({ rawError: config.rawError })
  async loadSeoText (codePageName: string) {
    /* const result = await this.store.$api.getSeoTextBlock({
      regionId: s(this.store).regions.regionId ?? 0,
      codePage: codePageName
    })

    if (result.code === 200 && result.data) {
      this.setSeoTextBlock(result.data)
    } else {
      this.setSeoTextBlock({
        id: 0,
        text: '',
        img: { m: '', d: '', t: '' }
      })
    } */
  }

  get seoTextBlock (): SeoTextBlock {
    return this._seoTextBlock
  }
}
