import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import ListResponse from '~/types/api/ListResponse'
import Product from '~/types/api/Product'
import * as count from '~/utils/config/productCount'
import s from '~/utils/s'
import config from '~/utils/config'
import * as sort from '~/utils/config/productSort'
import { NuxtApp } from '@nuxt/types/app'

@Module({
  name: 'collectionProduct',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _list: ListResponse<Product> = {
    items: [],
    count: 0
  }

  // list
  _limit: number = 9
  _page: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  // start list mutation
  @Mutation
  setList (list: ListResponse<Product>) {
    this._list = list
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._list.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }
  // end list mutation

  @Action({ rawError: config.rawError })
  clearProductList () {
    this.setList({
      items: [],
      count: 0
    })
  }

  @Action({ rawError: config.rawError })
  async loadProductList (productListId: string, route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }
    const app = this.store.$router.app as NuxtApp
    const isDev = app.context.isDev

    s(this.store).general.enableLoader()

    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    s(this.store).productCardsCommercial.setProductCardsCommercial([])
    s(this.store).popularFilter.fillQueryFilter(route)
    const query = route.query
    const limit = count.checkValue(query.count)
    const sortType = sort.checkValue(query.sort)
    let page = 0
    if (typeof query.page === 'string' && !isNaN(parseInt(query.page, 10))) {
      page = parseInt(query.page, 10)
      if (page > 0) {
        page -= 1
      }
    }
    const offset = page * limit

    const result = await this.store.$api.getCollectionProductList({
      id: productListId,
      limit,
      offset,
      regionId: s(this.store).regions.regionId ?? 0,
      sortType,
      sectionId: s(this.store).regions.sectionId,
      filter: s(this.store).bannerFilter.queryFilter,
      devRequest: isDev
    })

    if (result.code === 200 && result.data) {
      if (this._isLoadMore) {
        const offsetScroll = window.scrollY
        this.setList({
          ...this._list,
          items: [...this._list.items, ...result.data.items]
        })
        setTimeout(() => window.scroll({ top: offsetScroll, left: 0 }), 0)
        this.disableLoadMore()
      } else {
        this.setList(result.data)
      }
      s(this.store).bannerFilter.setList(result.data.filters)
      await s(this.store).bannerFilter.fillSelectedFilter()
    } else {
      this.setList({
        items: [],
        count: 0
      })
    }
    this.setLimit(limit)
    this.setPage(page)
    s(this.store).general.disableLoader()
  }

  // start list props
  get list (): ListResponse<Product> {
    return this._list
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get pageCount (): number {
    return Math.ceil(this.list.count / this.limit)
  }
  // end list props
}
