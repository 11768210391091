import { Vue, Component } from 'vue-property-decorator'
import DiseaseSectionList from '~/components/block/DiseaseSectionList'
import s from '~/utils/s'

@Component
export default class DiseasesSectionsPopup extends Vue {
  render () {
    return (
      <div class="diseases-sections-popup">
        <div class="diseases-sections-popup__header">
          Разделы медицины
        </div>
        <div class="diseases-sections-popup__content">
          <DiseaseSectionList {...{
            props: {
              items: s(this.$store).diseasesPage.sections
            }
          }} />
        </div>
      </div>
    )
  }
}
