import { Vue, Component } from 'vue-property-decorator'
import SmsAuthorization from '~/components/general/SmsAuthorization'
import s from '~/utils/s'
import loc from '~/utils/loc'

@Component
export default class OrderAuthPopup extends Vue {
  async onAuthSuccess () {
    s(this.$store).general.closePopup()
    await this.$router.push(loc(this.$store, { name: 'order' }))
    // this.$forceUpdate()
  }

  render () {
    return (
      <SmsAuthorization {...{
        props: {
          onAuthSuccess: this.onAuthSuccess
        }
      }}/>
    )
  }
}
