import { Vue, Component } from 'vue-property-decorator'
import LocationSvg from '~/assets/svg/header-location.svg?inline'
import { Region } from '~/types/api/Region'
import s from '~/utils/s'

@Component
export default class HeaderLocation extends Vue {
  get selectedParentRegion (): Region | undefined {
    return s(this.$store).regions.region
  }

  openPopup (event: MouseEvent) {
    event.stopPropagation()
    s(this.$store).general.showPopup('RegionPopup')
  }

  render () {
    return (
      <div
        onClick={this.openPopup}
        class="header-link header-link_great header-link_type_location">
        <div class="header-link__icon">
          <LocationSvg />
        </div>
        <div class="header-link__text">
          {this.selectedParentRegion?.name}
        </div>
      </div>
    )
  }
}
