import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import License from '~/types/api/License'
import StaticPage from '~/types/api/StaticPage'
import config from '~/utils/config'
import s from '~/utils/s'

const initStaticPage = {
  id: '',
  title: '',
  code: '',
  HTMLContent: '',
  SEOTitle: '',
  SEODescription: '',
  SEOKeywords: '',
  type: '',
  images: [],
  imagesBlockName: ''
}

@Module({
  name: 'staticPages',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _staticPage: StaticPage = initStaticPage
  _staticPages: StaticPage[] = []
  _licenses: License[] = []

  @Mutation
  setStaticPage (staticPage: StaticPage) {
    this._staticPage = staticPage
  }

  @Mutation
  setStaticPages (staticPages: StaticPage[]) {
    this._staticPages = staticPages
  }

  @Mutation
  setLicenses (licenses: License[]) {
    this._licenses = licenses
  }

  @Action({ rawError: config.rawError })
  async getStaticPage (params: {code: string, regionId: string, sectionId: string}) {
    const { code, regionId, sectionId } = params
    const result = await this.store.$api.getStaticPage(code, regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setStaticPage(result.data)
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  @Action({ rawError: config.rawError })
  async getStaticPages (params: {regionId: string, sectionId: string}) {
    const { regionId, sectionId } = params
    const result = await this.store.$api.getStaticPages(regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setStaticPages(result.data)
    } else {
      this.setStaticPages([])
    }
  }

  @Action({ rawError: config.rawError })
  async getLicenses (params: {regionId: string, sectionId: string}) {
    const { regionId, sectionId } = params
    const result = await this.store.$api.getLicenses(regionId, sectionId)
    if (result.code === 200 && result.data) {
      this.setLicenses(result.data)
    } else {
      this.setLicenses([])
    }
  }

  get staticPage (): StaticPage {
    return this._staticPage
  }

  get staticPages (): StaticPage[] {
    return this._staticPages
  }

  get licenses (): License[] {
    return this._licenses
  }
}
