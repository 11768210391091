import { Vue, Component } from 'vue-property-decorator'
import PersonalSvg from '~/assets/svg/mobile-menu-man.svg?inline'
import GeneralListType from '~/types/enum/GeneralListType'
import s from '~/utils/s'

@Component
export default class PersonalButton extends Vue {
  get isSelected (): boolean {
    return s(this.$store).general.generalListType === GeneralListType.Personal
  }

  toogle () {
    if (this.isSelected) {
      s(this.$store).general.closeGeneralList()
    } else {
      s(this.$store).general.showGeneralList(GeneralListType.Personal)
    }
  }

  render () {
    return (
      <div {...{
        class: {
          'mobile-menu__item-personal': true,
          'mobile-menu__item-personal_selected': this.isSelected
        },
        on: {
          click: this.toogle
        }
      }}>
        <PersonalSvg />
      </div>
    )
  }
}
