import { Vue, Component } from 'vue-property-decorator'
import LogoSvg from '~/assets/svg/header-logo.svg?inline'
import loc from '~/utils/loc'

@Component({
  components: {
    LogoSvg
  }
})
export default class HeaderLogo extends Vue {
  render () {
    return (
      <nuxt-link to={loc(this.$store, { name: 'index' })} class="header-logo">
        <LogoSvg />
      </nuxt-link>
    )
  }
}
