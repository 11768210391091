import { Location } from 'vue-router'
import { Store } from 'vuex'
import s from '~/utils/s'

export default function loc (store: Store<any>, location: Location): Location {
  let name = location.name

  if (location.name === 'index') {
    name = 'region'
  } else if (name !== 'region') {
    name = `region-${location.name}`
  }

  return {
    name,
    params: {
      ...location.params,
      region: s(store).regions.sectionCode || 'apteki'
    },
    query: location.query
  }
}
