








import { Vue, Component } from 'vue-property-decorator'
import NotificationList from '~/components/general/notificationList/NotificationList'
import Loader from '~/components/general/Loader'

@Component({
  middleware: [
    'sensorPanelMiddleware',
    'regionRedirectMiddleware'
  ],
  components: {
    NotificationList,
    Loader
  }
})
export default class AuthLayout extends Vue {}
