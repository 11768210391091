import { Vue, Component } from 'vue-property-decorator'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import s from '~/utils/s'

@Component
export default class TrainingScreen extends Vue {
  mounted () {
    this.$cookies.set('training', true, {
      domain: `.${s(this.$store).general.host}`
    })
    if (window.innerWidth < 768) {
      s(this.$store).general.setIsShowTraining(false)
    } else {
      window.addEventListener('scroll', this.blockScroll)
    }
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.blockScroll)
  }

  blockScroll () {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div class="training-screen">
        <div class="training-screen__container">
          <div class="training-screen__popup">
            <div class="training-screen__arrow"></div>
            <div class="training-screen__close" onClick={() => s(this.$store).general.setIsShowTraining(false)}>
              <CloseSvg />
            </div>
            <div class="training-screen__title">
              Выберите направление
            </div>
            <div class="training-screen__list">
              {s(this.$store).regions.region?.items.map(e => (
                <div key={e.id} class="training-screen__item-wrapper">
                  <nuxt-link {...{
                    props: {
                      to: {
                        name: 'region',
                        params: {
                          region: e.code
                        }
                      }
                    },
                    class: 'training-screen__item',
                    nativeOn: {
                      click: () => s(this.$store).general.setIsShowTraining(false)
                    }
                  }}>
                    <div class="training-screen__item-image">
                      <img src={e.icon} alt={e.name} />
                    </div>
                    <div class="training-screen__item-text">
                      {e.name}
                    </div>
                  </nuxt-link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
