import { Component, Vue, Prop } from 'vue-property-decorator'
import FavoriteSvg from '~/assets/svg/heart-bordered.svg?inline'
import BasketSvg from '~/assets/svg/basket.svg?inline'
import loc from '~/utils/loc'
import s from '~/utils/s'
import BasketHover from '~/components/page/basket/BasketOnHover'

@Component({
  components: {
    FavoriteSvg,
    BasketSvg,
    BasketHover
  }
})

export default class HeaderCatalogLinks extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly inSticky?: boolean

  render () {
    return (
      <div class="header-catalog-links">
        <div class="header-catalog-links__item"
          onClick={(event: MouseEvent) => {
            this.$store.$router.push(loc(this.$store, { name: 'wishlist' }))
          }}>
          <div class="header-catalog-links__icon">
            <div class="header-catalog-links__svg">
              <FavoriteSvg/>
            </div>
            {s(this.$store).wishlist.ids.length > 0 &&
            <div class="header-catalog-links__count">
              {s(this.$store).wishlist.ids.length}
            </div>
            }
          </div>
          <div class="header-catalog-links__text">
            Избранное
          </div>
        </div>
        <nuxt-link
          to={loc(this.$store, { name: 'basket' })}
          class={{
            'header-catalog-links__item': true,
            'header-catalog-links__item-sticky': this.inSticky
          }}>
          <div class="header-catalog-links__icon">
            <div class="header-catalog-links__svg">
              <BasketSvg/>
            </div>
            {s(this.$store).basket.items.length > 0 &&
            <div class="header-catalog-links__count">
              {s(this.$store).basket.items.length}
            </div>
            }
          </div>
          {s(this.$store).basket.items.length > 0 &&
          <div class="header-catalog-links__text">
            {s(this.$store).basket.price} ₽
          </div>}
          {s(this.$store).basket.items.length === 0 &&
          <div class="header-catalog-links__text">
            Корзина
          </div>}
        </nuxt-link>
        {s(this.$store).basket.items.length > 0 &&
            this.$store.$router.currentRoute.name !== 'region-basket' && (
          <div class="header-catalog-links__basket basket-on-hover">
            <BasketHover
              {...{ props: { cartItems: s(this.$store).basket.items } }}
            />
          </div>
        )}
      </div>
    )
  }
}
