export default function ({ app: { router }, isDev }) {
  if (process.client && !isDev) {
    router.onReady(() => {
      setTimeout(() => {
        window.webim = {
          accountName: '24farmaciaru',
          domain: '24farmaciaru.webim.ru',
          location: 'default'
        };

        (function () {
          const s = document.createElement('script')
          s.type = 'text/javascript'
          s.src = 'https://24farmaciaru.webim.ru/js/button.js'
          document.getElementsByTagName('head')[0].appendChild(s)
        })()

        document.querySelector('body').insertAdjacentHTML('beforeend', `<a class="webim_button" href="#" rel="webim">
        <img src="https://24farmaciaru.webim.ru/button.php" border="0"/>
          </a>`)
      }, 2000)
    })
  }
}
