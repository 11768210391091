import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import config from '~/utils/config'
import ProductCardCommercial from '~/types/api/ProductCardCommercial'
import s from '~/utils/s'
import { MainSliderItem } from '~/types/api/MainSliderItem'

@Module({
  name: 'productCardsCommercial',
  stateFactory: true,
  namespaced: true
})
export default class ProductCardsCommercial extends VuexModule {
  _productCardsCommercial: MainSliderItem[] = []

  @Mutation
  setProductCardsCommercial (items: MainSliderItem[]) {
    this._productCardsCommercial = items
  }

  @Action({ rawError: config.rawError })
  async loadProductCardsCommercial (categoryId: string) {
    const result = await this.store.$api.getProductCardCommercial({
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId,
      categoryId
    })

    if (result.code === 200 && result.data) {
      this.setProductCardsCommercial(result.data)
    } else {
      this.setProductCardsCommercial([])
    }
  }

  get productCardsCommercial (): MainSliderItem[] {
    return this._productCardsCommercial
  }
}
