import { Context } from '@nuxt/types'

interface CookieCollection{
  [key: string]: string
}

export function parseCookiesServer (request: any): CookieCollection {
  const list: any = {}
  const rc: any = request.headers.cookie

  rc && rc.split(';').forEach(function (cookie: any) {
    const parts: any = cookie.split('=')
    list[parts.shift().trim()] = decodeURI(parts.join('='))
  })

  return list
}

export function getCookieClient (name: string): string | null {
  const cookieArr = document.cookie.split(';')

  for (const cookieVal of cookieArr) {
    const cookiePair = cookieVal.split('=')
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }

  return null
}

export function setCookie (context: Context, key: string, value: string, path: string = '/', maxAge: number = 2592000) {
  const domain = '.' + context.env.NUXT_ENV_DOMAIN

  context.$cookies.set(key, value, { path, maxAge, domain })
}

export function removeCookie (context: Context, key: string) {
  setCookie(context, key, '', '/', 0)
}
