import { Vue, Component } from 'vue-property-decorator'
import HeaderBottom from '~/components/general/header/HeaderBottom'

@Component
export default class HeaderSticky extends Vue {
  isShow: boolean = false;

  mounted () {
    window.addEventListener('scroll', this.scrollHandler.bind(this))
    this.$router.beforeEach((_, __, next) => {
      this.scrollHandler()
      next()
    })

    this.$router.afterEach((_, __) => {
      this.scrollHandler()
    })

    this.scrollHandler()
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  scrollHandler () {
    this.isShow = window.pageYOffset > 400
  }

  render () {
    return (
      <div class={{
        'sticky-header': true,
        'sticky-header_show': this.isShow
      }}>
        <HeaderBottom {...{
          props: {
            inSticky: true
          }
        }}/>
      </div>
    )
  }
}
