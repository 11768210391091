import { Vue, Component } from 'vue-property-decorator'
import MapIconSvg from '~/assets/svg/marker.svg'
import MapSelectedIconSvg from '~/assets/svg/marker-selected.svg'
import LocationSvg from '~/assets/svg/location-order.svg?inline'
import SelectorArrowSvg from '~/assets/svg/link-arrow.svg?inline'
import ArrowSvg from '~/assets/svg/arrow-search.svg?inline'
import CloseSvg from '~/assets/svg/close-popup.svg?inline'
import LocationDistrict from '~/types/api/LocationDistrict'
import s from '~/utils/s'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import { format } from 'fecha'

interface Marker {
  id: string,
  selected: boolean,
  coords: number[],
  size: number[],
  url: any
}

@Component
export default class PickupSelectorPopupDesktop extends Vue {
  searchInput: string = ''
  selectedPickpoint: string = ''
  coords: number[] = [55.996857, 92.912626]
  zoom: number = 14
  districtId: string = '0'
  isShowSelector: boolean = false

  get bounds () {
    const points = this.pickpointsByFilterDiscrict.filter(e => e.point.lat && e.point.lng).map(e => e.point)
    const lat = [...points].sort((a, b) => a.lat - b.lat)
    const lng = [...points].sort((a, b) => a.lng - b.lng)
    return [
      [lat[lat.length - 1]?.lat, lng[0]?.lng],
      [lat[0]?.lat, lng[lng.length - 1]?.lng]
    ]
  }

  get pickpointMarkers (): Marker[] {
    return this.pickpointsByFilterDiscrict.filter(e => e.point).map(e => ({
      id: e.id,
      selected: e.id === this.selectedPickpoint,
      coords: [e.point.lat, e.point.lng],
      size: [20, 31],
      url: this.selectedPickpoint === e.id ? MapSelectedIconSvg : MapIconSvg
    }))
  }

  get pickpointsByFilterDiscrict () {
    let list = s(this.$store).orderPage.pickpoints
    if (this.districtId !== '0') {
      list = list.filter(e => e.district.id === this.districtId)
    }

    return list
  }

  get pickpoints () {
    let list = this.pickpointsByFilterDiscrict
    if (this.searchInput.length > 2) {
      const query = this.searchInput.toLowerCase()
      list = s(this.$store).orderPage.pickpoints.filter((e) => {
        const name = e.name.toLowerCase()
        const nameForSite = e.nameForSite?.toLowerCase()
        const address = e.address.toLowerCase()
        const description = e.description.toLowerCase()
        return name.includes(query) || address.includes(query) || description.includes(query) || nameForSite?.includes(query)
      })
    }

    return list
  }

  get districtList () {
    const districtList: LocationDistrict[] = s(this.$store).orderPage.pickpoints
      .filter(e => e.district)
      .map(e => e.district)
    return districtList.reduce<LocationDistrict[]>((acc, current) => {
      const x = acc.find(item => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [{
      id: '0',
      xmlId: '0',
      name: 'Все районы'
    }])
  }

  get selectedDistrictName () {
    const district = this.districtList.find(e => e.id === this.districtId)
    if (district) {
      return district.name
    }
    return 'Все районы'
  }

  scrollTo (id: string) {
    document.querySelector(`.pickup-selector-popup-desktop__item[data-scroll-id="${id}"]`)
      ?.scrollIntoView({ block: 'start' })
  }

  clickByMap (id: string) {
    if (this.selectedPickpoint !== id) {
      this.selectedPickpoint = id
      this.clearInput()
      this.scrollTo(id)
    }
  }

  clickByItem (id: string) {
    s(this.$store).general.closePopup()
    s(this.$store).orderPage.setPickpointId(id)
  }

  clearInput () {
    this.searchInput = ''
  }

  selectDistrict (id: string) {
    this.clearInput()
    this.districtId = id
    this.isShowSelector = false
  }

  renderSearchString () {
    return (
      <div class="pickup-selector-popup-desktop__search">
        <div class="pickup-selector-popup-desktop__search-icon">
          <LocationSvg />
        </div>
        <input type="text"
          class="pickup-selector-popup-desktop__search-input"
          v-model={this.searchInput}
          placeholder="Поиск по адресу"
          onInput={() => { this.districtId = '0' }} />
        <div class="pickup-selector-popup-desktop__search-clear" onClick={() => this.clearInput()}>
          <CloseSvg />
        </div>
      </div>
    )
  }

  renderMap () {
    return (
      <div class="pickup-selector-popup-desktop__map">
        <yandex-map {...{
          props: {
            coords: this.coords,
            zoom: this.zoom,
            bounds: this.bounds,
            'scroll-zoom': false,
            controls: [
              'zoomControl'
            ],
            clusterOptions: {
              cluster: {
                clusterDisableClickZoom: false,
                clusterIconColor: '#296654',
                openBalloonOnClick: false,
                gridSize: 50,
                zoomMargin: [100, 20, 20, 20]
              }
            }
          }
        }}>
          {this.pickpointMarkers.filter(marker => marker.coords[0] && marker.coords[1]).map((e) => {
            return (
              <ymap-marker {...{
                props: {
                  'marker-id': e.id,
                  coords: e.coords,
                  clusterName: 'cluster',
                  icon: {
                    layout: 'default#imageWithContent',
                    imageSize: e.size,
                    imageHref: e.url,
                    imageOffset: [-e.size[0] / 2, -e.size[1]],
                    contentOffset: [0, 0]
                  }
                },
                on: {
                  click: () => this.clickByMap(e.id)
                },
                key: `${e.id}_${e.selected}`
              }} />
            )
          })}
        </yandex-map>
      </div>
    )
  }

  renderSelector () {
    return (
      <div {...{
        class: 'link-selector',
        directives: [{
          name: 'VueClickOutside',
          value: () => { this.isShowSelector = false }
        }]
      }}>
        <div {...{
          class: 'link-selector__selected',
          on: {
            click: () => { this.isShowSelector = !this.isShowSelector }
          }
        }}>
          <div class="link-selector__selected-text">
            {this.selectedDistrictName}
          </div>
          <div class="link-selector__selected-arrow">
            <SelectorArrowSvg />
          </div>
        </div>
        {this.isShowSelector &&
          <div class="link-selector__list">
            {this.districtList.map(e => (
              <div {...{
                class: 'link-selector__item',
                on: {
                  click: (event: MouseEvent) => {
                    event.stopPropagation()
                    this.selectDistrict(e.id)
                  }
                }
              }}>{e.name}</div>
            ))}
          </div>
        }
      </div>
    )
  }

  render () {
    return (
      <div class="pickup-selector-popup-desktop">
        <div class="pickup-selector-popup-desktop__content">
          <div class="pickup-selector-popup-desktop__map-col">
            {this.renderSearchString()}
            {this.renderMap()}
          </div>
          <div class="pickup-selector-popup-desktop__list-col">
            <div class="pickup-selector-popup-desktop__header">
              <div class="pickup-selector-popup-desktop__text">
                Выберите аптеку
              </div>
              <div class="pickup-selector-popup-desktop__selector">
                {this.renderSelector()}
              </div>
            </div>
            <client-only>
              <vue-custom-scrollbar class="pickup-selector-popup-desktop__scroll_area" {...{
                props: {
                  settings: {
                    suppressScrollX: true
                  }
                }
              }}>
                <div class="pickup-selector-popup-desktop__list">
                  {this.pickpoints.map(e => (
                    <div key={e.id} class={{
                      'pickup-selector-popup-desktop__item': true,
                      'pickup-selector-popup-desktop__item_selected': e.id === this.selectedPickpoint
                    }} data-scroll-id={e.id} onClick={() => this.clickByItem(e.id)}>
                      <div class="pickup-selector-popup-desktop__item-info">
                        <div class="pickup-selector-popup-desktop__item-name">
                          {e.nameForSite ? e.nameForSite : e.name}
                        </div>
                        <div class="pickup-selector-popup-desktop__address-name">
                          {e.address}
                        </div>
                        <div class="pickup-selector-popup-desktop__work-time">
                          {e.shortWorkTime}
                        </div>
                        <div class="pickup-selector-popup-desktop__phone">
                          {e.phone}
                        </div>
                        {process.env.NUXT_ENV_HIDE_PICKUP_DATE === 'true' ? null
                          : <div class={{
                            'pickup-selector-popup-desktop__pickpoint-date-to-pickup': true,
                            'pickup-selector-popup-desktop__pickpoint-date-to-pickup-today': e.isPickupOrderToday
                          }}
                          >
                            Можно забрать {e.isPickupOrderToday ? e.pickupOrderTodayText : format(new Date(e.dateToPickup), 'DD.MM.YYYY')}
                          </div>
                        }
                      </div>
                      <div class="pickup-selector-popup-desktop__item-button">
                        <ArrowSvg />
                      </div>
                    </div>
                  ))}
                </div>
              </vue-custom-scrollbar>
            </client-only>
          </div>
        </div>
      </div>
    )
  }
}
