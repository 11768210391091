import { Store } from 'vuex'
import SeoRouteConfig from '~/types/seo/SeoRouteConfig'
import SeoPageType from '~/types/seo/SeoPageType'
import SeoDynamicObjectType from '~/types/seo/SeoDynamicObjectType'
import SeoDynamicSectionType from '~/types/seo/SeoDynamicSectionType'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import s from '~/utils/s'

const getStatic = (route: string, name: string): SeoRouteConfig => {
  return {
    route,
    name,
    type: SeoPageType.Static
  }
}

const getDinamic = (route: string, objectType: SeoDynamicObjectType, sectionType: SeoDynamicSectionType, store: ISeoDynamic): SeoRouteConfig => {
  return {
    route,
    objectType,
    sectionType,
    type: SeoPageType.Dinamic,
    store
  }
}

export default (store: Store<any>) => [
  getStatic('index', 'index'),
  getStatic('region', 'index'),
  getStatic('region-basket', 'basket'),
  getStatic('region-catalog', 'catalog'),
  getStatic('region-diseases', 'diseases'),
  getStatic('region-news', 'news'),
  getStatic('region-order', 'order'),
  getStatic('region-personal', 'personal'),
  getStatic('region-pharmacies', 'pharmacies'),
  getStatic('region-search', 'search'),
  getStatic('region-stock', 'stock'),
  getStatic('region-wishlist', 'wishlist'),
  getStatic('region-userProductList', 'userProductList'),
  getStatic('region-catalog-popular', 'catalog-popular'),
  getStatic('region-catalog-sale', 'catalog-sale'),
  getStatic('region-help-about', 'help-about'),
  getStatic('region-help-delivery-payment', 'help-delivery-payment'),
  getStatic('region-help-how-get-order', 'help-how-get-order'),
  getStatic('region-help-how-make-order', 'help-how-make-order'),
  getStatic('region-help-pharmacy', 'help-pharmacy'),
  getStatic('region-help-rule-service', 'help-rule-service'),
  getStatic('region-personal-orders-id', 'personal-orders-id'),
  getStatic('region-categories', 'categories'),
  getDinamic('region-disease-code', SeoDynamicObjectType.Diseases, SeoDynamicSectionType.Item, s(store).diseaseDetailPage),
  getDinamic('region-diseases-section', SeoDynamicObjectType.Diseases, SeoDynamicSectionType.Section, s(store).diseasesPage),
  getDinamic('region-news-code', SeoDynamicObjectType.News, SeoDynamicSectionType.Item, s(store).newsDetailPage),
  getDinamic('region-pharmacies-id', SeoDynamicObjectType.Pharmacies, SeoDynamicSectionType.Item, s(store).pharmacyDetailPage),
  getDinamic('region-stock-code', SeoDynamicObjectType.Stocks, SeoDynamicSectionType.Item, s(store).stockDetailPage)
]
