import { Vue, Component } from 'vue-property-decorator'
import LocationButton from './LocationButton'
import CatalogButton from './CatalogButton'
import BasketButton from './BasketButton'
import PersonalButton from './PersonalButton'
import HomeSvg from '~/assets/svg/mobile-menu-home.svg?inline'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class MobileMenu extends Vue {
  isScroll: boolean = false
  currentScrollY: number = 0

  mounted () {
    window.addEventListener('scroll', this.scrollHandler)
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  scrollHandler () {
    if (this.currentScrollY < window.scrollY) {
      this.isScroll = true
    } else {
      this.isScroll = false
    }
    this.currentScrollY = window.scrollY
  }

  get isShow () {
    return this.isScroll
  }

  render () {
    return (
      <transition name="mobile-menu__animate">
        {!this.isShow &&
          <div class="mobile-menu">
            <div class="mobile-menu__item mobile-menu__item-home">
              <nuxt-link to={loc(this.$store, {
                name: 'index'
              })} {...{
                nativeOn: {
                  click: () => s(this.$store).general.closeGeneralList()
                }
              }}>
                <HomeSvg />
              </nuxt-link>
            </div>
            <div class="mobile-menu__item">
              <LocationButton />
            </div>
            <div class="mobile-menu__item">
              <CatalogButton />
            </div>
            <div class="mobile-menu__item">
              <BasketButton />
            </div>
            <div class="mobile-menu__item">
              <PersonalButton />
            </div>
          </div>
        }
      </transition>
    )
  }
}
