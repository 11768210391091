







































import { Vue, Component } from 'vue-property-decorator'
import CategoryFilterHeaderSticky from '../components/general/header/CategoryFilterHeaderSticky'
import ChoiceCity from '../components/general/ChoiceCity'
import GeneralListType from '~/types/enum/GeneralListType'
import Header from '~/components/general/header/Header'
import Footer from '~/components/general/footer/Footer'
import MobileMenu from '~/components/general/mobileMenu/MobileMenu'
import Loader from '~/components/general/Loader'
import Popup from '~/components/general/popups/Popup'
import GeneralList from '~/components/general/generalList/GeneralList'
import NotificationList from '~/components/general/notificationList/NotificationList'
import BasketScrollMobile from '~/components/page/basket/BasketScrollMobile'
import TrainingScreen from '~/components/general/TrainingScreen'
import HeaderSticky from '~/components/general/header/HeaderSticky'
import SectionClosed from '~/components/page/SectionClosed'
import RegionClosed from '~/components/page/RegionClosed'
import s from '~/utils/s'

@Component({
  components: {
    ChoiceCity,
    Header,
    Footer,
    MobileMenu,
    Loader,
    Popup,
    GeneralList,
    NotificationList,
    BasketScrollMobile,
    TrainingScreen,
    HeaderSticky,
    CategoryFilterHeaderSticky,
    SectionClosed,
    RegionClosed
  },
  middleware: [
    'sensorPanelMiddleware',
    'regionRedirectMiddleware',
    'regionSectionMiddleware',
    'seoMiddleware',
    'choiceCityMiddleware'
  ],
  head () {
    return s(this.$store).seo.meta
  }
})
export default class CommonLayout extends Vue {
  get isShowGeneralList (): boolean {
    return s(this.$store).general.generalListType !== GeneralListType.None
  }

  get isPopupOpen () {
    return s(this.$store).general.popupName !== null
  }

  get popupOffset () {
    return `-${s(this.$store).general.popupOffset}px`
  }

  get isShowTraining () {
    return s(this.$store).general.isShowTraining
  }

  get isShowChoiceCity () {
    return s(this.$store).general.isShowChoiceCity
  }

  get sectionClosed () {
    return s(this.$store).general.sectionClosed
  }

  get regionClosed () {
    return s(this.$store).general.regionClosed
  }
}
