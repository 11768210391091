import {Plugin} from '@nuxt/types'
import TypeDevice from "~/types/enum/TypeDevice";


const typeDevice: Plugin = async (context) => {
  const isSensorPanel = context.$cookies.get('sensor_panel') === 'on'
  context.$api.setTypeDevice(isSensorPanel ? TypeDevice.SensorPanel : TypeDevice.Web)
}

export default typeDevice
