import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Product from '../types/api/Product'
import { userListOptions } from '../types/api/AidKit'
import { ListResponseUpl } from '~/types/api/ListResponse'
import config from '~/utils/config'
import s from '~/utils/s'
import UserProductList, { dataToAddProduct, userListOptionsToChange, userProductListData } from '~/types/api/AidKit'
import NotificationType from '~/types/enum/NotificationType'
import * as sort from '~/utils/config/productSort'

@Module({
  name: 'userProductList',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _ids: string[] = []

  _list:ListResponseUpl<UserProductList>={
    userProductListDataAll: []
  }

  _items: userProductListData = {
    userProductList: {
      id: 'string',
      name: 'string',
      userId: 'string',
      authorizeToken: 'string',
      regionId: 'string',
      sectionId: 'string',
      items: []
    },
    totalSum: 0,
    discount: 0,
    totalDiscountPrice: 0,
    message: '',
    count: 0,
    total: 0
  }

  _currentListData: userProductListData = {
    userProductList: {
      id: 'string',
      name: 'string',
      userId: 'string',
      authorizeToken: 'string',
      regionId: 'string',
      sectionId: 'string',
      items: []
    },
    totalSum: 0,
    discount: 0,
    totalDiscountPrice: 0,
    message: '',
    count: 0,
    total: 0
  }

  _currentProducts: Product[]=[]

  _limit: number = 30
  _page: number = 0
  _offset: number = 0
  _isLoadMore: boolean = false
  _isLoaded: boolean = false

  _renderProducts: boolean = false
  _selectedIds: string[] = []

  @Mutation
  setCurrentList (list: userProductListData) {
    this._currentListData = list
  }

  @Mutation
  setIds (ids: string[]) {
    this._ids = ids
  }

  @Mutation
  setList (list: ListResponseUpl<UserProductList>) {
    this._list = list
  }

  @Mutation
  setItems (items: userProductListData) {
    this._items = items
  }

  @Mutation
  setLimit (limit: number) {
    this._limit = limit
  }

  @Mutation
  setPage (page: number) {
    const pageCount = Math.ceil(this._items.count / this._limit)

    if (page < 0) {
      this._page = 0
    } else if (page >= pageCount) {
      this._page = pageCount - 1
    } else {
      this._page = page
    }
  }

  @Mutation
  enableLoadMore () {
    this._isLoadMore = true
  }

  @Mutation
  disableLoadMore () {
    this._isLoadMore = false
  }

  @Mutation
  enableLoader () {
    this._isLoaded = true
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Mutation
  setCurrentProducts (items: Product[]) {
    this._currentProducts = items
  }

  @Mutation
  setRenderProducts (render: boolean) {
    this._renderProducts = render
  }

  @Mutation
  reCalcResult () {
    const list = this._currentListData
    const products = list.userProductList.items
    list.count = 0
    list.discount = 0
    list.totalDiscountPrice = 0
    list.totalSum = 0
    list.total = 0

    products.map((item) => {
      if (this._selectedIds.includes(item.product.id)) {
        list.count += 1
        list.total += item.amount
        list.totalSum += item.product.basePrice * item.amount
        list.discount += (item.product.basePrice - item.product.price) * item.amount
        list.totalDiscountPrice += item.product.price * item.amount
      }
    })
    list.discount = +list.discount.toFixed(2)
    list.totalDiscountPrice = +list.totalDiscountPrice.toFixed(2)
    list.totalSum = +list.totalSum.toFixed(2)
    list.total = +list.total.toFixed(2)
  }

  @Mutation
  addSelectedId (id: string) {
    if (!this._selectedIds.includes(id)) {
      this._selectedIds.push(id)
    }
  }

  @Mutation
  removeSelectedId (id: string) {
    this._selectedIds = this._selectedIds.filter(e => e !== id)
  }

  @Mutation
  setSelectedIds (items: string[]) {
    this._selectedIds = items
  }

  @Action({ rawError: config.rawError })
  async getProduct () {
    s(this.store).general.enableLoader()
    const result = await this.items.userProductList.items.map(item => item.product)
    this.setCurrentProducts(result)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async getSelectedIds () {
    s(this.store).general.enableLoader()
    const result = await this.items.userProductList.items.map(item => item.product.id)
    this.setSelectedIds(result)
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async createUserProductList () {
    if (this.list.userProductListDataAll.length <= 10) {
      const regionId = s(this.store).regions.regionId
      const sectionId = s(this.store).regions.sectionId

      await this.store.$api.createUserProductList(regionId, sectionId)
      await this.loadUserProductList()
      this.setCurrentList(this.items)
    } else {
      s(this.store).notification.addNotification({
        type: NotificationType.Error,
        text: 'Максимальное количество списков'
      })
    }
  }

  @Action({ rawError: config.rawError })
  async deleteUserProductList (userProductListId: string) {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    await this.store.$api.deleteUserProductList(userProductListId, regionId, sectionId)
    await this.loadUserProductList()
    if (this.list.userProductListDataAll.length !== 0) {
      this.setItems(this.list.userProductListDataAll[this.list.userProductListDataAll.length - 1])
    } else {
      this.setItems({
        userProductList: {
          id: '',
          name: '',
          userId: '',
          authorizeToken: '',
          regionId: '',
          sectionId: '',
          items: []
        },
        totalSum: 0,
        discount: 0,
        totalDiscountPrice: 0,
        message: '',
        count: 0,
        total: 0
      })
      this.setRenderProducts(false)
    }
    this.setCurrentList(this.items)
    this.getProduct()
  }

  @Action({ rawError: config.rawError })
  async addToUserProductList (data: dataToAddProduct) {
    const userProductListId = data.userProductListId
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const res = await this.store.$api.addToUserProductList(data.productId, data.userProductListId, regionId, sectionId)

    if (res.code === 200) {
      if (data.route?.name !== 'region-userProductList') {
        await this.getUserProductListItemById({ userProductListId })
        await this.setCurrentList(this.items)
        await this.getProduct()
      } else {
        await this.getUserProductListItemById({ userProductListId })
      }
    }
  }

  @Action({ rawError: config.rawError })
  async removeItemFromUserProductList (data: dataToAddProduct) {
    const userProductListId = data.userProductListId
    const userProductListItemId = [data.productId]
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const res = await this.store.$api.removeItemFromUserProductList(userProductListId, userProductListItemId, regionId, sectionId)

    if (res.code === 200) {
      if (data.route?.name !== 'region-userProductList') {
        await this.getUserProductListItemById({ userProductListId })
        await this.setCurrentList(this.items)
        await this.getProduct()
      } else {
        await this.getUserProductListItemById({ userProductListId })
      }
    }
  }

  @Action({ rawError: config.rawError })
  async removeFromUserProductList (data: dataToAddProduct) {
    const userProductListId = data.userProductListId
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId

    const res = await this.store.$api.removeFromUserProductList(data.productId, data.userProductListId, regionId, sectionId)

    if (res.code === 200) {
      if (data.route?.name !== 'region-userProductList') {
        await this.getUserProductListItemById({ userProductListId })
        await this.setCurrentList(this.items)
        await this.getProduct()
      } else {
        await this.getUserProductListItemById({ userProductListId })
      }
    }
  }

  @Action({ rawError: config.rawError })
  async addProductsToBasket () {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const items = this.items.userProductList.items.filter(id => this.selectedIds.includes(id.product.id))
    const itemsids = items.map(id => id.id)
    const result = await this.store.$api.addProductsToBasket(itemsids, this.items.userProductList.id, regionId, sectionId)
    if (result.code === 200) { s(this.store).basket.fillData() }
  }

  @Action({ rawError: config.rawError })
  async loadUserProductList () {
    s(this.store).general.enableLoader()
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getUserProductList(regionId, sectionId)
    if (result.code === 200 && result.data?.userProductListDataAll) {
      this.setList({
        userProductListDataAll: result.data.userProductListDataAll
      })
      await this.updateIds()
    }
    s(this.store).general.disableLoader()
  }

  @Action({ rawError: config.rawError })
  async updateUserProductList (userProductListName: string) {
    const regionId = s(this.store).regions.regionId
    const sectionId = s(this.store).regions.sectionId
    const userProductListId = s(this.store).userProductList.items.userProductList.id
    const result = await this.store.$api.updateUserProductList(userProductListName, userProductListId, regionId, sectionId)
    if (result.code === 200) {
      s(this.store).notification.addNotification({
        type: NotificationType.Success,
        text: 'Ваш список успешно переименован'
      })
      await this.loadUserProductList()
      await this.getUserProductListItemById({ userProductListId })
    }
    this.setCurrentList(this.items)
  }

  @Action({ rawError: config.rawError })
  async getUserProductListItemById (options: userListOptions) {
    let route
    if (!options.route) {
      route = this.store.$router.currentRoute
    } else {
      route = options.route
    }

    // если переход из другой страницы, непоказывает лоадер
    if (this.isLoaded) {
      s(this.store).general.enableLoader()
    }
    const limit = this._limit
    const offset = this._offset
    const query = route.query
    const sortType = sort.checkValue(query.sort)
    const getRegionId = s(this.store).regions.regionId
    const getSectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getUserProductListById({
      limit,
      offset,
      regionId: getRegionId,
      sortType,
      sectionId: getSectionId
    }, options.userProductListId)

    if (result.code === 200) {
      this.setItems(
        result.data ?? this._items
      )
      await this.updateIds()
      await this.setRenderProducts(true)
      s(this.store).general.disableLoader()
    }
  }

@Action({ rawError: config.rawError })
  checkProductInList (data: dataToAddProduct) {
    const lists = this.list.userProductListDataAll.map((item) => {
      if (item.userProductList.id === data.userProductListId) {
        const list = item.userProductList.items.map((item) => {
          return item.product.id
        })
        if (list.includes(data.productId)) { return true } else {
          return false
        }
      }
    })
    if (lists.includes(true)) { return true } else {
      return false
    }
  }

  @Action({ rawError: config.rawError })
async changeProductInUserList (options: userListOptionsToChange): Promise<void> {
  const userProductListId = options.userProductListId
  s(this.store).general.enableLoader()
  const result = await this.store.$api.addedAmountUserProductList(options.userProductListId, options.productId, options.quantity, options.regionId, options.sectionId)

  if (result.code === 200) {
    const notificationText = 'Количество товара изменено'
    s(this.store).notification.addNotification({
      type: NotificationType.Success,
      text: notificationText
    })
    await this.getUserProductListItemById({ userProductListId })
    await this.setCurrentList(this.items)
    await this.reCalcResult()
  } else if (result.error) {
    s(this.store).notification.addNotification({
      type: NotificationType.Warning,
      text: result.error.description
    })
  } else {
    s(this.store).notification.addNotification({
      type: NotificationType.Error,
      text: 'Неизвестная ошибка'
    })
  }
  s(this.store).general.disableLoader()
}

  @Action({ rawError: config.rawError })
  async updateIds () {
    const regionId = s(this.store).regions.regionId ?? 0
    const sectionId = s(this.store).regions.sectionId
    const result = await this.store.$api.getUserProductList(regionId, sectionId)
    if (result.code === 200 && result.data?.userProductListDataAll) {
      this.setList({
        userProductListDataAll: result.data.userProductListDataAll
      })
    }
    if (this.list.userProductListDataAll) {
      const items = this.list.userProductListDataAll.map(item => item.userProductList)
      const lists = items.map(item => item.items.map(item2 => (item2.product)))
      const result: string[] = []
      lists.map((item) => {
        item.map((item2) => { result.push(item2.id) })
      })
      this.setIds(result)
    } else {
      this.setIds([])
    }
  }

  get ids (): string[] {
    return this._ids
  }

  get selectedIds (): string[] {
    return this._selectedIds
  }

  get list (): ListResponseUpl<UserProductList> {
    return this._list
  }

  get items (): userProductListData {
    return this._items
  }

  get limit (): number {
    return this._limit
  }

  get page (): number {
    return this._page
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get currentProducts (): Product[] {
    return this._currentProducts
  }

  get renderProducts () : boolean {
    return this._renderProducts
  }

  get pageCount (): number {
    return Math.ceil(this.items.count / this.limit)
  }

  get currentListData (): userProductListData {
    return this._currentListData
  }
}
