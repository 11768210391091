import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const regionRedirectMiddleware: Middleware = async (context: Context) => {
  if (!context.req) {
    return
  }

  const host = context.req.headers.host ?? ''
  const regexDomain = s(context.store).general.host?.replace('.', '\\.')
  const regexMain = new RegExp('^' + regexDomain + '(:\\d{1,5})?$', 'i')
  const regexSub = new RegExp('^([^.]*)\\.' + regexDomain + '(:\\d{1,5})?$', 'i')
  if (regexMain.test(host)) {
    s(context.store).regions.setDefaultRegion()
    await s(context.store).regions.setDefaultSection()
  } else if (regexSub.test(host)) {
    const match = host.match(regexSub) ?? []
    if (match.length > 1) {
      const regionDomain = match[1]
      s(context.store).regions.setRegionByDomain(regionDomain)
    } else {
      s(context.store).general.redirectFatal()
    }
  } else {
    s(context.store).general.redirectFatal()
  }
}

export default regionRedirectMiddleware
