import { Action, Module, VuexModule } from 'vuex-module-decorators'
import config from '~/utils/config'

@Module({
  name: 'advertising',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  // #region actions
  @Action({ rawError: config.rawError })
  addInfoInContainer (id: string) {
    window.yaContextCb?.push(() => {
      // eslint-disable-next-line no-undef
      Ya.adfoxCode.create({
        ownerId: 5202103,
        containerId: id,
        params: {
          p1: 'dbqlc',
          p2: 'ixfw'
        }
      })
    })
    console.log(window.yaContextCb)
  }
  // #endregion
}
