import { Vue, Component } from 'vue-property-decorator'
import Section from '~/types/api/Section'
import ArrowSvg from '~/assets/svg/catalog-menu-arrow.svg?inline'
import SectionHelper from '~/utils/sectionHelper'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'

@Component
export default class MobileCatagoryList extends Vue {
  parentId: string = '0'
  parentHistory: string[] = []

  get sections (): SfSection[] {
    return s(this.$store).general.sections.filter(sectionItem => sectionItem.parentId === this.parentId)
  }

  get isRoot (): boolean {
    return this.parentId === '0'
  }

  selectItem (id: string) {
    this.parentHistory.push(this.parentId)
    this.parentId = id
  }

  back () {
    this.parentId = this.parentHistory.pop() ?? '0'
  }

  renderBack () {
    if (!this.isRoot) {
      return (
        <div class="mobile-catagory-list__back" onClick={this.back}>
          <div class="mobile-catagory-list__back-arrow">
            <ArrowSvg />
          </div>
          <div class="mobile-catagory-list__back-text">
            Назад
          </div>
        </div>
      )
    }
  }

  renderItem (section: SfSection) {
    const isLink = s(this.$store).general.sections.find(sectionItem => section.id === sectionItem.parentId) === undefined
    return (
      <div class="mobile-catagory-list__col">
        {isLink ? this.renderLink(section) : this.renderParent(section)}
      </div>
    )
  }

  renderIcon (section: SfSection) {
    if (this.isRoot && section.icon) {
      return (
        <div class="mobile-catagory-list__item-icon">
          <img src={section.icon} alt={section.name} />
        </div>
      )
    }
  }

  renderArrow () {
    if (!this.isRoot) {
      return (
        <div class="mobile-catagory-list__item-arrow">
          <ArrowSvg />
        </div>
      )
    }
  }

  renderLink (section: SfSection) {
    const helper = new SectionHelper(s(this.$store).general.sections, this.$store)
    return (
      <nuxt-link {...{
        class: 'mobile-catagory-list__item',
        props: {
          to: helper.getSectionPathById(section.id)
        },
        nativeOn: {
          click: () => s(this.$store).general.closeGeneralList()
        }
      }}>
        {this.renderIcon(section)}
        {section.name}
      </nuxt-link>
    )
  }

  renderParent (section: SfSection) {
    return (
      <div class="mobile-catagory-list__item" onClick={() => this.selectItem(section.id)}>
        {this.renderIcon(section)}
        <div class="mobile-catagory-list__name">
          {section.name}
        </div>
        {this.renderArrow()}
      </div>
    )
  }

  render () {
    return (
      <div class="mobile-catagory-list">
        <div class="mobile-catagory-list__container">
          {this.renderBack()}
          <div class="mobile-catagory-list__list">
            <div class="mobile-catagory-list__row">
              {this.sections.map((sectionItem: SfSection) => this.renderItem(sectionItem))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
