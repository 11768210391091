import { Vue, Component } from 'vue-property-decorator'
import BasketSvg from '~/assets/svg/mobile-menu-basket.svg?inline'
import loc from '~/utils/loc'
import s from '~/utils/s'

@Component
export default class BasketButton extends Vue {
  render () {
    return (
      <nuxt-link to={loc(this.$store, { name: 'basket' })} class="mobile-menu__item-basket" {...{
        nativeOn: {
          click: () => s(this.$store).general.closeGeneralList()
        }
      }}>
        <BasketSvg />
        {s(this.$store).basket.items.length > 0 &&
          <div class="mobile-menu__item-basket-count">
            {s(this.$store).basket.items.length}
          </div>
        }
      </nuxt-link>
    )
  }
}
