import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const regionSectionMiddleware: Middleware = async (context: Context) => {
  const sectionCode = context.route.params.region
  const current = s(context.store).regions.sectionCode
  if (sectionCode !== undefined && sectionCode !== current) {
    await s(context.store).regions.setSectionByCode(sectionCode)
  }

  if (sectionCode === undefined) {
    await s(context.store).regions.setDefaultSection()
  }
}

export default regionSectionMiddleware
