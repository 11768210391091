import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { Route } from 'vue-router'
import Img from '~/types/api/Img'
import ArticleDetailOptions from '~/types/api/options/ArticleDetailOptions'
import config from '~/utils/config'
import ISeoDynamic from '~/types/seo/ISeoDynamic'
import s from '~/utils/s'
import Image from '~/types/api/Image'
import Product from '~/types/api/Product'

@Module({
  name: 'newsDetailPage',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule implements ISeoDynamic {
  _id: number = 0
  _code: string = ''
  _title: string = ''
  _date!: Date
  _img?: Image = undefined
  _description: string = ''
  private _HTMLContent: string = ''
  _placementDate?: Date
  _products?: Product[]

  @Mutation
  setId (id: number) {
    this._id = id
  }

  @Mutation
  setCode (code: string) {
    this._code = code
  }

  @Mutation
  setTitle (title: string) {
    this._title = title
  }

  @Mutation
  setDate (date: Date) {
    this._date = date
  }

  @Mutation
  setImg (img?: Image) {
    this._img = img
  }

  @Mutation
  setDescription (description?: string) {
    if (description) {
      this._description = description
    } else {
      this._description = ''
    }
  }

  @Mutation
  setPlacementDate (placementDate?: Date) {
    this._placementDate = placementDate
  }

  @Mutation
  setHTMLContent (value?: string) {
    this._HTMLContent = value ?? ''
  }

  @Mutation
  setProducts (value?: Product[]) {
    this._products = value ?? []
  }

  get HTMLContent (): string {
    return this._HTMLContent
  }

  @Action({ rawError: config.rawError })
  async loadData (route?: Route) {
    if (!route) {
      route = this.store.$router.currentRoute
    }

    const params = route.params
    let code = ''
    if (typeof params.code === 'string') {
      code = params.code
    }

    if (!code) {
      s(this.store).general.redirectNotFound()
    }

    const options: ArticleDetailOptions = {
      code,
      regionId: s(this.store).regions.regionId ?? 0
    }

    const result = await this.store.$api.getArticle(options)
    if (result.code === 200 && result.data) {
      this.setId(result.data.id)
      this.setCode(result.data.code)
      this.setTitle(result.data.title)
      this.setDate(result.data.updatedAt)
      this.setImg(result.data.image)
      this.setDescription(result.data.description)
      this.setHTMLContent(result.data.HTMLContent)
      this.setPlacementDate(result.data.placementDate)
      this.setProducts(result.data.products)
    } else {
      s(this.store).general.redirectNotFound()
    }
  }

  get id (): number {
    return this._id
  }

  get code (): string {
    return this._code
  }

  get title (): string {
    return this._title
  }

  get date () {
    return this._date
  }

  get img (): Image | undefined {
    return this._img
  }

  get description (): string {
    return this._description
  }

  get seoCode (): string {
    return this.code
  }

  get placementDate (): Date | undefined {
    return this._placementDate
  }

  get products (): Product[] {
    return this._products ?? []
  }
}
