import { Vue, Component } from 'vue-property-decorator'
import LogoSvg from '~/assets/svg/footer-logo.svg?inline'

@Component
export default class FooterLogo extends Vue {
  render () {
    return (
      <nuxt-link to="/" class="common-footer-logo">
        <LogoSvg />
      </nuxt-link>
    )
  }
}
