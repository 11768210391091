import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'

@Component
export default class FooterInfo extends Vue {
  async mounted () {
    await s(this.$store).helpPages.loadFooterInfo()
  }

  renderContent () {
    return (
      <div class="common-footer-info__content" domPropsInnerHTML={s(this.$store).helpPages.footerInfo}></div>
    )
  }

  render () {
    return (
      <div class="common-footer-info">
        {this.renderContent()}
      </div>
    )
  }
}
