import { Vue, Component } from 'vue-property-decorator'
import s from '~/utils/s'

@Component
export default class ChoiceCity extends Vue {
  mounted () {
    this.$cookies.set('choiceCity', true, {
      domain: `.${s(this.$store).general.host}`,
      path: '/'
    })
    s(this.$store).general.showPopup('RegionPopup')
  }

  render () {
    return (
      <div></div>
    )
  }
}
