enum FilterType{
  Checkbox = 'checkbox',
  Price = 'price',
  Range = 'range',
  Radio = 'radio',
  FlagBoolean = 'flagBoolean',
  FlagNumeric = 'flagNumeric'
}

export default FilterType
