import { Vue, Component, Prop } from 'vue-property-decorator'
import { NuxtError } from '@nuxt/types'
import UnknownError from '~/components/page/errors/UnknownError'
import Error404 from '~/components/page/errors/Error404'
import ErrorClosedSite from '~/components/page/errors/ErrorClosedSite'

@Component({
  layout: (param) => {
    return param?.app?.nuxt?.err?.statusCode === 503 ? 'empty' : 'default'
  },
  middleware: [
    'sensorPanelMiddleware'
  ]
})
export default class extends Vue {
  @Prop({ required: true, type: Object })
  readonly error!: NuxtError;

  renderError () {
    if (this.error.statusCode === 404) {
      return <Error404 />
    } else if (this.error.statusCode === 500) {
      return <ErrorClosedSite />
    } else {
      return (
        <UnknownError
          {...{
            props: {
              error: this.error
            }
          }}
        />
      )
    }
  }

  render () {
    return this.renderError()
  }
}
