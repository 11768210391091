import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import config from '~/utils/config'
import s from '~/utils/s'
import OrderForRating from '~/types/api/OrderForRating'
import OrderRatingStep from '~/types/enum/OrderRatingStep'
import Order from '~/types/api/order/Order'

@Module({
  name: 'orderRating',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _orderForRating!: Order

  _orderRatingStep: OrderRatingStep = OrderRatingStep.Stars
  _selectStarValue: number = 0
  _comment: string = ''

  @Mutation
  setOrderForRating (val: Order) {
    this._orderForRating = val
  }

  @Mutation
  setOrderRatingStep (step: OrderRatingStep) {
    this._orderRatingStep = step
  }

  @Mutation
  setStarValue (val: number) {
    this._selectStarValue = val
  }

  @Mutation
  setComment (val: string) {
    this._comment = val
  }

  @Action({ rawError: config.rawError })
  async getOrderForRating () {
    if (s(this.store).auth.isAuth) {
      const result = await this.store.$api.getOrderForRating(s(this.store).regions.regionId ?? 0, s(this.store).regions.sectionId)
      if (result?.data) {
        this.setOrderForRating(result.data)
      }
    }
  }

  @Action({ rawError: config.rawError })
  async sendOrderForRating () {
    const result = await this.store.$api.sendOrderRating({
      orderId: this.orderForRating.id,
      rating: this.starValue,
      comment: this.comment,
      regionId: s(this.store).regions.regionId ?? 0,
      sectionId: s(this.store).regions.sectionId
    })
    if (result.data?.status) {
      return true
    }
    return false
  }

  get orderForRating (): Order {
    return this._orderForRating
  }

  get orderRatingStep (): OrderRatingStep {
    return this._orderRatingStep
  }

  get starValue (): number {
    return this._selectStarValue
  }

  get comment (): string {
    return this._comment
  }
}
