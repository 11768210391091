export enum MetrikaLocalCodes {
  ORDER_STEP_1 = 'order_step_1',
  ORDER_STEP_2 = 'order_step_2',
  ORDER_STEP_3 = 'order_step_3',
  ADD_PRODUCT = 'add_product',
  PAGE_404 = 'page_404',
  CONTACT_US_STEP_1 = 'contact_us_step_1',
  CONTACT_US_STEP_2 = 'contact_us_step_2',
  MAKE_ORDER = 'make_order'
}

export interface MetrikaCode {
  id: string;
  code: MetrikaLocalCodes;
  name: string;
  metrikaCode: string;
};
