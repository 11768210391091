interface Options{
  duration: number
  timing: (timeFraction: number) => number,
  draw: (progress: number) => void
}

export default function animate (options: Options) {
  const start = performance.now()

  requestAnimationFrame(function animateFrame (time) {
    let timeFraction = (time - start) / options.duration
    if (timeFraction > 1) { timeFraction = 1 }
    const progress = options.timing(timeFraction)
    options.draw(progress)
    if (timeFraction < 1) {
      requestAnimationFrame(animateFrame)
    }
  })
}
