import { Middleware, Context } from '@nuxt/types'
import s from '~/utils/s'

const authMiddleware: Middleware = (context: Context) => {
  const isAuth = s(context.store).auth.isAuth
  if (!isAuth) {
    return context.redirect('/personal/login/')
  }
}

export default authMiddleware
